import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function BannerList() {
  const [bannerList, setBannerList] = useState([]);
  const [path, setPath] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getBannerList();
  }, [params]);

  const getBannerList = () => {
    AdminAPI.get(`banners?type=banner`, { params: params })
      .then((response) => {
        setBannerList(response.data.data.data);
        setPath(response.data.path)
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row mb-1">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/add-banner"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Banner
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Heading</th>
                    <th scope="col">Detail</th>
                    <th scope="col">URL</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {bannerList.map((banner, i) => (
                    <tr key={i}>
                      <td>
                        <img
                            style={{height: "50px"}}
                            src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${banner.image}`}
                            alt={banner.heading}
                        />
                      </td>
                      <td>{banner.heading}</td>
                      <td>{banner.detail}</td>
                      <td>{banner.url}</td>
                      
                      <td>
                        <Link
                          to={`/admin/banner/view/${banner.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default BannerList;
