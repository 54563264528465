import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function BlogList() {
  const [blogList, setBlogList] = useState([]);
  const [path, setPath] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getBlogList();
  }, [params]);

  const getBlogList = () => {
    AdminAPI.get(`blogs`, { params: params })
      .then((response) => {
        setBlogList(response.data.data.data);
        setPath(response.data.cover_path)
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onChagneStatus = (id, e) => {
    AdminAPI.post(`blogs/${id}/update-status`, {
      status: e.target.value,
    })
      .then((response) => {
        getBlogList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/blogs/add-blog"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Blog
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Published">Published</option>
              <option value="Draft">Draft</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th style={{ width: "5%" }} scope="col">Cover</th>
                    <th style={{ width: "25%" }} scope="col">Heading</th>
                    <th style={{ width: "40%" }} scope="col">Details</th>
                    <th style={{ width: "10%" }} scope="col">Author</th>
                    <th style={{ width: "15%" }} scope="col">Status</th>
                    <th style={{ width: "5%" }} scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {blogList.map((blog, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${blog.cover}`}
                          style={{ height: "30px" }}
                          alt="..."
                        />
                      </td>
                      <td>{blog.title.length > 50 ? blog.title.slice(0, 50)+"..." : blog.title}</td>
                      <td>{blog.description.length > 70 ? blog.description.slice(0, 70)+"..." : blog.description}</td>
                      {/* <td>
                        {blog.description}
                      </td> */}
                      <td>
                        {blog.admin !== null 
                          ? blog.admin.name
                          : 0}
                      </td>
                      <td>
                        <select
                          value={blog.status}
                          onChange={(e) => {
                            onChagneStatus(blog.id, e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="Published">Published</option>
                          <option value="Draft">Draft</option>
                        </select>
                      </td>
                      <td>
                        <Link
                          to={`/admin/edit-blog/${blog.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default BlogList;
