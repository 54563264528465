import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import { useContainerDimensions } from "./useContainerDimensions";
import Zoom from "react-reveal/Zoom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import "normalize.css/normalize.css";
import "./slider-animations.css";
// import "./styles.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 100,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
};

export default function SliderBanner2() {
  let history = useHistory();
  const [banners, setBanners] = useState([]);
  const [path, setPath] = useState("");
  const [s, setS] = useState(false);
  const ref = useRef(null);
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(() => {
    // console.log("width", ref.current ? ref.current.offsetHeight : 0);
  }, [ref.current]);
  useEffect(() => {
    getBanners();
  }, []);
  useEffect(() => {
    // console.log("oisssssssss mobile", isMobile);
    setS(true);
  }, [isMobile]);

  const getBanners = () => {
    axios
      .get(`banners?type=banner`)
      .then((response) => {
        setBanners(response.data.data);
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goto = (url) => {
    history.push(url);
  };
  return (
    <div className="slider-banner-main">
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={index} className="parent-slide">
            <div className="slider-contents pt-3">
              {isMobile ? (
                <div className="row">
                  <div className="col-12">
                    <div
                      style={{ height: "300px" }}
                      className="slider-img-area tilt-in-fwd-tr"
                    >
                      <img
                        alt="app-bar-logo"
                        src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${banner.image}`}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div ref={componentRef} className="slider-text-area">
                      {/* <Zoom left> */}
                      <h1 className="tilt-in-fwd-tr">{banner.heading}</h1>
                      {/* </Zoom> */}

                      {/* <Zoom left> */}
                      <p className="tilt-in-fwd-tr">{banner.detail}</p>
                      <Link
                        to={banner.url}
                        type="button"
                        className="btn btn-outline-success tilt-in-fwd-tr"
                      >
                        SHOP NOW
                      </Link>
                      {/* </Zoom> */}
                    </div>
                  </div>
                </div>
              ):(
                <div className="row">
                  <div className="col-5 pt-5">
                    <div ref={componentRef} className="slider-text-area">
                      {/* <Zoom left> */}
                      <h1 className="tilt-in-fwd-tr">{banner.heading}</h1>
                      {/* </Zoom> */}

                      {/* <Zoom left> */}
                      <p className="tilt-in-fwd-tr">{banner.detail}</p>
                      <Link
                        to={banner.url}
                        type="button"
                        className="btn btn-outline-success tilt-in-fwd-tr"
                      >
                        SHOP NOW
                      </Link>
                      {/* </Zoom> */}
                    </div>
                  </div>
                  <div className="col-6 ml-auto">
                    <div
                      className="slider-img-area tilt-in-fwd-tr"
                    >
                      <img
                        alt="app-bar-logo"
                        src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${banner.image}`}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
