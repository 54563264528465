import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory, useParams, Link } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Typography, Container, Button, Box } from "@material-ui/core";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import * as yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

export default function CouponsForm() {
  let history = useHistory();
  let { id } = useParams();
  const [coupon, setCoupon] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    if (id !== undefined) {
      getCouponDetails();
    }
  }, []);

  const getCouponDetails = () => {
    AdminAPI.get(`coupons/${id}`)
      .then((response) => {
        setCoupon(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var validationSchema = yup.object().shape({
    code: yup.string().required(),
    valid_date: yup.date().required(),
  });

  const initialValues = {
    code: coupon.code || "",
    discount: coupon.discount || "0",
    discount_type: coupon.discount_type || "%",
    maximum: coupon.maximum || "",
    valid_date:
      moment(coupon.valid_date).format("MM/DD/YYYY") ||
      moment().format("MM/DD/YYYY"),
  };

  const onSubmit = (values, { resetForm }) => {
    let objToSend = {
      code: values.code,
      discount: values.discount,
      discount_type: values.discount_type,
      maximum: values.maximum,
      valid_date: moment(values.valid_date).format("YYYY-MM-DD"),
    };
    if (id !== undefined) {
      AdminAPI.put(`coupons/${id}`, objToSend)
        .then((response) => {
          history.push("/admin/coupons");
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    } else {
      AdminAPI.post(`coupons`, objToSend)
        .then((response) => {
          history.push("/admin/coupons");
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    }
  };
  const deleteCoupon = () => {
    AdminAPI.post(`coupons/${id}`)
      .then((response) => {
        setOpenDialog(false);
        history.push("/admin/coupons");
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };
  return (
    <div>
      <div className="products_section">
        <Container>
          {/* Formik Mui Date Picker Example */}
          <div className="products_section_form">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              render={({ setFieldValue, isValid, dirty, values }) => (
                <Form>
                  <div className="form-group row">
                    <label className="col-md-3 ">Code</label>
                    <div className="col-md-8">
                      <Field name={`code`} className="form-control" />

                      <ErrorMessage
                        name={`code`}
                        component="div"
                        className="error-message"
                      />
                      {!_.isEmpty(serverErrors) &&
                        serverErrors.code !== undefined && (
                          <span className="error-msg">
                            {" "}
                            {serverErrors.code}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 ">Discount</label>
                    <div className="col-md-8">
                      <Field name={`discount`} className="form-control" />

                      <ErrorMessage
                        name={`discount`}
                        component="div"
                        className="error-message"
                      />
                      {!_.isEmpty(serverErrors) &&
                        serverErrors.discount !== undefined && (
                          <span className="error-msg">
                            {" "}
                            {serverErrors.discount}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 ">Discount Type</label>
                    <div className="col-md-8">
                      <Field
                        name="discount_type"
                        as="select"
                        className="form-control"
                      >
                        <option value="%">Percentage</option>
                        <option value="Flat">Flat</option>
                      </Field>

                      <ErrorMessage
                        name={`discount_type`}
                        component="div"
                        className="error-message"
                      />
                      {!_.isEmpty(serverErrors) &&
                        serverErrors.discount_type !== undefined && (
                          <span className="error-msg">
                            {" "}
                            {serverErrors.discount_type}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 ">Maximum</label>
                    <div className="col-md-8">
                      <Field name={`maximum`} className="form-control" />

                      <ErrorMessage
                        name={`maximum`}
                        component="div"
                        className="error-message"
                      />
                      {!_.isEmpty(serverErrors) &&
                        serverErrors.maximum !== undefined && (
                          <span className="error-msg">
                            {" "}
                            {serverErrors.maximum}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 ">Coupon Validity</label>
                    <div className="col-md-8">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Coupon Validity"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          value={values.valid_date}
                          disablePast
                          autoOk
                          clearable
                          onChange={(value) =>
                            setFieldValue("valid_date", value)
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider>
                      <ErrorMessage
                        name="valid_date"
                        component="div"
                        className="error-message"
                      />
                      {!_.isEmpty(serverErrors) &&
                        serverErrors.valid_date !== undefined && (
                          <span className="error-msg">
                            {" "}
                            {serverErrors.valid_date}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        onClick={() => setOpenDialog(true)}
                        className="btn btn-md btn-danger float-left"
                        type="button"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setOpenDialog(true)}
                        className="btn btn-md btn-primary float-right"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </Container>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => deleteCoupon()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
