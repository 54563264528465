// import React, { useContext } from "react";
import ProductItems from "./ProductItems";
import React, { useRef, useEffect, useState, useCallback } from "react";
import "./css/component.css";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
const ProductsGrids = ({
  products,
  path,
  triggerScroll,
  stop,
  cat,
  search,
  fetchStars,
  stars,
  isFetching,
  nextStars,
}) => {
  const attributes = [
    { name: "name", label: "Name" },
    { name: "height", label: "Height" },
    { name: "mass", label: "Weight (kgs)" },
    { name: "hair_color", label: "Hair Color" },
    { name: "skin_color", label: "Skin Color" },
    { name: "eye_color", label: "Eye Color" },
    { name: "birth_year", label: "Birth Year" },
    { name: "gender", label: "Gender" },
  ];
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // Create ref to attach to the loader component
  const loader = useRef(null);

  const loadMore = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        !stop && products.length > 0 && loadUserList();
        console.log("hello fetching", search);
        console.log("current product length", products.length);
        // if (!loading) {
        //   loadUserList(page);
        // }
      }
    },
    [stop, cat, products, search]
  );

  console.log("loading stop current state", stop);
  const loadUserList = () => {
    console.log("loading called");
    setLoading(true);

    if (triggerScroll) {
      triggerScroll("aa");
    }
    // triggerScroll("aa");
    // console.log("loading called");
    // setTimeout(() => {
    // UserService.getList(page)
    // .then((res) => {
    const newPage = page + 1;
    // const newList = userList.concat(res.data);
    // setUserList(newList);
    setPage(newPage);
    // console.log("loading page inner", page);
    //   if(res.data.length===0)
    //     setNoData(true);
    // // })

    // .finally(() =>{
    // setLoading(false);
    // })
    // }
    // ,1500);
  };
  useEffect(() => {
    const options = {
      root: null, // window by default
      rootMargin: "0px",
      threshold: 0.25,
    };

    // Create observer
    const observer = new IntersectionObserver(loadMore, options);

    // observer the loader
    if (loader && loader.current) {
      observer.observe(loader.current);
    }

    // clean up on willUnMount
    return () => {
      console.log("loader curreeeent", loader);
      loader.current !== null && observer.unobserve(loader.current);
    };
  }, [loader, loadMore]);

  console.log("load refffffffffffff===>", loader);
  return (
    <div className={"styles.p__container"}>
      <div className={"product_grid"}>
        {products.map((product) => (
          <ProductItems key={product.id} product={product} path={path} />
        ))}
      </div>{" "}
      {/* {loading ? <div className="text-center">loading data ...</div> : ""}
      {noData ? <div className="text-center">no data anymore ...</div> : ""} */}
      <div className="text-center">
        <div ref={loader}>
          {/* {isFetching && <div color="goldenrod" size="64px" thickness={2} />}
          hello */}
          {loading && !stop && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};

export default ProductsGrids;
