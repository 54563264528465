import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

function AdminDetails() {
  const [openDialog, setOpenDialog] = useState(false);
  let { id } = useParams();
  let history = useHistory();
  const [admin, setAdmin] = useState({});
  const [modalMessage, setModalMessage] = useState("");

  const [params, setParams] = useState({
    per_page: 20,
    page: 1
  });

  useEffect(() => {
    setModalMessage("Are you sure to change passowrd?");
  }, []);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: ""
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
  });
  
  const onSubmit = async (values, { resetForm }) => { 
    setOpenDialog(true)
    let obj = {
      password: values.password,
      confirm_password: values.confirm_password
    };
    setAdmin(obj);
  };

  const changePassword = () => {
    AdminAPI.post(`admins/${id}/update-password`, admin)
    .then((response) => {
        setModalMessage("Password updated");
        setOpenDialog(false);
        history.push(`/admin/admins`);
    })
    .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
    });
  }

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row ">
                                    <label className="col-md-3 ">
                                        Password
                                    </label>
                                    <div className="col-sm-7">
                                        <Field
                                        className=" form-control"
                                        type="password"
                                        name="password"
                                        />
                                        <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="error-message"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <label className="col-md-3 ">
                                        Confirm Password
                                    </label>
                                    <div className="col-sm-7">
                                        <Field
                                        className=" form-control"
                                        type="password"
                                        name="confirm_password"
                                        />
                                        <ErrorMessage
                                        name="confirm_password"
                                        component="div"
                                        className="error-message"
                                        />
                                    </div>
                                </div>
                                
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="text-right">
                                      <button
                                        onSubmit={onSubmit}
                                        className="btn btn-md btn-primary"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Change password"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="mr-auto" onClick={() => setOpenDialog(false)} color="default">
            Cancel
          </Button>
          <Button className="ml-auto" onClick={() => changePassword()} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminDetails;
