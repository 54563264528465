class Auth {
  constructor() {
    const ImaginaryUserToken = localStorage.getItem("ImaginaryUserToken");

    if (ImaginaryUserToken !== null) {
      this.authenticated = true;
    }
  }

  login(cb) {
    this.authenticated = true;

    cb();
  }
  logout(cb) {
    this.authenticated = false;
    localStorage.removeItem("ImaginaryUserToken");
    localStorage.removeItem("authenticated");
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
