import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { formatNumber } from "../helpers/utils";
import "./css/component.css";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      fontSize: "9px",
    },
    backgroundColor: "#0069d9",
  },
}));
const ProductItems = ({ product, path = "" }) => {
  let history = useHistory();
  const [price, setPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState({});
  const classes = useStyles();
  const { addProduct, cartItems, increase, decrease, removeProduct } =
    useContext(CartContext);

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.selectedVariant.id === product.id);
  };
  const checkQuantity = (product) => {
    // console.log("product", product);
    let selectedProduct = cartItems.find(
      (item) => item.selectedVariant.id === product.id
    );
    if (selectedProduct !== undefined) {
      if (Object.keys(selectedProduct).includes("quantity"))
        return selectedProduct.quantity;
    }
    return 0;
  };
  const decreaseProduct = (item) => {
    if (checkQuantity(item) > 1) {
      decrease(product, selectedVariant);
    } else {
      removeProduct(product, selectedVariant);
    }
  };
  const onChagneVarint = (e) => {
    // console.log(e.target.value);
    let pID = parseInt(e.target.value);
    let p = { ...product };
    let arr = p.product_variants.filter((o) => o.id === pID);
    setSelectedVariant(arr[0]);
  };

  useEffect(() => {
    if (product.product_variants.length > 0) {
      setSelectedVariant(product.product_variants[0]);
    }
  }, []);

  return (
    <ScrollAnimation
      animateOnce={true}
      animateIn="zoomIn"
      animateOut="backOutRight"
      duration={2}
    >
      <div className="card card-body mx-2 product-card">
        <div
          className="single-preview"
          onClick={() => history.push(`/product-details/${product.id}`)}
        >
          {selectedVariant !== undefined &&
            selectedVariant.product_photos !== undefined &&
            selectedVariant.product_photos.length > 0 && (
              <div>
                <div className="container_img">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${product.id}/${selectedVariant.id}/${selectedVariant.product_photos[0].photo}`}
                    alt="..."
                  />
                  {selectedVariant.discount !== 0 && (
                    <div className="topright-product">
                      <span className="discount_area">
                        - {selectedVariant.discount} %
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>

        <div className="product-desc-body">
          <h6
            onClick={() => history.push(`/product-details/${product.id}`)}
            style={{ cursor: "pointer" }}
            className="product-desc text-center pt-1"
          >
            <strong>{product.name}</strong>
          </h6>
          {product.product_variants.length > 1 ? (
            <div className="text-left product-price">
              <select
                onChange={(e) => {
                  onChagneVarint(e);
                }}
                className="form-control"
                aria-label="Default select example"
              >
                {product.product_variants.map((p, i) => (
                  <option value={p.id}>{p.unit} </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="select_height">
              {product.product_variants[0].unit}
            </div>
          )}
          {selectedVariant !== undefined && (
            <div class="price-box">
              {!_.isEmpty(selectedVariant) && (
                <h3 className="text-left product-price">
                  {formatNumber(
                    selectedVariant.price -
                      (selectedVariant.discount / 100) * selectedVariant.price
                  )}
                  Tk
                </h3>
              )}
              {selectedVariant.discount !== 0 && (
                <span class="old-price">
                  <del>{selectedVariant.price} Tk</del>
                </span>
              )}
            </div>
          )}
          {selectedVariant.discount === 0 && (
            <div style={{ height: "20px" }}></div>
          )}

          {product.status !== "Out of stock" ? (
            <span>
              {isInCart(selectedVariant) && (
                <div>
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      classes={{ root: classes.root }}
                      onClick={() => decreaseProduct(selectedVariant)}
                    >
                      {" "}
                      <Icon color="white">remove</Icon>
                    </Button>
                    <Button classes={{ root: classes.root }}>
                      {" "}
                      <span className="quantity_areaa">
                        {checkQuantity(selectedVariant)} in bag
                      </span>
                    </Button>
                    <Button
                      classes={{ root: classes.root }}
                      onClick={() => increase(product, selectedVariant)}
                    >
                      {" "}
                      <Icon color="white">add</Icon>
                    </Button>
                  </ButtonGroup>
                </div>
              )}

              {!isInCart(selectedVariant) && (
                <React.Fragment>
                  {selectedVariant.status !== "Out of stock" ? (
                    <button
                      onClick={() => addProduct(product, selectedVariant)}
                      className="btn btn-primary btn-block"
                    >
                      Add to cart
                    </button>
                  ) : (
                    <span className="text-danger out-of-stock">
                      Out of stock
                    </span>
                  )}
                </React.Fragment>
              )}
            </span>
          ) : (
            <span className="text-danger out-of-stock">Out of stock</span>
          )}
        </div>
      </div>
    </ScrollAnimation>
  );
};
//
export default ProductItems;
