import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import menuItems from "./menuItems";
import axios from "axios";
import "./css/home.css";
import ProductsList from "../../components/ProductList";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  links: {
    textDecoration: "none",
    color: "#000",
  },
  menuHeader: {
    paddingLeft: "5px",
  },
  menuList: {
    fontSize: "15px",
    color: "#333333",
    textTransform: "capitalize",
    lineHeight: "18px",
    borderBottom: "1px solid #fff",
    backgroundColor: "#f8f8f8",
  },
}));

function Shop() {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [productPath, setProductPath] = useState([]);
  const [state, setState] = useState({});
  const handleClick = (item) => {
    setState((prevState) => ({ [item]: !prevState[item] }));
  };
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    axios
      .get(`products`)
      .then((response) => {
        let data = [...response.data.data.data];
        setProducts(data);
        setProductPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handler = (children) => {
    return children.map((subOption) => {
      if (!subOption.children) {
        return (
          <div key={subOption.name}>
            <ListItem className={classes.menuList} button key={subOption.name}>
              <Link to={subOption.url} className={classes.links}>
                <ListItemText primary={subOption.name} />
              </Link>
            </ListItem>
          </div>
        );
      }
      return (
        <div key={subOption.name}>
          <ListItem
            className={classes.menuList}
            button
            onClick={() => handleClick(subOption.name)}
          >
            <ListItemText primary={subOption.name} />
            {state[subOption.name] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[subOption.name]} timeout="auto" unmountOnExit>
            {handler(subOption.children)}
          </Collapse>
        </div>
      );
    });
  };

  console.log("stateeeeee", state);

  return (
    <div>
      <div className="breadcrumb-area">
        {" "}
        <h1 className="product-details-header text-center">Shop</h1>
      </div>
      <div className="p_details_main bg-white px-5">
        <div className="row">
          <div className="col-md-2">
            <div class="sidebar-title">
              <h3>categories</h3>
            </div>
            <List>{handler(menuItems.data)}</List>
          </div>
          <div className="col-md-9">
            <ProductsList products={products} path={productPath} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
