import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import CartItem from "./CartItem";
import axios from "axios";
import "./css/cart.css";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

// import styles from './CartProducts.module.scss';

const CartProducts = () => {
  const { cartItems, total } = useContext(CartContext);
  const [shop, setShop] = useState(0);
  const [value, setValue] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountData, setDiscountData] = useState([]);
  useEffect(() => {
    getDiscountList();
  }, []);
  const getDiscountList = () => {
    axios
      .get("delivery-charge-discounts")
      .then((response) => {
        let data = response.data.data.data;
        const sorted = data.sort(function (a, b) {
          return a.bill - b.bill;
        });
        setShop(sorted[0].bill);
        setDiscount(sorted[0].discount);
        setDiscountData(sorted);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("here comes the total", total);

    // setValue((total * 100) / 400);
    // setShop(400 - total);
    // setValue((100 / 400) * (400 - total));
    if (discountData.length > 0) {
      let result = getDiscount(total, discountData);
      let result2 = getNextDiscount(total, discountData);
      console.log("resulttttttttttt=>", result);
      console.log("resulttttttttttt 222222=>", result2);

      let val = result2.bill - total;
      let per = (total * 100) / result2.bill;
      let discount = result2.discount;
      setDiscount(discount);

      console.log("valueeeeee", val);
      console.log("perrrrrrr", per);

      if (val <= 0) {
        setShop(0);
      } else {
        setShop(val);
      }
      if (per >= 100) {
        setValue(100);
      } else {
        setValue(per);
      }
    }
  }, [total]);
  const getDiscount = (price, discounts) => {
    const discountArrayNew = JSON.parse(JSON.stringify(discounts));
    const sorted = discountArrayNew.sort(function (a, b) {
      return a.bill - b.bill;
    });

    const discountObj = sorted.find((item, index, array) => {
      if (
        index < array.length - 1 &&
        price >= item.bill &&
        price < array[index + 1].bill
      ) {
        return true;
      }

      if (index === array.length - 1 && price >= item.bill) {
        return true;
      }

      // if true is returned, item is returned and iteration is stopped
      // for falsy scenario returns undefined
    });

    if (discountObj === undefined) {
      return 0;
    }
    return discountObj;
  };

  const getNextDiscount = (price, discounts) => {
    console.log("price of next discoutn", price);
    const discountArrayNew = JSON.parse(JSON.stringify(discounts));
    const sorted = discountArrayNew.sort(function (a, b) {
      return a.bill - b.bill;
    });
    let position = 0;
    const discountObj = sorted.find((item, index, array) => {
      if (
        index < array.length - 1 &&
        price >= item.bill &&
        price < array[index + 1].bill
      ) {
        position = index;
        return true;
      }

      if (index === array.length - 1 && price >= item.bill) {
        position = index;
        return true;
      }

      // if true is returned, item is returned and iteration is stopped
      // for falsy scenario returns undefined
    });

    if (position === 0 && discountObj === undefined) {
      return sorted[0];
    }
    if (position <= sorted.length - 2) {
      return sorted[position + 1];
    }

    return sorted[position];
  };

  return (
    <div className={"styles.p__containersa"}>
      <div className="px-3">
        {total >= shop && <h5>Discount : {discount} tk</h5>}
        {shop !== 0 && (
          <h5>
            Shop {Number(shop).toFixed(2)} tk more to get {discount} tk discount
            on delivery charge
          </h5>
        )}
        {shop === 0 && (
          <h5>You have saved the Delivery charge to {discount} tk</h5>
        )}
      </div>

      <BorderLinearProgress variant="determinate" value={value} />
      <div className="card card-body border-0">
        {cartItems.map((product) => (
          <CartItem key={product.selectedVariant.id} product={product} />
        ))}
      </div>
      {/* {cartItems.length > 0 && (
        <div className="text-right">
          <Link
            type="button"
            to={"/checkout"}
            className="btn btn-sm btn-primary"
          >
            Checkout
          </Link>
        </div>
      )} */}
    </div>
  );
};

export default CartProducts;
