import React, { createContext, useReducer } from "react";
import { CartReducer, sumItems } from "./CartReducer";

export const CartContext = createContext();

const storage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
const authenticated = localStorage.getItem("authenticated")
  ? JSON.parse(localStorage.getItem("authenticated"))
  : false;
const initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
  loggedIn: authenticated,
  loginModal: false,
};

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const increase = (payload, variant) => {
    dispatch({ type: "INCREASE", payload, variant });
  };

  const decrease = (payload, variant) => {
    dispatch({ type: "DECREASE", payload, variant });
  };

  const addProduct = (payload, variant) => {
    // console.log("payload", payload, "variant", variant);
    dispatch({ type: "ADD_ITEM", payload, variant });
  };

  const removeProduct = (payload, variant) => {
    dispatch({ type: "REMOVE_ITEM", payload, variant });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };
  const openLogin = () => {
    dispatch({ type: "OPEN_MODAL" });
  };
  const closeLogin = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  const handleCheckout = () => {
    // console.log("CHECKOUT", state);
    dispatch({ type: "CHECKOUT" });
  };
  const UserLogin = () => {
    dispatch({ type: "USER_AUTH" });
    // console.log("CHECKOUT", state);
  };
  const UserLogout = () => {
    // console.log("CHECKOUT", state);
    dispatch({ type: "USER_LOGOUT" });
    // console.log("CHECKOUT", state);
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    UserLogin,
    closeLogin,
    openLogin,
    state,
    UserLogout,

    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
