import React, { useState, useEffect, useContext } from "react";
import "./css/checkout.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import axios from "axios";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { divide } from "lodash";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { formatNumber } from "../../helpers/utils";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import userAuth from "../../routingRules/userAuth";
import { UserAPI } from "../../AxiosRequest/UserAPI";
import _ from "lodash";

export default function Checkout() {
  let history = useHistory();
  const [showLogin, setShowLogin] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isLogged, setLoggedIn] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    cartItems,
    state,
    total,
    handleUser,
    checkout,
    clearCart,
    loggedIn,
    openLogin,
  } = useContext(CartContext);
  const [policeStations, setPoliceStations] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState("");
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [policeStationValue, setPoliceStationValue] = useState("");
  const [ship, setShip] = useState("");
  const [discount, setDiscount] = useState(0);
  // const [totalPrice, setTotalPrice] = useState(parseInt(total));
  const [totalRegularPrice, setTotalRegularPrice] = useState(Number);
  const [subTotal, setSubTotal] = useState(Number);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [code, setCode] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [discountData, setDiscountData] = useState([]);
  const [serverError, setServerError] = useState({});

  useEffect(() => {
    if (!loggedIn) {
      history.push("/");
    }
  }, [loggedIn]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let ImaginaryUserToken = JSON.parse(localStorage.getItem("ImaginaryUserToken"));
    if (
      ImaginaryUserToken !== null &&
      ImaginaryUserToken !== undefined &&
      ImaginaryUserToken !== ""
    ) {
      console.log("You are logged in");
      // setLoggedIn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    GetPoliceStations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm, district]);
  useEffect(() => {
    getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtSearchTerm]);

  const GetPoliceStations = () => {
    console.log("distric value for api", district);
    if (district !== "") {
      axios
        .get(`districts/${district}?name=${stationSearchTerm}`)
        .then((response) => {
          console.log("pppppp", response.data.data);
          setPoliceStations(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  const getDistricts = () => {
    axios
      .get(`districts?name=${districtSearchTerm}`)
      .then((response) => {
        console.log("dddd", response.data.data.data);
        setDistrictList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleOnInputCaptureMed = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  const handleOnInputCaptureDis = _.debounce((event) => {
    setDistrictSearchTerm(event.target.value);
  }, 500);

  const getShipping = () => {
    let pID = parseInt(policeStation);
    if (policeStation !== "") {
      axios
        .post(`delivery-charge?police_station_id=${pID}`)
        .then((response) => {
          console.log("pppppp", response.data.data);
          console.log("tpppppp", totalPrice);
          let shipC = parseInt(response.data.data.value);
          if (ship !== "") {
            console.log("tpppppp lopp", totalPrice - parseInt(ship));
            let totalPP = totalPrice - parseInt(ship);
            // setTotalPrice(totalPrice - parseInt(ship));
            setShip(shipC);
            setTotalPrice(totalPP + shipC);
          } else {
            setShip(shipC);
            setTotalPrice(totalPrice + shipC);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  useEffect(() => {
    getShipping();
  }, [policeStation]);

  var validationSchemaLogin = yup.object().shape({
    phone: yup.string().required(),
    password: yup.string().required(),
  });

  const initialValuesLogin = {
    phone: "",
    password: "",
  };
  const onSubmitLogin = (values, { resetForm }) => {
    setLoading(true);
    axios
      .post(`auth/login`, values)
      .then((response) => {
        console.log("response=====>", response);
        setLoading(false);
        setLoggedIn(true);
        let ImaginaryUserToken = response.data.access_token;
        localStorage.setItem(
          "ImaginaryUserToken",
          JSON.stringify(ImaginaryUserToken)
        );
        console.log(ImaginaryUserToken);
        setLoading(false);
        handleUser();
        userAuth.login(() => {
          console.log("sosdosdiofdsf");
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
        setLoginError(error.response.data.error);
        setLoading(false);
      });
  };
  var validationSchemaCoupon = yup.object().shape({
    code: yup.string().required(),
  });

  const initialValuesCoupon = {
    code: "",
  };
  const onSubmitCoupon = (values, { resetForm }) => {
    let objToSend = {
      code: values.code,
    };
    setLoading(true);
    axios
      .post(`coupons/check-validity`, objToSend)
      .then((response) => {
        console.log("response=====>", response.data.data);
        let data = response.data.data;
        if (code === "" && data.discount_type === "Flat") {
          console.log("dsfkjksldfjlksdfjldj");
          setCode(values.code);
          console.log("discountttttt", data.discount + totalDiscountPrice);
          setTotalDiscountPrice(parseInt(data.discount) + totalDiscountPrice);
          setCodeValue(parseInt(data.discount));
          setTotalPrice(
            totalPrice - (totalDiscountPrice + parseInt(data.discount))
          );
        } else if (code === "" && data.discount_type === "%") {
          setCode(values.code);
          let getPv = (data.discount / 100) * totalPrice;
          console.log("get pvvv", getPv);
          console.log("get totalPrice", totalPrice);
          if (getPv > data.maximum) {
            setCodeValue(parseInt(data.maximum));
            setTotalDiscountPrice(totalDiscountPrice + data.maximum);
            setTotalPrice(
              totalPrice - (totalDiscountPrice + parseInt(data.maximum))
            );
          } else {
            setCodeValue(parseInt(getPv));
            setTotalDiscountPrice(totalDiscountPrice + parseInt(getPv));
            setTotalPrice(totalPrice - (totalDiscountPrice + parseInt(getPv)));
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  var validationSchemaOrder = yup.object().shape({
    customer_name: yup.string().required("Customer name is required"),
    address: yup.string().required("Required"),
    police_station_id: yup.string().required("Police Station is required"),
  });

  const initialValuesOrder = {
    customer_name: "",
    address: "",
    police_station_id: "",
    note: "",
  };
  const onSubmitOrder = (values, { resetForm }) => {
    setLoading(true);
    let objToSend = { ...values };
    if (code !== "") {
      objToSend.code = code;
    }
    if (objToSend.note === "") {
      delete objToSend.note;
    }

    let invoiceDetails = cartItems.map((o) => {
      let obj = {
        product_variant_id: o.selectedVariant.id,
        quantity: o.quantity,
      };
      return obj;
    });
    objToSend.invoiceDetails = invoiceDetails;
    console.log("invoiceDetails", invoiceDetails);
    console.log("valuesssss", objToSend);

    UserAPI.post(`invoices`, objToSend)
      .then((response) => {
        setServerError({});
        alert(response.data.message);
        setLoading(false);
        userAuth.login(() => {
          // console.log("sosdosdiofdsf");
          history.push("/customer/invoice");
        });

        clearCart();
      })
      .catch((error) => {
        console.log(error.response);
        setServerError(error.response.data.errors);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDiscountList();
  }, []);
  const getDiscountList = () => {
    axios
      .get("delivery-charge-discounts")
      .then((response) => {
        let data = response.data.data.data;
        const sorted = data.sort(function (a, b) {
          return a.bill - b.bill;
        });

        setDiscountData(sorted);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const testsTotalPrice = cartItems.map((o) => parseInt(o.price));

    const subTotal = cartItems.map((o) => {
      return parseInt(o.selectedVariant.price * o.quantity);
    });
    console.log("sub total", subTotal);
    let totalSub = subTotal.reduce(function (a, b) {
      return a + b;
    }, 0);
    setSubTotal(totalSub);

    // const testsTotalPercentageDiscount = cartItems
    //   .filter((o) => o.discount_type === "%")
    //   .map((o) => {
    //     return parseInt((o.price / 100) * o.discount);
    //   });
    const testsTotalPercentageDiscount = cartItems.map((o) => {
      return parseInt(
        (o.selectedVariant.price / 100) *
          o.selectedVariant.discount *
          o.quantity
      );
    });
    console.log("testsTotalPercentageDiscount", testsTotalPercentageDiscount);
    // const testsTotalFlatDiscount = cartItems.map((o) =>
    //   parseInt(o.selectedVariant.discount)
    // );
    // const testsTotalFlatDiscount = cartItems.map((o) => {
    //   if () {
    //     return parseInt(o.price - o.discount);
    //   }
    // });
    // console.log("total discoutn====>", testsTotalPercentageDiscount);
    // console.log("total disaacout flat====>", testsTotalFlatDiscount);
    let totalPrice = testsTotalPrice.reduce(function (a, b) {
      return a + b;
    }, 0);
    // setTotalRegularPrice(totalPrice);
    let totalDicountP = testsTotalPercentageDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);

    console.log("totalDicountP", totalDicountP);

    // let totalDicountF = testsTotalFlatDiscount.reduce(function (a, b) {
    //   return a + b;
    // }, 0);

    // let totalD = totalDicountP + totalDicountF;
    if (discountData.length > 0) {
      // console.log("discount p", totalDicountP);
      let result = getDiscount(total, discountData);
      if (!_.isEmpty(result)) {
        let discountA = result.discount;

        totalDicountP = totalDicountP + discountA;
      }
    }

    // let totalD = totalDicountP;
    let inTotalDiscount = "";
    console.log("current discount PPPPPPPPPPP:::::::::", totalDicountP);
    if (codeValue !== "" && totalDiscountPrice > 0) {
      setTotalDiscountPrice(totalDicountP + codeValue);

      setTotalPrice(totalSub - (totalDicountP + codeValue));
    } else {
      setTotalDiscountPrice(totalDicountP);

      setTotalPrice(totalSub - totalDicountP);
    }
  }, [cartItems, discountData]);
  console.log("total discount price", totalDiscountPrice);
  const getDiscount = (price, discounts) => {
    const discountArrayNew = JSON.parse(JSON.stringify(discounts));
    const sorted = discountArrayNew.sort(function (a, b) {
      return a.bill - b.bill;
    });

    const discountObj = sorted.find((item, index, array) => {
      if (
        index < array.length - 1 &&
        price >= item.bill &&
        price < array[index + 1].bill
      ) {
        return true;
      }

      if (index === array.length - 1 && price >= item.bill) {
        return true;
      }

      // if true is returned, item is returned and iteration is stopped
      // for falsy scenario returns undefined
    });

    if (discountObj === undefined) {
      return 0;
    }
    return discountObj;
  };

  const getNextDiscount = (price, discounts) => {
    console.log("price of next discoutn", price);
    const discountArrayNew = JSON.parse(JSON.stringify(discounts));
    const sorted = discountArrayNew.sort(function (a, b) {
      return a.bill - b.bill;
    });
    let position = 0;
    const discountObj = sorted.find((item, index, array) => {
      if (
        index < array.length - 1 &&
        price >= item.bill &&
        price < array[index + 1].bill
      ) {
        position = index;
        return true;
      }

      if (index === array.length - 1 && price >= item.bill) {
        position = index;
        return true;
      }

      // if true is returned, item is returned and iteration is stopped
      // for falsy scenario returns undefined
    });

    if (position === 0 && discountObj === undefined) {
      return sorted[0];
    }
    if (position <= sorted.length - 2) {
      return sorted[position + 1];
    }

    return sorted[position];
  };

  return (
    <div>
      <div className={loading ? "loading-indicator" : ""}></div>
      <div className="breadcrumb-area">
        {" "}
        <h1 className="product-details-header text-center">Shop</h1>
      </div>
      <div className="checkout-page-wrapper bg-white pt-60 pb-54">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="checkoutaccordion" id="checkOutAccordion">
                <div className="card">
                  <h3>
                    Have A Coupon?{" "}
                    <span onClick={() => setShowCoupon(!showCoupon)}>
                      Click Here To Enter Your Code
                    </span>
                  </h3>
                  {showCoupon && (
                    <div id="couponaccordion" style={{}}>
                      <div className="card-body">
                        <div className="cart-update-option">
                          <div className="apply-coupon-wrapper">
                            <Formik
                              initialValues={initialValuesCoupon}
                              validationSchema={validationSchemaCoupon}
                              onSubmit={onSubmitCoupon}
                              render={({ values }) => (
                                <Form className=" d-block d-md-flex">
                                  <Field
                                    type="text"
                                    placeholder="Enter Your Coupon Code"
                                    name={`code`}
                                  />
                                  <br />
                                  <ErrorMessage
                                    name={`code`}
                                    component="div"
                                    className="error-message"
                                  />
                                  <button
                                    disabled={code !== "" || loading}
                                    type="submit"
                                    className="btn btn__bg"
                                  >
                                    {loading && (
                                      <React.Fragment>
                                        {" "}
                                        <span>Applying</span>{" "}
                                        <CircularProgress
                                          style={{ marginTop: "-2px" }}
                                          size={24}
                                        />
                                      </React.Fragment>
                                    )}
                                    {!loading && code === "" && "Apply Coupon"}
                                    {code !== "" && "Coupon applied"}
                                  </button>
                                </Form>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-6">
              <div class="checkout-billing-details-wrap">
                <h2>Billing Details</h2>
                <div class="billing-form-wrap">
                  <Formik
                    initialValues={initialValuesOrder}
                    validationSchema={validationSchemaOrder}
                    onSubmit={onSubmitOrder}
                    render={({ values, setFieldValue, handleSubmit }) => (
                      <Form>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="single-input-item">
                              <label for="f_name" class="required">
                                Name (
                                <span style={{ color: "Red" }}>Required</span>)
                              </label>
                              <Field
                                type="text"
                                name="customer_name"
                                placeholder="Name"
                                required=""
                                disabled={!loggedIn}
                              />
                              <ErrorMessage
                                name="customer_name"
                                component="div"
                                className="error-message"
                              />
                              {!_.isEmpty(serverError) &&
                                serverError.customer_name !== undefined && (
                                  <span className="error-message">
                                    {" "}
                                    {serverError.customer_name}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <div class="single-input-item mb-2">
                          <label for="street-address" class="required mt-20">
                            Address (
                            <span style={{ color: "Red" }}>Required</span>)
                          </label>
                          <Field
                            type="text"
                            name="address"
                            placeholder="Address"
                            disabled={!loggedIn}
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error-message"
                          />
                          {!_.isEmpty(serverError) &&
                            serverError.address !== undefined && (
                              <span className="error-message">
                                {" "}
                                {serverError.address}
                              </span>
                            )}
                        </div>

                        <div className="form-group row ">
                          <div className="col-sm-12">
                            <label class="required mt-20">
                              District (
                              <span style={{ color: "Red" }}>Required</span>)
                            </label>
                            <Autocomplete
                              disabled={!loggedIn}
                              freeSolo
                              id="disabledHelperText"
                              name="district"
                              placeholder="Select District"
                              options={districtList}
                              onInputCapture={(event) => {
                                event.persist(); // As we are accessing the event in asynchronous way.
                                handleOnInputCaptureDis(event);
                              }}
                              // getOptionLabel={(option) => option.name}
                              getOptionLabel={(option) => {
                                return option.district;
                              }}
                              // value={districtValue}
                              onChange={(e, value) => {
                                if (value == null) {
                                  console.log("Hello");
                                  setPoliceStationValue("");
                                  setPoliceStations([]);
                                  setFieldValue("police_station_id", "");
                                } else {
                                  console.log(value);
                                  setPoliceStationValue("");
                                  setPoliceStations([]);
                                  setDistrict(value.district);

                                  // GetPoliceStations();
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextField}
                                  {...params}
                                  onChange={(e, value) => {
                                    console.log(e.target.value);
                                  }}
                                  name="district"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Select District"
                                />
                              )}
                            />
                            <ErrorMessage
                              name="district"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="form-group row ">
                          <div className="col-sm-12">
                            <label class="required mt-20">
                              Police Station (
                              <span style={{ color: "Red" }}>Required</span>){" "}
                            </label>
                            <Autocomplete
                              disabled={district === ""}
                              freeSolo
                              id="disabledHelperText"
                              name="police_station_id"
                              options={policeStations}
                              value={policeStationValue}
                              onInputCapture={(event) => {
                                event.persist(); // As we are accessing the event in asynchronous way.
                                handleOnInputCaptureMed(event);
                              }}
                              getOptionLabel={(option) => {
                                return option.name || policeStationValue;
                              }}
                              onChange={(e, value) => {
                                if (value == null) {
                                  setFieldValue("police_station_id", "");
                                } else {
                                  console.log(value);
                                  setPoliceStationValue(value.name);

                                  setFieldValue("police_station_id", value.id);
                                  setPoliceStation(value.id);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextField}
                                  {...params}
                                  onChange={(e, value) => {
                                    console.log(e.target.value);
                                  }}
                                  name="police_station_id"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Select Police Station"
                                />
                              )}
                            />
                            <ErrorMessage
                              name="police_station_id"
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverError) &&
                              serverError.police_station_id !== undefined && (
                                <span className="error-message">
                                  {" "}
                                  {serverError.police_station_id}
                                </span>
                              )}
                          </div>
                        </div>

                        <div class="single-input-item mb-2">
                          <label for="note">Order Note</label>
                          <Field
                            disabled={!loggedIn}
                            type="text"
                            name="note"
                            placeholder="Note"
                          />
                          <ErrorMessage
                            name="note"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div class="form-group summary-footer-area">
                          <button
                            type="button"
                            onClick={() => {
                              console.log(
                                "state login",
                                state.loggedIn,
                                loggedIn
                              );
                              if (loggedIn === false) {
                                openLogin();
                              } else if (loggedIn === true) {
                                handleSubmit();
                              }
                            }}
                            class="btn btn__bg"
                          >
                            {loggedIn === false
                              ? "Login before checkout"
                              : "Place order"}
                          </button>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="order-summary-details mt-md-50 mt-sm-50">
                <h2>Your Order Summary</h2>
              </div>
              <div className="order-summary-content">
                <div className="order-summary-table table-responsive text-center">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Products</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((product, index) => (
                        <tr>
                          <td>
                            <Link to={`/product-details/${product.id}`}>
                              {product.name}{" "}
                              <strong> × {product.quantity}</strong>
                            </Link>
                          </td>
                          <td>
                            {" "}
                            <strong>৳</strong>{" "}
                            {formatNumber(
                              product.selectedVariant.price * product.quantity
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Sub Total</td>
                        <td>
                          <strong>৳{subTotal}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping</td>
                        <td class="d-flex justify-content-center">
                          <ul class="shipping-type">
                            <li>
                              <label
                                class="custom-control-labela"
                                for="flatrate"
                              >
                                ৳{ship}
                              </label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>
                          <strong>
                            ৳
                            {totalDiscountPrice > 0
                              ? `- ${totalDiscountPrice}`
                              : 0}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Total Amount</td>
                        <td>
                          <strong>৳{totalPrice}</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="order-payment-method">
                  <div class="single-payment-method show">
                    <div class="payment-method-details">
                      <Link to="/cart-details" className="btn btn__bg d-block">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
