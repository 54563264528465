import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function CouponList() {
  const [couponList, setCouponList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [couponId, setCouponId] = useState(Number);

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getCouponList();
  }, [params]);

  const getCouponList = () => {
    AdminAPI
      .get(`coupons`, {params: params})
      .then((response) => {
        setCouponList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onChagneStatus = (id, e) => {
    AdminAPI
      .post(`coupons/${id}/update-status`, {
        status: e.target.value
      })
      .then((response) => {
        getCouponList();
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/add-coupon"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Coupon
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input className="form-control" placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search")
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Maximum</th>
                    <th scope="col">Valid till</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {couponList.map((coupon, i) => (
                    <tr key={i}>
                      <td>{coupon.code}</td>
                      <td>{coupon.discount}{coupon.discount_type == "%" ? "%" : " Tk"}</td>
                      <td>{coupon.maximum == null ? "0" : coupon.maximum} Tk</td>
                      <td>{coupon.valid_date}</td>
                      <td>
                        <select
                          value={coupon.status}
                          onChange={(e) => {
                            onChagneStatus(coupon.id, e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </td>
                      <td>
                        <Link
                          to={`/admin/coupon/view/${coupon.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CouponList;
