import React, { useContext } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { InvoiceContext } from "../../contexts/InvoiceContext";
import { makeStyles } from "@material-ui/core/styles";
import { formatNumber } from "../../helpers/utils";
import { Icon } from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
    border: "none",
    color: "#000",
    "&:hover": {
      // color: "#40a9ff",
      border: "none",
    },
  },
  disabled: {
    border: "none!important",
  },
  outlinedSizeSmall: {
    padding: "0px 9px",
  },
}));

const InvoiceItem = ({ product }) => {
  const classes = useStyles();
  const { increase, decrease, removeProduct } = useContext(InvoiceContext);

  return (
    <div className="cart-list-itms">
      <table class="table table-sm table-borderless">
        <tbody>
          <tr className="cart-row">
            <td>
              {" "}
              <ButtonGroup
                orientation="vertical"
                color="primary"
                aria-label="vertical outlined primary button group"
              >
                <Button
                  size="small"
                  onClick={() => increase(product, product.selectedVariant)}
                  classes={{
                    root: classes.root,
                    outlinedSizeSmall: classes.outlinedSizeSmall,
                  }}
                >
                  <Icon size="small">expand_less</Icon>
                </Button>
                <Button
                  size="small"
                  classes={{
                    root: classes.root,
                    outlinedSizeSmall: classes.outlinedSizeSmall,
                  }}
                  style={{ pointerEvents: "none" }}
                >
                  {product.quantity}
                </Button>
                <Button
                  size="small"
                  disabled={product.quantity === 1}
                  onClick={() => decrease(product, product.selectedVariant)}
                  classes={{
                    root: classes.root,
                    disabled: classes.disabled,
                    outlinedSizeSmall: classes.outlinedSizeSmall,
                  }}
                >
                  {" "}
                  <Icon size="small">expand_more</Icon>
                </Button>
              </ButtonGroup>
            </td>
            <td>
              {/* <img
                alt={product.title}
                style={{ margin: "0 auto" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/files/product/${product.id}/${product.selectedVariant.id}/${product.selectedVariant.product_photos[0].photo}`}
                className=""
              /> */}
            </td>
            <td>
              {" "}
              <h5 className="mb-1">{product.name}</h5>
            </td>
            <td>
              {" "}
              <p className="mb-1">
                {formatNumber(product.selectedVariant.price)} Tk
              </p>
            </td>
            <td>
              {" "}
              <Button
                size="small"
                onClick={() => removeProduct(product, product.selectedVariant)}
                classes={{ root: classes.root }}
              >
                {" "}
                <Icon size="small">close</Icon>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItem;
