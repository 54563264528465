// import React, { useContext } from "react";
import ProductItem from "./ProductItem";
import React, { useState, useEffect, useContext } from "react";

import axios from "axios";
const ProductsGrid = () => {
  const [products, setProducts] = useState([]);
  const [jproducts, setJProducts] = useState([]);

  return (
    <div className={"styles.p__container"}>
      <div className="row">
        <div className="col-sm-8">
          <div className="py-3">{products.length} Products</div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <input
              type="text"
              name=""
              placeholder="Search product"
              className="form-control"
              id=""
            />
          </div>
        </div>
      </div>
      <div className={"styles.p__grid"}>
        {products.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </div>
      <div className={"styles.p__footer"}>
        {jproducts.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductsGrid;
