import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function TextList() {
  const [textList, setTextList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getTextList();
  }, [params]);

  const getTextList = () => {
    AdminAPI.get(`banners?type=text`, { params: params })
      .then((response) => {
        setTextList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Heading</th>
                    <th scope="col">Detail</th>
                    <th scope="col">URL</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {textList.map((text, i) => (
                    <tr key={i}>
                      <td>{text.heading}</td>
                      <td>{text.detail}</td>
                      <td>{text.url}</td>
                      
                      <td>
                        <Link
                          to={`/admin/text/view/${text.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default TextList;
