import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../helpers/utils";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

function CustomerDetails() {
  const [customer, setCustomer] = useState({});
  const [customerInvoices, setCustomerInvoices] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  let { id } = useParams();
  let history = useHistory();

  const [params, setParams] = useState({
    customer_id: id,
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getCustomerDetails();
  }, []);

  useEffect(() => {
    getCustomerInvoices();
  }, [params]);

  const getCustomerDetails = () => {
    AdminAPI.get(`users/${id}`)
      .then((response) => {
        setCustomer(response.data.data);
        getCustomerInvoices();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getCustomerInvoices = () => {
    AdminAPI.get(`invoices`, {params: params})
    .then((response) => {
      setCustomerInvoices(response.data.data.data);
      setTotalPage(response.data.data.last_page);
    })
    .catch((error) => {
      console.log(error.data);
    })
  }

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const onChangeStatus = (status) => {
    AdminAPI.post(`users/${id}/update-status`, {
      status: status,
    })
      .then((response) => {
        getCustomerDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group row">
              <div className="col-12">
                Name:{" "}
                <strong>
                  {customer.name}
                </strong>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                Phone:{" "}
                <strong>
                  {customer.mobile_number}
                </strong>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                Email:{" "}
                <strong>
                  {customer.email}
                </strong>
              </div>
            </div>
            {customer.police_station != null &&(  
              <div className="form-group row">
                <div className="col-12">
                  Address:{" "}
                  <strong>
                    {customer.address}, {customer.police_station.name}, {customer.police_station.district}
                  </strong>
                </div>
              </div>
            )}
            <div className="form-group row">
              <div className="col-sm-1"> Status: </div>
              <div className="col-sm-4">
                {" "}
                <select
                  value={customer.status}
                  onChange={(e) => {
                    onChangeStatus(e.target.value);
                  }}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                Member since:{" "}
                <strong>
                  {moment(customer.created_at).format("DD/MM/Y")}
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="col-5 my-1 ml-auto">
                <input className="form-control" placeholder="Search"
                  onChange={(e) => {
                    handleColumnWiseSearch(e.target.value, "search")
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th className="text-center" scope="col">Date</th>
                    <th className="text-center" scope="col">Address</th>
                    <th className="text-center" scope="col">Price</th>
                    <th className="text-center" scope="col">Bill</th>
                    <th className="text-center" scope="col">Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {customerInvoices.map((invoice, i) => (
                      <tr key={i}>
                        <td>{moment(invoice.updated_at).format('DD-MM-Y')}</td>
                        <td>{invoice.address} {invoice.police_station != null ? ", "+ invoice.police_station.name+ ", "+invoice.police_station.district : ""}</td>
                        <td  className="text-right">{formatNumber(invoice.price)}</td>
                        <td  className="text-right">{formatNumber(invoice.bill)}</td>
                        <td className="text-center">{invoice.status}</td>
                        <td>
                          <Link
                            to={`/admin/invoice/view/${invoice.id}`}
                            type="button"
                            className="btn btn-sm btn-primary mb-1"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
