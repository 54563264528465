import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./css/home.css";
import { CartContext } from "../../contexts/CartContext";
import { formatNumber } from "../../helpers/utils";
import Slider from "react-slick";
import axios from "axios";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import SliderImage from "react-zoom-slider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProductsGrids from "../../components/ProductGrids";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./css/home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      fontSize: "9px",
    },
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const data = [
  // {
  //   image: "https://via.placeholder.com/150",
  //   text: "img1",
  // },
];

export default function ProductDetails() {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [productPath, setProductPath] = useState({});
  let pID = parseInt(productId);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [images, setImages] = useState(data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { addProduct, cartItems, increase, decrease, removeProduct } =
    useContext(CartContext);

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.selectedVariant.id === product.id);
  };
  const checkQuantity = (product) => {
    // console.log("product", product);
    let selectedProduct = cartItems.find(
      (item) => item.selectedVariant.id === product.id
    );
    if (selectedProduct !== undefined) {
      if (Object.keys(selectedProduct).includes("quantity"))
        return selectedProduct.quantity;
    }
    return 0;
  };
  const decreaseProduct = (item) => {
    if (checkQuantity(item) > 1) {
      decrease(productDetails, selectedVariant);
    } else {
      removeProduct(productDetails, selectedVariant);
    }
  };
  const onChagneVarint = (e) => {
    console.log(e.target.value);
    let pID = parseInt(e.target.value);
    let p = { ...productDetails };
    let arr = p.product_variants.filter((o) => o.id === pID);
    setSelectedVariant(arr[0]);
  };

  useEffect(() => {
    // if (productDetails.product_variants.length > 0) {
    //   setSelectedVariant(productDetails.product_variants[0]);
    // }
  }, [productDetails]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productId]);
  useEffect(() => {
    // getProducts();
  }, []);

  const getProducts = () => {
    axios
      .get(`products`)
      .then((response) => {
        let data = [...response.data.data.data];
        let filetered = data.filter((o) => o.id !== pID);
        setProducts(filetered);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getProductDetails = () => {
    axios
      .get(`products/${pID}`)
      .then((response) => {
        console.log("proudct path", response.data.path);
        setProductPath(response.data.path);
        setProductDetails(response.data.data);
        if (response.data.data !== undefined) {
          let rP = response.data.data.subcategory.category.name;
          relatedProducts(rP);
          setSelectedVariant(response.data.data.product_variants[0]);
          let pVar = response.data.data.product_variants;
          let arr = [];
          let iArr = pVar.map((o, i) => {
            let obj = o.product_photos;

            obj.map((o) => arr.push(o));
          });

          let imageToShowSlider = arr.map((o, i) => {
            let imageString = `${process.env.REACT_APP_PUBLIC_URL}/${response.data.path}/${response.data.data.id}/${o.product_variant_id}/${o.photo}`;
            let fomattedImgSrc = imageString.split(" ").join("%20");

            let objToSlider = {
              image: fomattedImgSrc,
              text: o.photo,
            };
            return objToSlider;
          });
          if (imageToShowSlider.length > 0) {
            setImages(imageToShowSlider);
          } else {
            setImages([]);
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const relatedProducts = (current) => {
    axios
      .get(`category/${current}`)
      .then((response) => {
        let curr = response.data.data.current_page;
        let last = response.data.data.last_page;
        let pPp = [];
        let copyArr = [...response.data.data.data];
        if (copyArr.length > 1) {
          let filt = copyArr.filter((o) => o.id !== pID);
          console.log("product path", response.data.data.path);
          // setProductPath(response.data.data.path);
          setProducts(filt);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getProductDetails();
  }, [productId]);

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img width={`100%`} src={images[i].image} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="breadcrumb-area">
        {" "}
        <h1 className="product-details-header text-center">Product Details</h1>
      </div>

      <div className="p_details_main bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="prod-slider-area">
                {!isMobile && images.length > 0 ? (
                  <div className="slider_image_box">
                    <SliderImage
                      data={images}
                      width={"350px"}
                      direction="right"
                      showDescription={false}
                    />
                  </div>
                ) : (
                  <Slider {...settings}>
                    {images.map((img) => (
                      <div className="product-slider-image">
                        <img key={img.src} src={img.image} alt={img.image} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
            <div className="col-md-7">
              <div className="product-content-list">
                <div className="product-name">
                  <h4>
                    <a>{productDetails.name}</a>
                  </h4>
                </div>
                <div class="price-box">
                  {!_.isEmpty(selectedVariant) && (
                    <h2>
                      <span class="regular-price">
                        {formatNumber(
                          selectedVariant.price -
                            (selectedVariant.discount / 100) *
                              selectedVariant.price
                        )}{" "}
                        Tk
                      </span>
                    </h2>
                  )}
                  {selectedVariant.discount !== 0 &&
                    !_.isEmpty(selectedVariant) && (
                      <span class="old-price">
                        <del>{selectedVariant.price} Tk</del>
                      </span>
                    )}
                </div>
                {/* {!_.isEmpty(productDetails) &&
                productDetails.product_variants !== undefined &&
                productDetails.product_variants.length > 1 && (
                  <div>
                    <div className="parent">
                      {productDetails.product_variants.map((p, i) => (
                        <div className="row">
                          <div className="col-6">
                            <div className="unit-box">{p.unit}</div>
                          </div>
                          <div className="col-6">
                            <div className="price-box">
                              <span className="regular-price">{p.price}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )} */}

                {/*  {productDetails !== undefined &&
                Object.keys(productDetails).includes("id") &&
                productDetails.product_variants !== undefined &&
                productDetails.product_variants.length > 1 && (
                  <div>
                    <div className="parent">
                      {productDetails.product_variants.map((p, i) => (
                        <div className="row">
                          <div className="col-6">
                            <div className="unit-box">{p.unit}</div>
                          </div>
                          <div className="col-6">
                            <div className="price-box">
                              <span className="regular-price">{p.price}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {productDetails !== undefined &&
              productDetails.product_variants.length > 1 ? (
                <div className="text-left product-price">
                  <select
                    onChange={(e) => {
                      onChagneVarint(e);
                    }}
                    className="form-control"
                    aria-label="Default select example"
                  >
                    {productDetails.product_variants.map((p, i) => (
                      <option value={p.id}>
                        {p.unit} - {p.price}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="select_height"> </div>
              )}

              {selectedVariant !== undefined && (
                <h3 className="text-left product-price">
                  {formatNumber(selectedVariant.price)}
                </h3>
              )} */}

                <div className="row">
                  <div className="col-md-5">
                    {
                      <div className="my-3">
                        {!_.isEmpty(selectedVariant) &&
                          isInCart(selectedVariant) && (
                            <div className="button_group">
                              <ButtonGroup
                                size="small"
                                variant="contained"
                                color="primary"
                                aria-label="outlined primary button group"
                              >
                                <Button
                                  classes={{ root: classes.root }}
                                  onClick={() =>
                                    decreaseProduct(selectedVariant)
                                  }
                                >
                                  {" "}
                                  <Icon color="white">remove</Icon>
                                </Button>
                                <Button classes={{ root: classes.root }}>
                                  {" "}
                                  <span className="quantity_areaa">
                                    {checkQuantity(selectedVariant)} in bag
                                  </span>
                                </Button>
                                <Button
                                  classes={{ root: classes.root }}
                                  onClick={() =>
                                    increase(productDetails, selectedVariant)
                                  }
                                >
                                  {" "}
                                  <Icon color="white">add</Icon>
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}

                        {!_.isEmpty(selectedVariant) &&
                          !isInCart(selectedVariant) && (
                            <button
                              onClick={() =>
                                addProduct(productDetails, selectedVariant)
                              }
                              className="btn btn-primary btn-block"
                            >
                              Add to cart
                            </button>
                          )}
                      </div>
                    }
                    {!_.isEmpty(productDetails) &&
                    productDetails.product_variants.length > 1 ? (
                      <div className="text-left product-price my-2">
                        <select
                          onChange={(e) => {
                            onChagneVarint(e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          {productDetails.product_variants.map((p, i) => (
                            <option value={p.id}>{p.unit} </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="select_height">
                        {!_.isEmpty(productDetails) &&
                          productDetails.product_variants.length === 1 && (
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {productDetails.product_variants[0].unit}{" "}
                            </span>
                          )}{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {!_.isEmpty(productDetails) && (
                      <p>{productDetails.description}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-md-12">
              <div className="">
                {/* <div className={classes.rootTab}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                  >
                    <Tab label="Description" {...a11yProps(0)} />
                    <Tab label="Reviews" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam fringilla augue nec est tristique auctor. Ipsum metus
                      feugiat sem, quis fermentum turpis eros eget velit. Donec
                      ac tempus ante. Fusce ultricies massa massa. Fusce
                      aliquam, purus eget sagittis vulputate, sapien libero
                      hendrerit est, sed commodo augue nisi non neque Cras neque
                      metus, consequat et blandit et, luctus a nunc. Etiam
                      gravida vehicula tellus, in imperdiet ligula euismod eget.
                      Pellentesque habitant morbi tristique senectus et netus et
                      malesuada fames ac turpis egestas
                    </p>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    Reviews
                  </TabPanel>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center pt-62">
                <h2>Related Product</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ProductsGrids
                stop={true}
                products={products}
                path={productPath}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
