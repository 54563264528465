import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProductsGrids from "../../components/ProductGrids";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  accor: {
    backgroundColor: "#62d2a2",
    color: "#fff",
  },
  accorPaper: {
    margin: "16px 10px!important",
  },
}));

export default function Categories() {
  let { catName } = useParams();
  const classes = useStyles();
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  const [products, setProducts] = useState([]);
  const [stop, setStop] = useState(false);
  const [path, setPath] = useState("files/product");
  const [msg, setMsg] = useState("");
  useEffect(() => {
    if (catName !== undefined) {
      console.log("cat name===>", catName);
      // setProducts([]);
      setParams({ ...params, page: 1 });
      setStop(false);
      setMsg("");
      // console.log("productssssssss from use effect", products);
      ppp(catName);
    }
  }, [catName]);
  useEffect(() => {
    getProducts();
  }, [params]);

  const ppp = (catName) => {
    let psa = [];

    // console.log("product from ppp", products);
    if (products.length > 0) {
      console.log("you have to be removed");
      setProducts(psa);
    }
    getProducts([]);
  };
  // console.log("product from render", products);

  const getProducts = (current) => {
    console.log("currrrrrrr", current);
    axios
      .get(`category/${catName}`, { params: params })
      .then((response) => {
        // console.log("response cate", response.data.data.data);
        let curr = response.data.data.current_page;
        let last = response.data.data.last_page;
        // console.log("ccc", "lll", curr, last);
        let pPp = [];
        if (current !== undefined) {
          pPp = [];
        } else {
          pPp = [...products];
        }

        // console.log("current products", pPp);
        let copyArr = [...response.data.data.data];
        // console.log("products from api", copyArr);
        if (copyArr.length === 0) {
          setMsg("No Products Available");
        } else {
          setMsg("");
        }
        const projectListLocalIDS = pPp.map((o) => o.id);
        copyArr.forEach((item) => {
          if (!projectListLocalIDS.includes(item.id)) {
            pPp.push(item);
          }
        });

        if (curr === last) {
          setStop(true);
        }

        setProducts(pPp);
        // setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const triggerScroll = (value) => {
    console.log("value", value);
    setParams({ ...params, page: params.page + 1 });
  };
  return (
    <div>
      <div className="">
        {catName ? (
          <div>
            {/* Search {search} */}
            <section className="popular-category bg-white mt-minus pt-60 pb-60 pb-md-30 pb-sm-30">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title text-center pt-62">
                      <h3> {msg}</h3>
                    </div>
                  </div>
                </div>

                <div className={classes.root}>
                  <div className={classes.demo1}>
                    <ProductsGrids
                      products={products}
                      triggerScroll={triggerScroll}
                      path={path}
                      stop={stop}
                      cat={catName}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <h1 className="text-center">{catName}</h1>
        )}
      </div>
    </div>
  );
}
