import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { UserAPI } from "../../AxiosRequest/UserAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function UserInvoice() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [isDetails, setIsDetails] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [detailList, setDetailList] = useState([]);
  const [path, setPath] = useState("");
  const [photoPath, setPhotoPath] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({});
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });
  const theme = useTheme();
  const myRef = useRef(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    getInvoiceList();
  }, [params]);

  const getInvoiceList = () => {
    UserAPI.get(`invoices`, { params: params })
      .then((response) => {
        setInvoiceList(response.data.data.data);
        setPath(response.data.cover_path);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const executeScroll = () => myRef.current.scrollIntoView();

  const showDetails = (id) => {
    if (!isMobile) {
      window.scroll(0, 0);
    }

    setSelected({
      id: id,
    });

    UserAPI.get(`invoices/${id}`)
      .then((response) => {
        setInvoiceDetails(response.data.data);
        setDetailList(response.data.data.invoice_details);
        console.log("response.data.data --- ", response.data.product_path);
        setPhotoPath(response.data.product_path);
        setIsDetails(true);
        if (isMobile) {
          executeScroll();
        }
        // setPath(response.data.cover_path)
        // setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container mt-1">
        <div className="row">
          <div className="col-md-6">
            {invoiceList.map((invoice, index) => (
              <div className="">
                <div key={index} className="card mb-1">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">Status: {invoice.status}</div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        Order #{invoice.id} <br /> Tk . {invoice.bill}
                      </div>
                      <div className="col-6">
                        Date : {moment(invoice.created_at).format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="text-center">
                          <button
                            onClick={() => showDetails(invoice.id)}
                            className="btn btn-sm btn-outline-primary"
                          >
                            Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isMobile && (
                  <div>
                    {selected.id === invoice.id && (
                      <div ref={myRef}>
                        <div>
                          {isDetails && invoiceDetails !== null && (
                            <div className="">
                              <div className="card mb-1">
                                <div className="card-header">
                                  <h5 className="text-center">Order Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-12 mb-3">
                                      <div>
                                        <strong>Order no: </strong>
                                        {invoiceDetails.id}
                                      </div>
                                      <div>
                                        <strong>Delivery address: </strong>
                                        {invoiceDetails.address}
                                        {invoiceDetails.police_station != null
                                          ? ", " +
                                            invoiceDetails.police_station.name +
                                            ", " +
                                            invoiceDetails.police_station
                                              .district
                                          : ""}
                                      </div>
                                      <div>
                                        <strong>Order price: </strong>
                                        {invoiceDetails.price}
                                      </div>
                                      <div>
                                        <strong>Delivery charge: </strong>
                                        {invoiceDetails.delivery_charge}
                                      </div>
                                      <div>
                                        <strong>Order bill: </strong>
                                        {invoiceDetails.bill}
                                      </div>
                                      <div>
                                        <strong>Order date: </strong>
                                        {moment(
                                          invoiceDetails.updated_at
                                        ).format("DD-MM-YYYY")}
                                      </div>
                                      <div>
                                        <strong>Note: </strong>
                                        {invoiceDetails.note}
                                      </div>
                                    </div>
                                    {detailList.map((detail, index) => (
                                      <div
                                        className="col-12 my-1"
                                        style={{
                                          borderBottom: "1px solid #ddd",
                                        }}
                                      >
                                        {detail.product_variant != null &&
                                          detail.product_variant.product !=
                                            null && (
                                            <div>
                                              <p>
                                                <img
                                                  src={`${process.env.REACT_APP_PUBLIC_URL}/files/product/${detail.product_variant.product_id}/${detail.product_variant_id}/${detail.product_variant.product_photos[0].photo}`}
                                                  className="mx-1"
                                                  style={{ width: "40px" }}
                                                />
                                                <strong>
                                                  {" "}
                                                  {detail.product_variant !=
                                                    null &&
                                                  detail.product_variant
                                                    .product != null
                                                    ? detail.product_variant
                                                        .product.name
                                                    : ""}{" "}
                                                  {detail.quantity} pcs{" "}
                                                </strong>
                                                <br />
                                                <strong>Price: </strong>
                                                {detail.product_variant != null
                                                  ? detail.product_variant.price
                                                  : ""}{" "}
                                                * {detail.quantity} ={" "}
                                                {detail.quantity * detail.price}{" "}
                                                <br />
                                                <strong>
                                                  Bill after discount:{" "}
                                                </strong>
                                                {detail.bill}
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="col-md-6">
            {!isMobile && (
              <div>
                {isDetails && invoiceDetails !== null && (
                  <div className="">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h5 className="text-center">Order Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 mb-3">
                            <div>
                              <strong>Order no: </strong>
                              {invoiceDetails.id}
                            </div>
                            <div>
                              <strong>Delivery address: </strong>
                              {invoiceDetails.address}
                              {invoiceDetails.police_station != null
                                ? ", " +
                                  invoiceDetails.police_station.name +
                                  ", " +
                                  invoiceDetails.police_station.district
                                : ""}
                            </div>
                            <div>
                              <strong>Order price: </strong>
                              {invoiceDetails.price}
                            </div>
                            <div>
                              <strong>Delivery charge: </strong>
                              {invoiceDetails.delivery_charge}
                            </div>
                            <div>
                              <strong>Order bill: </strong>
                              {invoiceDetails.bill}
                            </div>
                            <div>
                              <strong>Order date: </strong>
                              {moment(invoiceDetails.updated_at).format(
                                "DD-MM-YYYY"
                              )}
                            </div>
                            <div>
                              <strong>Note: </strong>
                              {invoiceDetails.note}
                            </div>
                          </div>
                          {detailList.map((detail, index) => (
                            <div
                              className="col-12 my-1"
                              style={{ borderBottom: "1px solid #ddd" }}
                            >
                              {detail.product_variant != null &&
                                detail.product_variant.product != null && (
                                  <div>
                                    <p>
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/product/${detail.product_variant.product_id}/${detail.product_variant_id}/${detail.product_variant.product_photos[0].photo}`}
                                        className="mx-1"
                                        style={{ width: "40px" }}
                                      />
                                      <strong>
                                        {" "}
                                        {detail.product_variant != null &&
                                        detail.product_variant.product != null
                                          ? detail.product_variant.product.name
                                          : ""}{" "}
                                        {detail.quantity} pcs{" "}
                                      </strong>
                                      <br />
                                      <strong>Price: </strong>
                                      {detail.product_variant != null
                                        ? detail.product_variant.price
                                        : ""}{" "}
                                      * {detail.quantity} ={" "}
                                      {detail.quantity * detail.price} <br />
                                      <strong>Bill after discount: </strong>
                                      {detail.bill}
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInvoice;
