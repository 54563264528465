import React, { createContext, useReducer } from "react";
import { InvoiceReducer, sumItems } from "./InvoiceReducer";

export const InvoiceContext = createContext();

const storage = localStorage.getItem("ImaginaryAdminInvoice")
  ? JSON.parse(localStorage.getItem("ImaginaryAdminInvoice"))
  : [];
// const storage = [];
const initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
};

const InvoiceContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoiceReducer, initialState);

  const increase = (payload, variant) => {
    dispatch({ type: "INCREASE", payload, variant });
  };

  const decrease = (payload, variant) => {
    dispatch({ type: "DECREASE", payload, variant });
  };

  const addProduct = (payload, variant, quantity) => {
    dispatch({ type: "ADD_ITEM", payload, variant, quantity });
  };

  const removeProduct = (payload, variant) => {
    dispatch({ type: "REMOVE_ITEM", payload, variant });
  };

  const clearCart = () => {
    console.log("cart contecxt clear");
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    // console.log("CHECKOUT", state);
    dispatch({ type: "CHECKOUT" });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    ...state,
  };

  return (
    <InvoiceContext.Provider value={contextValues}>
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceContextProvider;
