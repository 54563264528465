import React from "react";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import adminAuth from "../../routingRules/adminAuth";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import CreateIcon from "@material-ui/icons/Create";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AvTimerIcon from "@material-ui/icons/AvTimer";
// import BlockIcon from "@material-ui/icons/Block";
// import AssignmentIcon from "@material-ui/icons/Assignment";
// import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PersonIcon from "@material-ui/icons/Person";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import OpacityIcon from "@material-ui/icons/Opacity";
import SchoolIcon from "@material-ui/icons/School";
import SettingsIcon from "@material-ui/icons/Settings";

import Icon from "@material-ui/core/Icon";
import AdminProfile from "./profile/index";
import ResetPassword from "./reset-password/index";
import ProductList from "./products/ProductList";
import ProductForm from "./products/ProductForm";
import ProductDetails from "./products/ProductDetails";
import VariantList from "./variants/VariantList";
import VariantDetail from "./variants/VariantDetails";
import CategoryList from "./categories/CategoryList";
import CategoryForm from "./categories/CategoryForm";
import SubcategoryList from "./subcategories/SubcategoryList";
import SubcategoryForm from "./subcategories/SubcategoryForm";
import CouponList from "./coupons/CouponsList";
import CouponsForm from "./coupons/CouponsForm";
import InvoiceList from "./invoices/InvoiceList";
import InvoiceDetails from "./invoices/InvoiceDetails";
import PurchaseList from "./purchases/PurchaseList";
import PurchaseForm from "./purchases/PurchaseForm";
import InvoiceForm from "./invoices/InvoiceForm";
import InvoiceContextProvider from "../../contexts/InvoiceContext";
import ReportList from "./reports/ReportList";
import CustomerList from "./customers/CustomerList";
import CustomerForm from "./customers/CustomerForm";
import CustomerDetails from "./customers/CustomerDetails";
import AdminList from "./admins/AdminList";
import AdminForm from "./admins/AdminForm";
import AdminDetails from "./admins/AdminDetails";
import AdminPassword from "./admins/AdminPassword";
import DeliveryChargeList from "./delivery-charges/DeliveryChargeList";
import DeliveryChargeForm from "./delivery-charges/DeliveryChargeForm";
import ChargeDiscountList from "./delivery-charges/ChargeDiscountList";
import ChargeDiscountForm from "./delivery-charges/ChargeDiscountForm";
import BlogForm from "./blogs/BlogForm";
import BlogList from "./blogs/BlogList";
import BlogDetails from "./blogs/BlogDetails";
import BannerList from "./banners/BannerList";
import BannerForm from "./banners/BannerForm";
import TextList from "./texts/TextList";
import TextForm from "./texts/TextForm";
import Settings from "./settings/index";
import OpenCloseForm from "./settings/OpenCloseForm";
import { useEffect } from "react";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  nested: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "4rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function AdminDashboard() {
  let history = useHistory();
  let match = useRouteMatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openTransaction, setOpenTransaction] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [admin, setAdmin] = React.useState(true);
  const [openPreference, setOpenPreference] = React.useState(false);

  const handleProductExpand = () => {
    setOpenProduct(!openProduct);
  };
  const handleUserExpand = () => {
    setOpenUser(!openUser);
  };
  const handlePreferenceExpand = () => {
    setOpenPreference(!openPreference);
  };
  const handleTransactionExpand = () => {
    setOpenTransaction(!openTransaction);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const profile = () => {
    history.push("/admin/profile");
  };
  const logOut = () => {
    adminAuth.logout(() => {
      history.push("/");
    });
  };
  const resetPassword = () => {
    history.push("/admin/reset-password");
  };

  useEffect(() => {
    myFunction();
    return () => {
      setAdmin(true); // This worked for me
    };
  }, []);

  const myFunction = () => {
    const isSub = JSON.parse(localStorage.getItem("isSub"));
    if (isSub !== 1) {
      setAdmin(false);
    }
    // console.log("is sub from dash", isSub);
  };

  return (
    <div className={""}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>

          <Tooltip title="Change Password">
            <IconButton onClick={resetPassword} color="inherit">
              <VpnKeyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip onClick={profile} title="Profile">
            <IconButton color="inherit">
              <PersonIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton onClick={logOut} color="inherit" aria-label="logout">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Divider />
        <List>
          <ListItem button component={Link} to={`${match.url}`}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem button component={Link} to={`${match.url}/invoices`}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Invoices" />
          </ListItem>

          {admin && (
            <React.Fragment>
              <ListItem button onClick={handleProductExpand}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Product" />
                {openProduct ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openProduct} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/products`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/variants`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>

                    <ListItemText primary="Variants" />
                  </ListItem>

                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/categories`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <LocalHospitalIcon />
                    </ListItemIcon>
                    <ListItemText primary="Categories" />
                  </ListItem>

                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/subcategories`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <LocalHospitalIcon />
                    </ListItemIcon>
                    <ListItemText primary="Subcategories" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button onClick={handleTransactionExpand}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
                {openTransaction ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openTransaction} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/purchases`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Purchase" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/reports`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button onClick={handleUserExpand}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
                {openUser ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openUser} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/customers`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Customers" />
                  </ListItem>

                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/admins`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <LayersIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admins" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button component={Link} to={`${match.url}/blogs/list`}>
                <ListItemIcon>
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Blogs" />
              </ListItem>

              <ListItem button onClick={handlePreferenceExpand}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Preferences" />
                {openPreference ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openPreference} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/delivery-charges`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Delivery charge" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/coupons`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <OpacityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Coupons" />
                  </ListItem>

                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/banners`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Banners" />
                  </ListItem>

                  <ListItem
                    button
                    component={Link}
                    to={`${match.url}/texts`}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Texts" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button component={Link} to={`${match.url}/settings`}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </React.Fragment>
          )}
        </List>

        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <InvoiceContextProvider>
          <Switch>
            {/* {admin && (
            
            )} */}
            {admin ? (
              <React.Fragment>
                <Route exact path={`${match.path}/products`}>
                  <ProductList />
                </Route>
                <Route exact path={`${match.path}/product/view/:id`}>
                  <ProductDetails />
                </Route>
                <Route exact path={`${match.path}/add-product`}>
                  <ProductForm />
                </Route>
                <Route exact path={`${match.path}/edit-product/:id`}>
                  <ProductForm />
                </Route>
                <Route exact path={`${match.path}/blogs/add-blog`}>
                  <BlogForm />
                </Route>
                <Route exact path={`${match.path}/edit-blog/:id`}>
                  <BlogForm />
                </Route>
                <Route exact path={`${match.path}/blogs/list`}>
                  <BlogList />
                </Route>
                <Route exact path={`${match.path}/variants`}>
                  <VariantList />
                </Route>
                <Route exact path={`${match.path}/variant/view/:id`}>
                  <VariantDetail />
                </Route>
                <Route exact path={`${match.path}/categories`}>
                  <CategoryList />
                </Route>
                <Route exact path={`${match.path}/add-category`}>
                  <CategoryForm />
                </Route>
                <Route exact path={`${match.path}/category/view/:id`}>
                  <CategoryForm />
                </Route>
                <Route exact path={`${match.path}/subcategories`}>
                  <SubcategoryList />
                </Route>
                <Route exact path={`${match.path}/add-subcategory`}>
                  <SubcategoryForm />
                </Route>
                <Route exact path={`${match.path}/subcategory/view/:id`}>
                  <SubcategoryForm />
                </Route>
                <Route exact path={`${match.path}/coupons`}>
                  <CouponList />
                </Route>
                <Route exact path={`${match.path}/add-coupon`}>
                  <CouponsForm />
                </Route>
                <Route exact path={`${match.path}/coupon/view/:id`}>
                  <CouponsForm />
                </Route>

                <Route exact path={`${match.path}/add-invoice`}>
                  <InvoiceForm />
                </Route>

                <Route exact path={`${match.path}/invoices`}>
                  <InvoiceList admin={admin} />
                </Route>
                <Route exact path={`${match.path}/invoice/view/:id`}>
                  <InvoiceDetails admin={admin} />
                </Route>
                <Route exact path={`${match.path}/invoice/edit/:id`}>
                  <InvoiceForm />
                </Route>
                <Route exact path={`${match.path}/customers`}>
                  <CustomerList />
                </Route>
                <Route exact path={`${match.path}/add-customer`}>
                  <CustomerForm />
                </Route>
                <Route exact path={`${match.path}/customer/view/:id`}>
                  <CustomerDetails />
                </Route>
                <Route exact path={`${match.path}/admins`}>
                  <AdminList />
                </Route>
                <Route exact path={`${match.path}/admin/view/:id`}>
                  <AdminDetails />
                </Route>
                <Route exact path={`${match.path}/admin-password/:id`}>
                  <AdminPassword />
                </Route>
                <Route exact path={`${match.path}/add-admin`}>
                  <AdminForm />
                </Route>
                <Route exact path={`${match.path}/purchases`}>
                  <PurchaseList />
                </Route>
                <Route exact path={`${match.path}/add-purchase`}>
                  <PurchaseForm />
                </Route>
                <Route exact path={`${match.path}/purchase/view/:id`}>
                  <PurchaseForm />
                </Route>
                <Route exact path={`${match.path}/reports`}>
                  <ReportList />
                </Route>
                <Route exact path={`${match.path}/profile`}>
                  <AdminProfile />
                </Route>
                <Route exact path={`${match.path}/reset-password`}>
                  <ResetPassword />
                </Route>
                <Route exact path={`${match.path}/delivery-charges`}>
                  <DeliveryChargeList />
                </Route>
                <Route exact path={`${match.path}/delivery-charge/view/:id`}>
                  <DeliveryChargeForm />
                </Route>
                <Route exact path={`${match.path}/delivery-charge-discounts`}>
                  <ChargeDiscountList />
                </Route>
                <Route
                  exact
                  path={`${match.path}/delivery-charge-discount/view/:id`}
                >
                  <ChargeDiscountForm />
                </Route>
                <Route
                  exact
                  path={`${match.path}/delivery-charge-discount/add-new`}
                >
                  <ChargeDiscountForm />
                </Route>
                <Route exact path={`${match.path}/banners`}>
                  <BannerList />
                </Route>
                <Route exact path={`${match.path}/add-banner`}>
                  <BannerForm />
                </Route>
                <Route exact path={`${match.path}/banner/view/:id`}>
                  <BannerForm />
                </Route>

                <Route exact path={`${match.path}/texts`}>
                  <TextList />
                </Route>
                <Route exact path={`${match.path}/text/view/:id`}>
                  <TextForm />
                </Route>
                <Route exact path={`${match.path}/settings`}>
                  <Settings />
                </Route>
                <Route exact path={`${match.path}/setting/view/:id`}>
                  <OpenCloseForm />
                </Route>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route exact path={`${match.path}/invoices`}>
                  <InvoiceList admin={admin} />
                </Route>
                <Route exact path={`${match.path}/invoice/view/:id`}>
                  <InvoiceDetails admin={admin} />
                </Route>
              </React.Fragment>
            )}
          </Switch>
        </InvoiceContextProvider>

        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
}
