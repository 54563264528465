import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";

function Settings() {
  const [setting, setSetting] = useState({});
  const [detail, setDetail] = useState([]);
  const [params, setParams] = useState({
    type: "operation"
  });

  useEffect(() => {
    getSetting();
  }, [params]);

  const getSetting = () => {
    AdminAPI.get(`preferences`, { params: params })
      .then((response) => {
        setSetting(response.data.data.data[0]);
        setDetail(response.data.data.data[1]);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const toggleSetting = (id, value) => {
    let objToSend = {
      value: value
    };
    AdminAPI.put(`preferences/${id}`, objToSend)
      .then((response) => {
        getSetting();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Reason</th>
                    <th scope="col">Status</th>
                    <th scope="col">Banner</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="col">{detail.name}</td>
                    <td scope="col">
                      <select
                      onChange = {(event) => toggleSetting(setting.id, event.target.value)}
                        className="form-control"
                        value={setting.value}
                      >
                        <option value="0">Open</option>
                        <option value="1">Close</option>
                      </select>
                    </td>
                    <td scope="col">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/files/preferences/${detail.value}`}
                        alt={detail.name}
                        style={{height: "50px"}}
                      />
                    </td>
                    <td scope="col">
                      <Link
                          to={`/admin/setting/view/${detail.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
