import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function VariantDetails() {
  const [variantDetail, setVariantDetail] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [variantPhotos, setVariantPhotos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [path, setPath] = useState("");
  let { id } = useParams();
  let history = useHistory();
  const [openModal, setOpenModal] = React.useState(false);
  
  useEffect(() => {
    getVariantDetails();
  }, []);

  const getVariantDetails = () => {
    AdminAPI
      .get(`product-variants/${id}`)
      .then((response) => {
        setVariantDetail(response.data.data);
        setProductDetail(response.data.data.product)
        console.log(response.data.data.product_photos)
        setVariantPhotos(response.data.data.product_photos);
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const deleteVariant = () => {
    AdminAPI.post(`product-variants/${id}`)
      .then((response) => {
        setOpenDialog(false);
        history.push("/admin/variants");
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  const onChangeStatus = (value) => {
    AdminAPI
      .post(`product-variants/${id}/update-status`, {
        status: value
      })
      .then((response) => {
        getVariantDetails();
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <button
              onClick={() => setOpenDialog(true)}
              className="btn btn-md btn-danger mr-1"
              type="button"
            >
              Delete
            </button>
            <Link
              to={`/admin/edit-product/${productDetail.id}`}
              type="button"
              className="btn btn-md btn-success"
            >
              Edit Product
            </Link>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div><strong>Name: </strong> {productDetail.name}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div><strong>Description: </strong>{productDetail.description}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div><strong>Category: </strong> {productDetail.subcategory && productDetail.subcategory.category != null ? productDetail.subcategory.category.name : ""}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div><strong>Subcategory: </strong> {productDetail.subcategory != null ? productDetail.subcategory.name : ""}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div><strong>Unit: </strong>{variantDetail.unit}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div><strong>Price: </strong>{variantDetail.price}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-12">
            <div>
              <strong>Status: </strong>
              <select className="form-control"
                value={variantDetail.status}
                onChange={(e) => onChangeStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Out of stock">Out of stock</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <strong>Photos: </strong>
          </div>
          {variantPhotos != null && (
            <div className="row">
              {variantPhotos.map((photo, i) => (
                <div className="img-responsive">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${productDetail.id}/${variantDetail.id}/${photo.photo}`}
                    alt={variantDetail.unit}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this variant?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => deleteVariant()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VariantDetails;
