import React, { useLayoutEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Store from "../pages/store";
// import Store from "../pages/store";
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import Cart from "../pages/cart";
import HomePage from "../pages/Home/HomePage";
import AdminDashboard from "../pages/Admin/AdminDashboard";
import HomeLayout from "../layouts/HomeLayout";
import MainPage from "../pages/Home/MainPage";
import ProductDetails from "../pages/Home/ProductDetails";
import Search from "../pages/Home/Search";
import Shop from "../pages/Home/Shop";
import Checkout from "../pages/Home/Checkout";
import { AdminProtectedRoute } from "../routingRules/protectedAdminRoute";
import { UserProtectedRoute } from "../routingRules/protectedUserRoute";
import AdminLogin from "../pages/Admin/AdminLogin";
import CartContextProvider from "../contexts/CartContext";
import InvoiceContextProvider from "../contexts/InvoiceContext";
import UserDashboard from "../pages/User/UserDashboard";
import UserProfile from "../pages/User/profile";
import Categories from "../pages/Home/Categories";
import UserInvoice from "../pages/User/UserInvoice";
import BlogDetails from "../pages/Home/BlogDetails";
import CartDetails from "../pages/Home/CartDetails";
import PrivacyPolice from "../pages/Home/PrivacyPolice";
import ForgotPasswordAdmin from "../pages/Admin/forgot-password";

const Routes = () => {
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Switch>
      {/* <Route path="/about" component={About} /> */}
      <Route exact path="/products" component={Store} />
      <Route path="/cart" component={Cart} />
      {/* <Route path="/" component={HomePage} /> */}
      {/* <Route exact path="/">
          <HomePage />
        </Route> */}

      <Route path="/admin/login" component={AdminLogin} />

      <Route path="/admin/forgot-password" component={ForgotPasswordAdmin} />
      {/* <InvoiceContextProvider> */}

      <AdminProtectedRoute path="/admin">
        <AdminDashboard />
      </AdminProtectedRoute>
      {/* </InvoiceContextProvider> */}

      <CartContextProvider>
        <Route>
          <HomeLayout>
            {/* <ScrollToTop /> */}
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Route
                path="/product-details/:productId"
                component={ProductDetails}
              />
              <Route path="/shop" component={Shop} />
              <Route path="/blog-details/:id" component={BlogDetails} />
              <Route path="/search/:search" component={Search} />
              <Route path="/search" component={Search} />
              <Route path="/cart-details" component={CartDetails} />
              <Route path="/privacy-policy" component={PrivacyPolice} />
              <Route path="/checkout" component={Checkout} />
              {/* <UserDashboard> */}{" "}
              <UserProtectedRoute path="/customer/profile">
                <UserProfile />
              </UserProtectedRoute>{" "}
              <UserProtectedRoute path="/customer/invoice">
                <UserInvoice />
              </UserProtectedRoute>{" "}
              {/* </UserDashboard> */}
              <Route path="/:catName" component={Categories} />
              <Route path="/about" component={About} />
              <Route path="/about" component={About} />
            </Switch>
          </HomeLayout>
        </Route>
      </CartContextProvider>

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
