import React from "react";
import { Link } from "react-router-dom";

// import Layout from '../../components/Layout';

import ProductsGrid from "./ProductsGrid";

const Store = () => {
  return (
    // <Layout title="Store" description="This is the Store page" >
    //  </Layout>

    <div>
      <div className="text-center mt-5">
        <Link to="/cart">Cart</Link>
        <h1>Store</h1>
        <p>This is the Store Page.</p>
      </div>
      <ProductsGrid />
    </div>
  );
};

export default Store;
