import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function DeliveryChargeForm() {
  const classes = useStyles();
  let { id } = useParams();
  let history = useHistory();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState("");
  const [charge, setCharge] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    AdminAPI.get(`preferences/${id}`)
      .then((response) => {
        if (response.data.data.type !== "Delivery Charge") {
          history.push(`/admin/delivery-charges`);
        }
        setDeliveryCharge(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setModalMessage("Are you sure to update this charge?");
  }, []);
  const validationSchema = Yup.object({
    value: Yup.string().required("This field is required"),
  });
  const initialValues = {
    value: deliveryCharge.value || "",
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log(values);
    setOpenDialog(true);
    let obj = {
      value: values.value,
    };
    setCharge(obj);
  };

  const updateCharge = () => {
    AdminAPI.put(`preferences/${id}`, charge)
      .then((response) => {
        setModalMessage("Charge updated");
        setOpenDialog(false);
        history.push(`/admin/delivery-charges`);
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        setServerErrors(error.response.data.errors);
      });
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row">
                                  <label className="col-md-3">
                                    Delivery Charge
                                  </label>
                                  <label className="col-md-9">
                                    {deliveryCharge.name}
                                  </label>
                                </div>
                                <div className="form-group row">
                                  <label className="col-md-3">Charge</label>
                                  <div className="col-md-9">
                                    <Field
                                      name={`value`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`value`}
                                      component="div"
                                      className="error-message"
                                    />
                                    {!_.isEmpty(serverErrors) &&
                                      serverErrors.value !== undefined && (
                                        <span className="error-msg">
                                          {" "}
                                          {serverErrors.value}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="text-right">
                                      <button
                                        onSubmit={onSubmit}
                                        className="btn btn-md btn-primary"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update charge"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => updateCharge()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
