import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";

function DeliveryChargeList() {
  const [deliveryChargeList, setDeliveryChargeList] = useState([]);

  useEffect(() => {
    getDeliveryChargeList();
  }, []);

  const getDeliveryChargeList = () => {
    AdminAPI
      .get(`preferences?type=Delivery Charge`)
      .then((response) => {
        setDeliveryChargeList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
            <div className="col-12">
                <Link
                to="/admin/delivery-charge-discounts"
                type="button"
                className="btn btn-md btn-success float-right mb-1"
                >
                    Charge discounts
                </Link>
            </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {deliveryChargeList.map((charge, i) => (
                    <tr key={i}>
                      <td> {charge.name} </td>
                      <td> {charge.value} </td>
                      <td>
                        <Link
                          to={`/admin/delivery-charge/view/${charge.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryChargeList;
