import React, { useState, useEffect } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import _ from "lodash";
import * as Yup from "yup";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import "../css/admin-login.css";
export default function ForgotPasswordAdmin({}) {
  const [loading, setLoading] = useState(false);
  const [successUpMessage, setSuccessMessage] = useState("");
  const [success, setSuccess] = useState(false);

  // Backend Error Messages

  const [showOTOPField, setShowOTP] = React.useState(false);

  const [serverError, setServerError] = useState({});
  const [backendError, setBackendError] = useState({});

  var validationSchemaPassword = Yup.object().shape({
    mobile_number: Yup.string().required(),
  });

  const validationSchemaPasswordOTP = Yup.object({
    mobile_number: Yup.string().required("Name field is required"),
    otp: Yup.string().required("Please enter your otp"),

    password: Yup.string()
      .min(6, "Password must contain at least 6 characters")
      .required("Enter your password"),
    confirm_password: Yup.string()
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match"),
  });
  const initialValues = {
    mobile_number: "",
    otp: "",
    password: "",
    confirm_password: "",
  };

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    setLoading(true);
    if (showOTOPField === false) {
      AdminAPI.post(`auth/forgot-password`, values)
        .then((response) => {
          console.log("response=====>", response);
          setLoading(false);
          setShowOTP(true);
          setServerError({
            mobile_number: "",
          });
        })
        .catch((error) => {
          setServerError({
            mobile_number: "Please enter a valid number",
          });

          setLoading(false);
        });
    } else {
      AdminAPI.post(`auth/set-password`, values)
        .then((response) => {
          console.log("response=====>", response);
          setLoading(false);
          setSuccess(true);
          setSuccessMessage(response.data.message);
          setLoading(false);
          setServerError({});
        })
        .catch((error) => {
          console.log("error from login otop", error.response);
          console.log("error from login otop", error.response.data.errors);
          setServerError({
            ...serverError,
            other: error.response.data.message,
          });
          if (error.response.data.errors) {
            setBackendError(error.response.data.errors);
          }

          setLoading(false);
        });
    }
  };
  return (
    <div>
      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="login-form-section">
                    <h1 className="text-center my-3">Forgot Password</h1>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={
                        showOTOPField
                          ? validationSchemaPasswordOTP
                          : validationSchemaPassword
                      }
                      onSubmit={onSubmit}
                    >
                      {({ setFieldValue, isValid, dirty }) => (
                        <Form>
                          <div className="form-group row ">
                            <label className="col-sm-3 offset-sm-1 col-form-label">
                              Mobile Number
                            </label>
                            <div className="col-sm-7">
                              <Field
                                name="mobile_number"
                                type="text"
                                className="form-control"
                                placeholder="Phone/Mobile Number"
                                readOnly={showOTOPField}
                              />
                              <ErrorMessage
                                name="mobile_number"
                                component="div"
                                className="error-message"
                              />

                              {!_.isEmpty(serverError) &&
                                serverError.mobile_number !== undefined && (
                                  <span className="error-message">
                                    {" "}
                                    {serverError.mobile_number}
                                  </span>
                                )}
                            </div>
                          </div>
                          {showOTOPField && (
                            <div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1 col-form-label">
                                  Otp
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    name="otp"
                                    type="text"
                                    className="form-control"
                                    placeholder="OTP"
                                  />
                                  <ErrorMessage
                                    name="otp"
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                              </div>

                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1 col-form-label ">
                                  Password
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    name="password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(backendError) &&
                                    backendError.password !== undefined && (
                                      <span className="error-message">
                                        {" "}
                                        {backendError.password}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="col-sm-3 offset-sm-1 col-form-label ">
                                  Confirm Password
                                </label>
                                <div className="col-sm-7">
                                  <Field
                                    name="confirm_password"
                                    type="password"
                                    className="form-control "
                                    placeholder="password"
                                  />
                                  <ErrorMessage
                                    name="confirm_password"
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(backendError) &&
                                    backendError.confirm_password !==
                                      undefined && (
                                      <span className="error-message">
                                        {" "}
                                        {backendError.confirm_password}
                                      </span>
                                    )}
                                  {!_.isEmpty(serverError) &&
                                    serverError.other !== undefined && (
                                      <span className="error-message">
                                        {" "}
                                        {serverError.other}
                                      </span>
                                    )}
                                </div>
                              </div>

                              <div className="form-group row ">
                                <label className="col-sm-11 offset-sm-1">
                                  {successUpMessage}
                                  {success && (
                                    <Link
                                      to="/admin/login"
                                      type="button"
                                      className="btn btn-lg btn-primary"
                                    >
                                      Login
                                    </Link>
                                  )}
                                </label>
                              </div>
                            </div>
                          )}

                          <div className="form-group row ">
                            <label className="col-sm-5 offset-sm-1">
                              <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-lg btn-success"
                              >
                                {loading && (
                                  <i className="fa fa-refresh fa-spin"></i>
                                )}
                                {loading && <span>Submitting</span>}
                                {!loading && <span> Submit</span>}
                              </button>
                            </label>
                            <div className="col-sm-7"></div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
}
