import React, { useContext, useState, useEffect } from "react";

import { InvoiceContext } from "../../../contexts/InvoiceContext";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../helpers/utils";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function InvoiceDetails({ admin }) {
  const [invoice, setInvoice] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [productId, setProductId] = useState(Number);
  const { clearCart } = useContext(InvoiceContext);

  let { id } = useParams();
  let history = useHistory();

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    clearCart();
    getInvoiceDetails();
  }, []);

  const getInvoiceDetails = () => {
    AdminAPI.get(`invoices/${id}`)
      .then((response) => {
        setInvoice(response.data.data);
        console.log(response.data.data.invoice_details);
        setInvoiceItems(response.data.data.invoice_details);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onChangeInvoiceStatus = (e) => {
    setStatus(e.target.value);
    setOpenInvoiceDialog(true);
  };
  const updateInvoice = () => {
    AdminAPI.post(`invoices/${id}/update-status`, {
      status: status,
    })
      .then((response) => {
        setOpenInvoiceDialog(false);
        getInvoiceDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChangeStatus = (detailsId, e) => {
    setDetailId(detailsId);
    setStatus(e.target.value);
    setOpenDetailDialog(true);
  };

  const updateDetail = () => {
    AdminAPI.post(`invoice-details/${detailId}/update-status`, {
      status: status,
    })
      .then((response) => {
        getInvoiceDetails();
        setOpenDetailDialog(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteInvoice = () => {
    AdminAPI.post(`invoices/${id}`)
      .then((response) => {
        setOpenDialog(false);
        history.push("/admin/invoices");
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  const pdf = (type) => {
    AdminAPI.get(`invoices/${id}/pdf`, {
      params: params,
      responseType: "blob",
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        if (type == "Download") {
          var a = document.createElement("a");
          a.href = fileURL;
          console.log(fileURL);
          a.download = "Imaginary invoice_" + id + ".pdf";
          a.click();
          window.URL.revokeObjectURL(fileURL);
        } else {
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row mb-2">
          {admin && (
            <div className="col-md-12 text-right">
              <button
                onClick={() => setOpenDialog(true)}
                className="btn btn-md btn-danger mr-1"
                type="button"
              >
                Delete
              </button>
              <Link
                to={`/admin/invoice/edit/${invoice.id}`}
                type="button"
                className="btn btn-md btn-success"
              >
                Edit Invoice
              </Link>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group row">
              <div className="col-sm-4">
                Customer Name: <strong>{invoice.customer_name}</strong>
              </div>
              <div className="col-sm-4">
                Contact Number:{" "}
                <strong>
                  {invoice.customer != null
                    ? invoice.customer.mobile_number
                    : "N/A"}
                </strong>
              </div>
              <div className="col-sm-4">
                Order Date:{" "}
                <strong>
                  {moment(invoice.updated_at).format("DD-MM-YYYY hh:mm:ss A")}
                </strong>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                Delivery address:{" "}
                <strong>
                  {invoice.address},{" "}
                  {invoice.police_station != null ? (
                    <span>
                      {invoice.police_station.name},{" "}
                      {invoice.police_station.district}
                    </span>
                  ) : (
                    ""
                  )}{" "}
                  (
                  {invoice.inside_dhaka == 1 ? "Inside dhaka" : "Outside dhaka"}
                  )
                </strong>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-3">
                Price: <strong>{formatNumber(invoice.price)}Tk</strong>
              </div>
              <div className="col-sm-3">
                Delivery charge: <strong>{invoice.delivery_charge}Tk</strong>
              </div>
              <div className="col-sm-3">
                Discount: <strong>{invoice.discount}Tk</strong>
              </div>
              <div className="col-sm-3">
                Coupon:{" "}
                {invoice.coupon != null ? (
                  <strong>
                    {invoice.coupon.code}({invoice.coupon.discount}
                    {invoice.coupon.discount_type == "%" ? "%" : "Tk"})
                  </strong>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                Note: <strong>{invoice.note}</strong>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <h3>Payable bill: {formatNumber(invoice.bill)} Tk</h3>
                {invoice.referred_by == "Admin" && invoice.admin !== null && (
                  <div>Created/updated by: {invoice.admin.name}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-1"> Status: </div>
              <div className="col-sm-4">
                {" "}
                <select
                  disabled={!admin}
                  value={invoice.status}
                  onChange={(e) => {
                    onChangeInvoiceStatus(e);
                  }}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="Pending">Pending</option>
                  <option value="Processing">Processing</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Returned">Returned</option>
                </select>
              </div>
              <div className="col-sm-7 text-right">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    pdf("Download");
                  }}
                  className="btn btn-sm btn-success float-right mx-2"
                >
                  Download
                </button>
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    pdf("Print");
                  }}
                  className="btn btn-sm btn-success float-right mx-2"
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceItems !== undefined &&
                    invoiceItems.length > 0 &&
                    invoiceItems.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item.product_variant.product.name} (
                          {item.product_variant.unit})
                        </td>
                        <td>{formatNumber(item.price)}</td>
                        <td>{formatNumber(item.quantity)}</td>
                        <td>{formatNumber(item.discount)}%</td>
                        <td>{formatNumber(item.bill)}</td>
                        <td>
                          <select
                            disabled={!admin}
                            value={item.status}
                            onChange={(e) => {
                              onChangeStatus(item.id, e);
                            }}
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="Pending">Pending</option>
                            <option value="Processing">Processing</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Returned">Returned</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openInvoiceDialog}
        onClose={() => setOpenInvoiceDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to update status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenInvoiceDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => updateInvoice()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to update status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDetailDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => updateDetail()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this invoice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => deleteInvoice()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InvoiceDetails;
