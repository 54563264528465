import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function VariantList() {
  const [variantList, setVariantList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getVariantList();
  }, [params]);

  const getVariantList = () => {
    AdminAPI.get(`product-variants`, { params: params })
      .then((response) => {
        setVariantList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const onChangeStatus = (id, e) => {
    AdminAPI.post(`product-variants/${id}/update-status`, {
      status: e.target.value,
    })
      .then((response) => {
        getVariantList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event, value) => {
    console.log(event, value);
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Out of stock">Out of stock</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Sold</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {variantList.map((variant, i) => (
                    <tr key={i}>
                      <td>{variant.product.name}</td>
                      <td>{variant.product.subcategory.name}</td>
                      <td>{variant.unit}</td>
                      <td>{variant.price}</td>
                      <td>{variant.discount}%</td>
                      <td>{variant.sold}</td>
                      <td>
                        <select
                          value={variant.status}
                          onChange={(e) => {
                            onChangeStatus(variant.id, e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Out of stock">Out of stock</option>
                        </select>
                      </td>

                      <td>
                        <Link
                          to={`/admin/variant/view/${variant.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default VariantList;
