// import React, { useContext } from "react";
import ProductItems from "./ProductItems";
import React, { useState, useEffect, useContext } from "react";
import "./css/component.css";

import Slider from "react-slick";
import axios from "axios";
import Zoom from "react-reveal/Zoom";

const ProductSlider = ({ products, path }) => {
  const [count, setCount] = useState(1);
  const [countM, setCountM] = useState(1);
  const [countS, setCountS] = useState(1);
  const [countXS, setCountXS] = useState(1);

  useEffect(() => {
    let data = [...products];

    let dataC = data.length;
    if (dataC === 1) {
      setCount(1);
      setCountM(1);
      setCountS(1);
      setCountXS(1);
    } else if (dataC === 2) {
      setCount(2);
      setCountM(2);
      setCountS(2);
      setCountXS(2);
    } else if (dataC === 3) {
      setCount(3);
      setCountM(3);
      setCountS(2);
      setCountXS(2);
    } else if (dataC >= 4) {
      setCount(4);
      setCountM(3);
      setCountS(2);
      setCountXS(2);
    }
  }, [products]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: count,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: count,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: countM,
          slidesToScroll: countM,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: countS,
          slidesToScroll: countS,
          initialSlide: countS,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: countXS,
          slidesToScroll: countXS,
        },
      },
    ],
  };

  return (
    <div className={"styles.p__container"}>
      <div className={"product_grid"}></div>

      <Slider {...settings}>
        {products.map((product) => (
          <div>
            {" "}
            <ProductItems key={product.id} product={product} path={path} />{" "}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
