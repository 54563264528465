import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import moment from "moment";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function AdminList() {
  const [adminList, setAdminList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getAdminList();
  }, [params]);

  const getAdminList = () => {
    AdminAPI
      .get(`admins`, {params: params})
      .then((response) => {
        setAdminList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onChagneStatus = (id, e) => {
    AdminAPI.post(`admins/${id}/update-status`, {
      status: e.target.value,
    })
      .then((response) => {
        getAdminList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/add-admin"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Admin
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input className="form-control" placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search")
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {adminList.map((admin, i) => (
                    <tr key={i}>
                      <td> {admin.name} </td>
                      <td> {admin.email} </td>
                      <td> {admin.phone} </td>
                      <td>
                        {admin.id !== 1 ? (
                          <select
                            value={admin.status}
                            onChange={(e) => {
                              onChagneStatus(admin.id, e);
                            }}
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        ) : (
                          <span>{admin.status}</span>
                        )}
                        
                      </td>
                      <td>
                        {admin.id !== 1 &&
                          <Link
                            to={`/admin/admin/view/${admin.id}`}
                            type="button"
                            className="btn btn-sm btn-primary mb-1"
                          >
                            View
                          </Link>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminList;
