import axios from "axios";
import history from "../history";

const AdminAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/admin`,
});

AdminAPI.interceptors.request.use(
  (config) => {
    document.body.classList.add("loading-indicator");
    if (!config.headers.Authorization) {
      const adminToken = JSON.parse(localStorage.getItem("ImaginaryAdminToken"));

      if (adminToken) {
        config.headers.Authorization = `Bearer ${adminToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
AdminAPI.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  (error) => {
    document.body.classList.remove("loading-indicator");
    const path = document.location.pathname;
    if (error.response.status === 401 && path !== "/admin/login") {
      localStorage.removeItem("ImaginaryAdminToken");

      history.push("/admin/login");
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { AdminAPI };
