import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../css/admin.css";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function ChargeDiscountForm() {
  let history = useHistory();
  let { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [discount, setDiscount] = useState("");
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    if (id !== undefined) {
      getDiscountDetails();
    }
  }, []);

  const getDiscountDetails = () => {
    AdminAPI.get(`delivery-charge-discounts/${id}`)
      .then((response) => {
        console.log(response);
        setDiscount(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    bill: Yup.string().required("This field is required"),
    discount: Yup.string().required("This field is required"),
  });
  const initialValues = {
    bill: discount.bill || "",
    discount: discount.discount || "",
  };

  const onSubmit = async (values, { resetForm }) => {
    let objToSend = {
      bill: values.bill,
      discount: values.discount,
    };
    if (id !== undefined) {
      AdminAPI.put(`delivery-charge-discounts/${id}`, objToSend)
        .then((response) => {
          history.push(`/admin/delivery-charge-discounts`);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    } else {
      AdminAPI.post(`delivery-charge-discounts`, objToSend)
        .then((response) => {
          console.log(response);
          history.push(`/admin/delivery-charge-discounts`);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    }
  };

  const deleteDiscount = () => {
    AdminAPI.post(`delivery-charge-discounts/${id}`)
      .then((response) => {
        history.push("/admin/delivery-charge-discounts");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = () => {
    setOpenDialog(true);
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-12">
                <Link
                  to="/admin/delivery-charge-discounts"
                  type="button"
                  className="btn btn-md btn-primary float-right mb-1"
                >
                  Charge discounts
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div className="form-group row">
                                <label className="col-md-3 ">Bill</label>
                                <div className="col-md-9">
                                  <Field
                                    name={`bill`}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name={`bill`}
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.bill !== undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.bill}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-md-3 ">Discount</label>
                                <div className="col-md-9">
                                  <Field
                                    name={`discount`}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name={`discount`}
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.discount !== undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.discount}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="form-group row">
                                {id !== undefined && (
                                  <button
                                    onClick={openModal}
                                    className="btn btn-lg btn-danger mr-auto"
                                    type="button"
                                  >
                                    Delete
                                  </button>
                                )}
                                <button
                                  className="btn btn-lg btn-success ml-auto"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => deleteDiscount(false)}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
