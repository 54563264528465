// import logo from "./logo.svg";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import Routes from "./routes";
// import * as serviceWorker from "./serviceWorker";

import ProductsContextProvider from "./contexts/ProductsContext";
import CartContextProvider from "./contexts/CartContext";

function App() {
  return (
    <div className="">
      {/* <ProductsContextProvider>
      </ProductsContextProvider> */}

      <Routes />

      {/* <Switch>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch> */}
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
