import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function TextForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [textDetail, setTextDetail] = useState({});

  useEffect(() => {
    getTextDetails();
  }, []);

  const closeModal = () => {
    setOpenDialog(false);
    history.push('/admin/texts');
  }
  
  const openDeleteModal = () => {
    setOpenDeleteDialog(true);
  }

  const deleteBanner = () => {
    AdminAPI.post(`banners/${id}`)
      .then((response) => {
          history.push('/admin/texts');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTextDetails = () => {
    AdminAPI.get(`banners/${id}`)
      .then((response) => {
        setTextDetail(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    heading: Yup.string().required("This field is required"),
    detail: Yup.string().required("This field is required"),
    url: Yup.string().required("This field is required"),
  });
  const initialValues = {
    heading: textDetail.heading || "",
    detail: textDetail.detail || "",
    url: textDetail.url || "",
  };

  const onSubmit = async (values, { resetForm }) => {
    let objToSend = {
        heading: values.heading,
        detail: values.detail,
        url: values.url,
        type: "text",
    };

    AdminAPI.post(`banner/${id}`, objToSend)
        .then((response) => {
          setModalMessage(response.data.message)
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        });
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Heading</label>
                                  <div className="col-md-9">
                                    <Field
                                      name={`heading`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`heading`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Detail</label>
                                  <div className="col-md-9">
                                    <Field
                                      name={`detail`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`detail`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">URL</label>
                                  <div className="col-md-9">
                                    <Field
                                      name={`url`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`url`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                                
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="float-right">
                                      <button
                                        className="btn btn-lg btn-primary"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeModal()} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
