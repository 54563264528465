import React, { useContext, useState, useEffect, useRef } from "react";
import MultiMenus from "../components/MultiMenus";
import clsx from "clsx";
import {
  makeStyles,
  fade,
  useTheme,

  // useTheme
} from "@material-ui/core/styles";
import MessengerCustomerChat from "react-messenger-customer-chat";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Button from "@material-ui/core/Button";
import Slider from "react-slick";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {
  useParams,
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./css/checkout.css";
import CartProducts from "../pages/cart/CartProducts";
import { CartContext } from "../contexts/CartContext";
import { Icon } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { UserAPI } from "../AxiosRequest/UserAPI";
import userAuth from "../routingRules/userAuth";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, ErrorMessage, Field } from "formik";
import menuItems from "./menuItems";
import axios from "axios";
import * as yup from "yup";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
const drawerWidth = 240;
const drawerWidthCart = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButton2: {
    fontSize: "18px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  // drawer: {
  //   width: drawerWidth,
  //   flexShrink: 0,
  // },
  drawerPaper: {
    width: drawerWidth,
    paddingBottom: "20vh",
    top: "65px",

    [theme.breakpoints.down("sm")]: {
      top: "0",
    },
    [theme.breakpoints.down("xs")]: {
      top: "0",
    },
  },
  drawerCart: {
    width: drawerWidthCart,
    flexShrink: 0,
  },
  drawerPaperCart: {
    width: drawerWidthCart,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
      width: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "40px",
    marginBottom: "40px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  contentShift2: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginRight: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  btn_label: {
    color: "#fff",
    fontSize: "18px",
  },
  list: {
    width: 250,
  },
  links: {
    textDecoration: "none",
    color: "#000",
  },
  menuHeader: {
    paddingLeft: "5px",
  },
  menuList: {
    fontSize: "15px",
    color: "#333333",
    textTransform: "capitalize",
    lineHeight: "18px",
    borderBottom: "1px solid #fff",
    backgroundColor: "#f8f8f8",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
export default function HomeLayout(props) {
  const {
    total,
    loggedIn,
    state,
    itemCount,
    UserLogin,
    UserLogout,
    closeLogin,
    openLogin,
    cartItems,
  } = useContext(CartContext);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const classes = useStyles();
  // const theme = useTheme();

  const { window } = props;
  const location = useLocation();
  console.log("use location", location.pathname);
  const [open, setOpen] = React.useState(true);
  const [openCart, setOpenCart] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [loginModal, setLoginModal] = React.useState(false);
  // const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showOTOPField, setShowOTP] = React.useState(false);
  const [loginError, setLoginError] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [subState, setSubState] = useState({});
  const [categories, setCategories] = useState([]);
  const ref = useRef();
  const refTop = useRef();
  const [serverError, setServerError] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showPostOTP, setShowPostOTP] = React.useState(false);
  const [countDown, setCountDown] = React.useState("");
  const messagesEndRef = useRef(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // console.log("logggged in", state.loggedIn, cartItems);

  const handleClick = (item) => {
    setSubState((prevState) => ({ [item]: !prevState[item] }));

    history.push(`/${item}`);
  };
  const handleClickOpen = () => {
    setLoginModal(true);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getCategories();
    executeScroll();
    // scrollToBottom();

    // window.scrollTo(0, 0);

    // document.getElementById("HEADER").scrollIntoView();
    // ref.current.scrollTo(0, 0);
    // const body = document.querySelector("#HEADER");

    // body.scrollIntoView(
    //   {
    //     behavior: "smooth",
    //   },
    //   0
    // );
  }, []);
  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);
  console.log("propsssss===>", props.route);
  const getCategories = () => {
    axios
      .get(`categories`)
      .then((response) => {
        // console.log("response=====>", response.data.data.data);

        let da = response.data.data.data;
        let pp = da.map((o) => {
          let sObj = o;
          let obj = {};
          obj.name = o.name;
          obj.url = o.url;
          if (sObj.subcategories.length > 0) {
            obj.children = sObj.subcategories.map((p) => {
              let pObj = p;
              let objP = {};
              objP.name = pObj.name;
              objP.url = pObj.url;
              return objP;
            });
          }
          return obj;
        });

        // console.log("ppppppp", pp);
        setCategories(pp);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleClose = () => {
    setLoginModal(false);
    closeLogin();
  };
  useEffect(() => {
    let ImaginaryUserToken = JSON.parse(localStorage.getItem("ImaginaryUserToken"));
    if (
      ImaginaryUserToken !== null &&
      ImaginaryUserToken !== undefined &&
      ImaginaryUserToken !== ""
    ) {
      // console.log("You are logged in");
      // setLoggedIn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const logOut = () => {
    userAuth.logout(() => {
      history.push("/");
    });
    UserLogout();
  };
  var validationSchemaLogin = yup.object().shape({
    mobile_number: yup.string().required(),
    // password: yup.string().required(),
  });
  var validationSchemaLoginOTP = yup.object().shape({
    mobile_number: yup.string().required(),
    otp: yup.string().required(),
  });

  const initialValuesLogin = {
    mobile_number: "",
    otp: "",
  };
  const postOTPAgain = (values) => {
    // setLoading(true);
    setShowPostOTP(false);
    handlePostOTP(values);
  };

  const handlePostOTP = (values) => {
    axios
      .post(`auth/send-otp`, values)
      .then((response) => {
        console.log("response=====>", response);
        setLoading(false);
        setShowOTP(true);
        setServerError({
          mobile_number: "",
        });
        var timeLeft = 30;

        var timerId = setInterval(countdown, 1000);

        function countdown() {
          if (timeLeft === -1) {
            clearTimeout(timerId);
            setShowPostOTP(true);
          } else {
            setCountDown(timeLeft);
            timeLeft--;
          }
        }
      })
      .catch((error) => {
        // console.log("error from login", error.response);
        setServerError({
          mobile_number: "Please enter a valid number",
        });
        // setLoginError(error.response.data.error);
        setLoading(false);
      });
  };
  const onSubmitLogin = (values, { resetForm }) => {
    // console.log("show otp", showOTOPField, values);
    setLoading(true);
    if (showOTOPField === false) {
      handlePostOTP(values);
    } else {
      axios
        .post(`auth/login`, values)
        .then((response) => {
          // console.log("response=====>", response);
          setShowOTP(false);
          setLoading(false);

          let ImaginaryUserToken = response.data.access_token;
          localStorage.setItem(
            "ImaginaryUserToken",
            JSON.stringify(ImaginaryUserToken)
          );
          // console.log(ImaginaryUserToken);
          setLoading(false);
          UserLogin();
          setLoginModal(false);
          closeLogin();
          setServerError({});
          if (cartItems.length > 0) {
            history.push("/checkout");
          }
          userAuth.login(() => {
            // console.log("sosdosdiofdsf");
          });
        })
        .catch((error) => {
          console.log("error from login otop", error.response.data.message);
          setServerError({
            ...serverError,
            other: error.response.data.message,
          });
          setLoginError(error.response.data.error);
          setLoading(false);
        });
    }
    // setTimeout(() => {
    //   setShowOTP(true);
    // }, 2000);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMenuRoute = (route) => {
    history.push("/customer/" + route);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  console.log("this is from home login", loggedIn);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <div>
      {state.loggedIn ? (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {/* <MenuItem onClick={() => handleMenuRoute("profile")}>
            Profile
          </MenuItem>
          <MenuItem onClick={() => handleMenuRoute("account")}>
            My account
          </MenuItem> */}
          <MenuItem onClick={() => history.push("/customer/invoice")}>
            Invoice
          </MenuItem>
          <MenuItem onClick={() => logOut()}>Logout</MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleClickOpen()}>Login</MenuItem>
          {/* <MenuItem onClick={() => handleMenuRoute("register")}>
            Sign Up
          </MenuItem> */}
        </Menu>
      )}
    </div>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <div>
      {state.loggedIn ? (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={() => history.push("/customer/invoice")}>
            Invoice
          </MenuItem>
          {/* <MenuItem onClick={() => handleMenuRoute("profile")}>
            Profile
          </MenuItem> */}
          {/* <MenuItem onClick={() => handleMenuRoute("account")}>
            My account
          </MenuItem> */}
          <MenuItem onClick={() => logOut()}>Logout</MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={() => handleClickOpen()}>Login</MenuItem>
        </Menu>
      )}
    </div>
  );
  let history = useHistory();
  let match = useRouteMatch();

  const handleDrawerOpen = () => {
    // console.log("Handle draweer callllllllllllleee");
    setOpen(!open);
  };
  const handleDrawerOpenCart = () => {
    setOpenCart(!openCart);
  };

  const handleColumnWiseSearch = _.debounce((inputValue) => {
    setSearchValue(inputValue);
    history.push(`/search/${inputValue}`);
  }, 500);
  const handler = (children) => {
    return children.map((subOption) => {
      if (!subOption.children) {
        return (
          <div key={subOption.name}>
            <ListItem
              className={classes.menuList}
              key={subOption.name}
              button
              component={Link}
              to={"/" + subOption.url}
            >
              <ListItemText className="someColaa" primary={subOption.name} />
            </ListItem>
          </div>
        );
      }
      return (
        <div key={subOption.name}>
          <ListItem
            className={classes.menuList}
            button
            onClick={() => handleClick(subOption.name)}
          >
            <ListItemText className="someCola" primary={subOption.name} />
            {subState[subOption.name] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={subState[subOption.name]} timeout="auto" unmountOnExit>
            {handler(subOption.children)}
          </Collapse>
        </div>
      );
    });
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div ref={myRef}>
      <AppBar
        ref={refTop}
        style={{ background: "#7fd02b", color: "#fff" }}
        position="fixed"
      >
        <Toolbar>
          {!isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <React.Fragment>
              {!mobileOpen ? (
                <IconButton
                  // ref={ref}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <CloseIcon />
                </IconButton>
                /* <IconButton
                  ref={ref}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={(event) => event.preventDefault()}
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <CloseIcon />
                </IconButton> */
              )}
            </React.Fragment>
          )}

          {/* <Typography className={classes.title} variant="h6" noWrap> */}
          <Button
            onClick={() => {
              history.push("/");
              setSearchValue("");
            }}
            // classes={{ label: classes.btn_label }}
          >
            <img
              style={{ borderRadius: "20%" }}
              className="avatar-img"
              alt="app-bar-logo"
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
            />
          </Button>
          {/* <Button
            onClick={() => UserLogin()}
            classes={{ label: classes.btn_label }}
          >
            Login
          </Button>
          <Button
            onClick={() => UserLogout()}
            classes={{ label: classes.btn_label }}
          >
            LogOut
          </Button> */}
          {/* </Typography> */}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value);
                // console.log("onchagneeeee", e.target.value);
              }}
              inputProps={{ "aria-label": "search" }}
              // value={location === "/" ? "" : ""}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Divider />

            <MultiMenus menus={categories} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Divider />

            <MultiMenus menus={categories} />
          </Drawer>
        </Hidden>
      </nav>
      {/* <Drawer
        ref={ref}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Divider />

        <MultiMenus menus={categories} />
      </Drawer> */}

      <Drawer
        className={classes.drawerCart}
        variant="persistent"
        anchor="right"
        open={openCart}
        classes={{
          paper: classes.drawerPaperCart,
        }}
      >
        <div className="shopptingCartButtona">
          <div class="shoppingCartButton">{/* <button>hello</button> */}</div>
          <div className="header-cart">
            <div className="row py-2">
              <div className="col-12">
                <div className="d-flex">
                  <div className="px-2">
                    {" "}
                    <Icon fontSize="large">local_mall</Icon>{" "}
                  </div>
                  <div>
                    {" "}
                    <h3 className="">{itemCount} Items</h3>
                  </div>
                  <div className="pr-2 ml-auto">
                    {" "}
                    <Button
                      onClick={() => handleDrawerOpenCart()}
                      variant="contained"
                      color="secondary"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="col-5"></div> */}
            </div>
          </div>
          <div className="cart-items-list">
            <CartProducts />
          </div>
          <div class="navbar-bottom ">
            {/* <a href="#home" class="active">
              Home
            </a> */}
            <div className="checkout-btn-drawer mb-2">
              {cartItems.length > 0 && (
                <React.Fragment>
                  <button
                    type="button"
                    onClick={() => {
                      console.log("state login button", state.loggedIn);
                      if (state.loggedIn) {
                        history.push("/checkout");
                        handleDrawerOpenCart();
                      } else {
                        openLogin();
                      }
                    }}
                    className="btn btn-md btn-primary chk-btns"
                  >
                    Checkout
                  </button>

                  <button className="btn btn-md btn-primary btn-primary-2 chk-btns">
                    {total} Tk
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      {isMobile && (
        <div class="nav-bottom">
          <div className="row">
            <div className="col-6">
              {!isMobile ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <React.Fragment>
                  {!open ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => setOpen(true)}
                      edge="start"
                      className={clsx(classes.menuButton2)}
                    >
                      <MenuIcon style={{ visibility: "hidden" }} /> Start
                      Shopping
                    </IconButton>
                  ) : (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => setOpen(false)}
                      edge="start"
                      className={clsx(classes.menuButton2)}
                    >
                      <CloseIcon style={{ visibility: "hidden" }} />{" "}
                      <span className="aas">Start Shopping</span>
                    </IconButton>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="col-6">
              <IconButton
                onClick={() => handleDrawerOpenCart()}
                aria-label="cart"
                className={clsx(classes.menuButton)}
              >
                <Badge badgeContent={itemCount} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </div>
          </div>
        </div>
      )}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
          [classes.contentShift2]: openCart,
        })}
      >
        {!isMobile && (
          <div onClick={() => handleDrawerOpenCart()} className="stickyHeader">
            <div className="text-center">
              <LocalMallOutlinedIcon fontSize="small" />
            </div>
            <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
              {itemCount} Items
            </h5>
            <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
              {total} Tk
            </h5>
          </div>
        )}
        {props.children}
        <section className="bg-gray pt-60 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-img-area">
                  <img
                    width="100%"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                    alt=""
                  />
                </div>
                <hr />
              </div>
              <div className="col-md-4">
                <h3 class="footer-classic-title">Contacts</h3>
                {/* <h3>Contacts</h3> */}

                <div className="footer-contact-info">
                  <ul className="list-unstyled">
                    <li className="address">
                      <i className="fa fa-map-marker"></i>
                      Dhaka, Bangladesh
                    </li>
                    <li className="phone">
                      <i className=" fa fa-mobile"></i>
                      <a href="tel://01924241969">01924241969</a>
                    </li>
                    <li className="phone">
                      <i className=" fa fa-phone"></i>
                      <a href="tel://01786245128">01786245128</a>
                    </li>

                    <li className="email">
                      <i className=" fa fa-envelope-o"></i> imaginaryworkstation@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <h3 class="footer-classic-title">Blogs</h3>
                <p>
                  Get weekly news, updates, special offers, and exclusive
                  discounts.
                </p>
                <Link to="/privacy-policy">Privacy policy</Link>
                <div class="single"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-2">
                    {" "}
                    <div className="get-social">
                      {" "}
                      <strong> Get Social </strong>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="social-icon-list">
                      <a
                        href="https://www.facebook.com/imaginary.workstation"
                        target="_blank"
                        class="fa fa-facebook"
                      ></a>
                      {/* <a href="#" class="fa fa-twitter"></a> */}
                      {/* <a
                        href="https://www.youtube.com/channel/UCFdSniAHZONE0UGJdOE1Tag"
                        target="_blank"
                        class="fa fa-youtube"
                      ></a>
                      <a
                        href="https://www.instagram.com/Imaginary_beauty"
                        target="_blank"
                        class="fa fa-instagram"
                      ></a> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3">
                    {" "}
                    <div className="get-social">
                      {" "}
                      <strong> We accept </strong>
                    </div>
                  </div>
                  <div className="col-md-9 payment-imgs">
                    <img
                      width="100%"
                      src={`${process.env.PUBLIC_URL}/assets/images/bkash.png`}
                      alt=""
                    />
                    <img
                      width="100%"
                      src={`${process.env.PUBLIC_URL}/assets/images/rocket.png`}
                      alt=""
                    />
                    <img
                      width="100%"
                      src={`${process.env.PUBLIC_URL}/assets/images/nagad.png`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row row-10 align-items-center justify-content-sm-between">
            <div className="col-md-auto">
              <p className="rights">
                <span>©&nbsp;</span>
                <span className="copyright-year">2021</span>
                <span>&nbsp;</span>
                <span>Imaginary</span>
                <span>.&nbsp;</span>
                <span>All rights reserved</span>
              </p>
            </div>
            <div className="col-md-auto order-md-1">
              <p className="rights">
                Developed by:{" "}
                <a href="https://imaginaryworkstation.com">
                  Imaginary workstation
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MessengerCustomerChat
              pageId="2203275646466472"
              appId="255237499579757"
            />
          </div>
        </div>

        <Dialog
          open={loginModal || state.loginModal}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Login</DialogTitle>
          <DialogContent>
            <div className="login-reg-form-wrap mt-20">
              <div className="row">
                <div className="col-lg-7 m-auto">
                  <Formik
                    initialValues={initialValuesLogin}
                    validationSchema={
                      showOTOPField
                        ? validationSchemaLoginOTP
                        : validationSchemaLogin
                    }
                    onSubmit={onSubmitLogin}
                    render={({ values }) => (
                      <Form>
                        <div className="row">
                          <div
                            style={{ visibility: "hidden", height: "0px" }}
                            className="col-md-12"
                          >
                            <div className="single-input-item">
                              <Field
                                type="text"
                                placeholder="Enter your OTP"
                                name={`otp`}
                              />

                              <ErrorMessage
                                name={`otp`}
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div className="error-message">{loginError}</div>
                          </div>
                          <div className="col-md-12">
                            <div className="single-input-item">
                              <Field
                                type="text"
                                placeholder="Enter your number"
                                name={`mobile_number`}
                              />

                              <ErrorMessage
                                name={`mobile_number`}
                                component="div"
                                className="error-message"
                              />
                              {!_.isEmpty(serverError) &&
                                serverError.mobile_number !== undefined && (
                                  <span className="error-msg">
                                    {" "}
                                    {serverError.mobile_number}
                                  </span>
                                )}
                            </div>
                          </div>

                          {showOTOPField && (
                            <div className="col-md-12">
                              <div className="single-input-item">
                                <Field
                                  type="text"
                                  placeholder="Enter your OTP"
                                  name={`otp`}
                                />

                                <ErrorMessage
                                  name={`otp`}
                                  component="div"
                                  className="error-message"
                                />
                                {!_.isEmpty(serverError) &&
                                  serverError.other !== undefined && (
                                    <span className="error-msg">
                                      {" "}
                                      {serverError.other}
                                    </span>
                                  )}
                              </div>
                              <div className="error-message">{loginError}</div>{" "}
                              <br />
                              <button
                                onClick={() => postOTPAgain(values)}
                                type="button"
                                disabled={!showPostOTP}
                                className="btn btn__bg"
                              >
                                Sent OTP Again{" "}
                                {countDown === 0 ? (
                                  ""
                                ) : (
                                  <span> ( {countDown} )</span>
                                )}
                              </button>
                            </div>
                          )}
                        </div>

                        <div className="single-input-item">
                          <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                            <div className="remember-meta">
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="rememberMe"
                                  required=""
                                />
                                <label
                                  className="custom-control-label"
                                  for="rememberMe"
                                >
                                  Remember Me
                                </label>
                              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="single-input-item">
                          <button
                            type="submit"
                            className="btn btn__bg"
                            disabled={loading}
                          >
                            {loading && (
                              <React.Fragment>
                                {" "}
                                <span>Loging In</span>{" "}
                                <CircularProgress
                                  style={{ marginTop: "-2px" }}
                                  size={24}
                                />
                              </React.Fragment>
                            )}
                            {!loading && "Login"}
                          </button>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {/* <Button onClick={handleClose} color="primary">
              Subscribe
            </Button> */}
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
}
HomeLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
