import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../css/admin.css";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function CategoryForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();

  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = React.useState("");
  const [categoryValue, setCategoryValue] = React.useState("");
  const [subcategory, setSubcategory] = useState({});
  const [editing, setEditing] = useState(false);
  const [serverErrors, setServerErrors] = useState({});

  const paramID = parseInt(id);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const params = {
    page: 1,
    per_page: 15,
  };

  useEffect(() => {
    getCategoryList();
  }, [productSearchTerm]);

  const getCategoryList = () => {
    AdminAPI.get(`categories?search=${productSearchTerm}`, { params: params })
      .then((response) => {
        setCategories(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureProduct = _.debounce((event) => {
    setProductSearchTerm(event.target.value);
  }, 500);

  useEffect(() => {
    if (id !== undefined) {
      getCategoryDetails();
    }
  }, []);

  const goto = () => {
    setOpenDialog(false);
    history.push("/admin/subcategories");
  };

  const getCategoryDetails = () => {
    AdminAPI.get(`subcategories/${id}`)
      .then((response) => {
        setEditing(true);

        setSubcategory(response.data.data);
        setCategoryValue(response.data.data.category.name);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    category_id: Yup.string().required("This field is required"),
  });
  const initialValues = {
    name: subcategory.name || "",
    category_id: subcategory.category_id || "",
  };

  const onSubmit = async (values, { resetForm }) => {
    let objToSend = {
      name: values.name,
      category_id: values.category_id,
    };
    if (id !== undefined) {
      AdminAPI.put(`subcategories/${id}`, objToSend)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    } else {
      AdminAPI.post(`subcategories`, objToSend)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    }
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Name</label>
                                  <div className="col-md-9">
                                    <Field
                                      name={`name`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`name`}
                                      component="div"
                                      className="error-message"
                                    />
                                    {!_.isEmpty(serverErrors) &&
                                      serverErrors.name !== undefined && (
                                        <span className="error-msg">
                                          {" "}
                                          {serverErrors.name}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row prescription  ">
                                <label className="col-md-3 ">Category</label>
                                <div className="col-md-9">
                                  <Autocomplete
                                    id="disabledHelperText"
                                    getOptionLabel={(option) =>
                                      option.name || categoryValue
                                    }
                                    getOptionSelected={(option, value) => {
                                      return option.name === value;
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCaptureProduct(event);
                                    }}
                                    value={categoryValue}
                                    options={categories}
                                    onChange={(event, value) => {
                                      if (value == null) {
                                        console.log("null");
                                        setCategoryValue("");
                                      } else {
                                        setFieldValue(`category_id`, value.id);
                                        setCategoryValue(value.name);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        name={`category_id`}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name={`category_id`}
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.category_id !== undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.category_id}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <div className="text-right">
                                    <button
                                      className="btn btn-lg btn-primary"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully added your subcategory
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goto} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
