import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function ProductDetails() {
  const [productDetails, setProductDetails] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [path, setPath] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [productId, setProductId] = useState(Number);
  let { id } = useParams();
  let history = useHistory();

  const [defaultStatus, setDefaultStatus] = useState("");
  const [selecedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = () => {
    AdminAPI
      .get(`products/${id}`)
      .then((response) => {
        setProductDetails(response.data.data);
        setProductVariants(response.data.data.product_variants);
        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const deleteProduct = () => {
    AdminAPI.post(`products/${id}`)
      .then((response) => {
        setOpenDialog(false);
        history.push("/admin/products");
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  const onChangeStatus = (id, e) => {
    AdminAPI
      .post(`product-variants/${id}/update-status`, {
        status: e.target.value
      })
      .then((response) => {
        getProductDetails();
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <button
              onClick={() => setOpenDialog(true)}
              className="btn btn-md btn-danger mr-1"
              type="button"
            >
              Delete
            </button>
            <Link
              to={`/admin/edit-product/${productDetails.id}`}
              type="button"
              className="btn btn-md btn-success"
            >
              Edit Product
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div>Name: {productDetails.name}</div>
            <div>Description: {productDetails.description}</div>
            <div>Category: {productDetails.subcategory && productDetails.subcategory.category != null ? productDetails.subcategory.category.name : ""}</div>
            <div>Subcategory: {productDetails.subcategory != null ? productDetails.subcategory.name : ""}</div>
            <div>Status: {productDetails.status}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Photo</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Sold</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {productVariants !== undefined &&
                    productVariants.length > 0 &&
                    productVariants.map((variant, i) => (
                      <tr key={i}>
                        <td>
                          {
                            variant.product_photos.length !== 0 ?
                            <img
                              src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${id}/${variant.id}/${variant.product_photos[0].photo}`}
                              style={{ height: "60px" }}
                              alt={variant.unit}
                            />
                            : ""
                          }
                        </td>
                        <td>{variant.unit}</td>
                        <td>{variant.price}</td>
                        <td>{variant.discount}</td>
                        <td>{variant.sold}</td>
                        <td>
                          <select
                            value={variant.status}
                            onChange={(e) => {
                              onChangeStatus(variant.id, e);
                            }}
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                            <option value="Out of stock">Out of stock</option>
                          </select>
                        </td>

                        <td>
                          <Link
                            to={`/admin/variant/view/${variant.id}`}
                            type="button"
                            className="btn btn-sm btn-primary mb-1"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this invoice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => deleteProduct()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductDetails;
