// import React, { useContext } from "react";
import ProductListItem from "./ProductListItem";
import React, { useState, useEffect, useContext } from "react";
import "./css/component.css";

import axios from "axios";
const ProductsList = ({ products, path }) => {
  useEffect(() => {
    getDocDetails();
    getDocDetailsJ();
  }, []);

  const getDocDetails = () => {
    axios
      .get(`https://fakestoreapi.com/products?limit=5 `)
      .then((response) => {
        // setProducts(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getDocDetailsJ = () => {
    axios
      .get(`https://fakestoreapi.com/products/category/jewelery`)
      .then((response) => {
        // setJProducts(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className={"styles.p__container"}>
      {/* <div className="row">
        <div className="col-sm-8">
          <div className="py-3">{products.length} Products</div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <input
              type="text"
              name=""
              placeholder="Search product"
              className="form-control"
              id=""
            />
          </div>
        </div>
      </div> */}

      <div className={"shop-product-wrap list-view"}>
        {products.map((product) => (
          <ProductListItem key={product.id} product={product} path={path} />
        ))}
      </div>
      {/* <div className={"product_grid"}>
        {jproducts.map((product) => (
          <ProductItems key={product.id} product={product} />
        ))}
      </div> */}
    </div>
  );
};

export default ProductsList;
