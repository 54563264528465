import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../../contexts/CartContext";
import { formatNumber } from "../../helpers/utils";
import CartItemDetails from "../cart/CartItemDetails";
import "./css/home.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Link } from "react-router-dom";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    color: "#000",
    "&:hover": {
      border: "none",
    },
  },
  disabled: {
    border: "none!important",
  },
  outlinedSizeSmall: {
    padding: "0px 9px",
  },
}));
function CartDetails() {
  const classes = useStyles();
  const {
    cartItems,
    state,
    total,
    handleUser,
    checkout,
    clearCart,
    increase,
    decrease,
    removeProduct,
    loggedIn,
  } = useContext(CartContext);

  const [totalDiscountPrice, setTotalDiscountPrice] = useState(Number);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [code, setCode] = useState("");
  const [discountData, setDiscountData] = useState([]);
  const [subTotal, setSubTotal] = useState(Number);
  useEffect(() => {
    window.scrollTo(0, 0);
    const testsTotalPrice = cartItems.map((o) => parseInt(o.price));
    const subTotal = cartItems.map((o) => {
      console.log("price", o.selectedVariant.price * o.quantity);
      return parseInt(o.selectedVariant.price * o.quantity);
    });
    console.log("sub total", subTotal);
    let totalSub = subTotal.reduce(function (a, b) {
      return a + b;
    }, 0);
    setSubTotal(totalSub);
    const testsTotalPercentageDiscount = cartItems.map((o) => {
      return parseInt(
        (o.selectedVariant.price / 100) *
          o.selectedVariant.discount *
          o.quantity
      );
    });

    let totalDicountP = testsTotalPercentageDiscount.reduce(function (a, b) {
      return a + b;
    }, 0);

    console.log("overall discount PPPPPPP", totalDicountP);

    if (discountData.length > 0) {
      // console.log("discount p", totalDicountP);
      let result = getDiscount(total, discountData);
      if (!_.isEmpty(result)) {
        let discountA = result.discount;

        totalDicountP = totalDicountP + discountA;
      }
    }

    // let totalD = totalDicountP;

    setTotalDiscountPrice(totalDicountP);
    let actualPrice = totalSub - totalDicountP;
    console.log("actualPrice", actualPrice);
    console.log("totalSub", totalSub);
    setTotalPrice(totalSub - totalDicountP);
  }, [cartItems, discountData]);
  useEffect(() => {
    getDiscountList();
  }, []);
  const getDiscountList = () => {
    axios
      .get("delivery-charge-discounts")
      .then((response) => {
        let data = response.data.data.data;
        const sorted = data.sort(function (a, b) {
          return a.bill - b.bill;
        });

        setDiscountData(sorted);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDiscount = (price, discounts) => {
    const discountArrayNew = JSON.parse(JSON.stringify(discounts));
    const sorted = discountArrayNew.sort(function (a, b) {
      return a.bill - b.bill;
    });

    const discountObj = sorted.find((item, index, array) => {
      if (
        index < array.length - 1 &&
        price >= item.bill &&
        price < array[index + 1].bill
      ) {
        return true;
      }

      if (index === array.length - 1 && price >= item.bill) {
        return true;
      }

      // if true is returned, item is returned and iteration is stopped
      // for falsy scenario returns undefined
    });

    if (discountObj === undefined) {
      return 0;
    }
    return discountObj;
  };
  return (
    <div>
      <div className="cart-main-wrapper pt-60 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="pro-thumbnail">Thumbnail</th>
                      <th className="pro-title">Product</th>
                      <th className="pro-price">Price</th>
                      <th className="pro-quantity">Quantity</th>
                      <th className="pro-subtotal">Total</th>
                      <th className="pro-remove">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((product, i) => (
                      <tr>
                        <td>
                          <img
                            alt={product.title}
                            style={{ margin: "0 auto" }}
                            src={`${process.env.REACT_APP_PUBLIC_URL}/files/product/${product.id}/${product.selectedVariant.id}/${product.selectedVariant.product_photos[0].photo}`}
                            className="img-fluid"
                          />
                        </td>
                        <td>
                          {" "}
                          <h5 className="mb-1">{product.name}</h5>
                        </td>
                        <td>
                          {" "}
                          <p className="mb-1">
                            ৳{formatNumber(
                              product.selectedVariant.price -
                                (product.selectedVariant.discount / 100) *
                                  product.selectedVariant.price
                            )}
                          </p>
                        </td>
                        <td>
                          {" "}
                          <ButtonGroup
                            orientation="vertical"
                            color="primary"
                            aria-label="vertical outlined primary button group"
                          >
                            <Button
                              size="small"
                              onClick={() =>
                                increase(product, product.selectedVariant)
                              }
                              classes={{
                                root: classes.root,
                                outlinedSizeSmall: classes.outlinedSizeSmall,
                              }}
                            >
                              <Icon size="small">expand_less</Icon>
                            </Button>
                            <Button
                              size="small"
                              classes={{
                                root: classes.root,
                                outlinedSizeSmall: classes.outlinedSizeSmall,
                              }}
                              style={{ pointerEvents: "none" }}
                            >
                              {product.quantity}
                            </Button>
                            <Button
                              size="small"
                              disabled={product.quantity === 1}
                              onClick={() =>
                                decrease(product, product.selectedVariant)
                              }
                              classes={{
                                root: classes.root,
                                disabled: classes.disabled,
                                outlinedSizeSmall: classes.outlinedSizeSmall,
                              }}
                            >
                              {" "}
                              <Icon size="small">expand_more</Icon>
                            </Button>
                          </ButtonGroup>
                        </td>
                        <td>
                          {" "}
                          <p className="mb-1">
                            ৳{formatNumber(
                              (product.selectedVariant.price -
                                (product.selectedVariant.discount / 100) *
                                  product.selectedVariant.price) *
                                product.quantity
                            )}
                          </p>
                        </td>

                        <td className="pro-remove">
                          <Button
                            size="small"
                            onClick={() =>
                              removeProduct(product, product.selectedVariant)
                            }
                            classes={{ root: classes.root }}
                          >
                            {" "}
                            <Icon size="small">close</Icon>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-5 ml-auto">
              <div className="cart-calculator-wrapper">
                <div className="cart-calculate-items">
                  <h3>Cart Totals</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Sub Total</td>
                          <td><strong>৳</strong>{subTotal}</td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td><strong>৳</strong>{totalDiscountPrice}</td>
                        </tr>
                        <tr className="total">
                          <td>Total</td>
                          <td className="total-amount"><strong>৳</strong>{totalPrice}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <Link to="/checkout" className="btn btn__bg d-block">
                  Proceed To Checkout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartDetails;
