import React, { useContext, useState, useEffect } from "react";
import { InvoiceContext } from "../../../contexts/InvoiceContext";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function InvoiceList({ admin }) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState();
  const { clearCart } = useContext(InvoiceContext);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    clearCart();
    getInvoiceList();
  }, [params]);

  console.log("admin======>", admin);

  const onChagneStatus = (id, e) => {
    setInvoiceId(id);
    setStatus(e.target.value);
    setOpenDialog(true);
  };

  const updateInvoice = () => {
    AdminAPI.post(`invoices/${invoiceId}/update-status`, {
      status: status,
    })
      .then((response) => {
        setOpenDialog(false);
        getInvoiceList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const getInvoiceList = () => {
    AdminAPI.get(`invoices`, { params: params })
      .then((response) => {
        setInvoiceList(response.data.data.data);
        setTotalPage(response.data.data.last_page)
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            {admin && (
              <Link
                to="/admin/add-invoice"
                type="button"
                className="btn btn-md btn-success"
                disa
              >
                Add Invoice
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Pending">Pending</option>
              <option value="Processing">Processing</option>
              <option value="Delivered">Delivered</option>
              <option value="Returned">Returned</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table class="custom-responsive-table">
                <thead>
                  <tr>
                    <th scope="col">Customer</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Order</th>
                    <th scope="col">Address</th>
                    <th scope="col">Admin</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceList.map((invoice, i) => (
                    <tr key={i}>
                      <td data-column="Customer">{invoice.customer_name}</td>
                      <td data-column="Bill">{invoice.bill}</td>
                      <td data-column="Order">
                        {moment(invoice.updated_at).format("DD-MM-yy")}
                      </td>
                      <td data-column="Address">
                        {invoice.address} (
                        {invoice.inside_dhaka == 1 ? "Inside" : "Outside"})
                      </td>
                      <td data-column="Admin">
                        {invoice.referred_by == "Admin" && invoice.admin != null
                          ? invoice.admin.name
                          : ""}
                      </td>
                      <td data-column="Status">
                        <select
                          disabled={!admin}
                          value={invoice.status}
                          onChange={(e) => {
                            onChagneStatus(invoice.id, e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="Pending">Pending</option>
                          <option value="Processing">Processing</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Returned">Returned</option>
                        </select>
                      </td>
                      <td data-column="Action">
                        <Link
                          to={`/admin/invoice/view/${invoice.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to update status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => updateInvoice()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InvoiceList;
