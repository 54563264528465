import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import moment from "moment";

function BlogDetails() {
  const [images, setImages] = useState([]);
  const [blog, setBlog] = useState({});
  const [path, setPath] = useState({});
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    getBlogs();
    window.scrollTo(0, 0);
  }, []);
  const getBlogs = () => {
    axios
      .get(`blogs/${id}`)
      .then((response) => {
        setBlog(response.data.data);
        let data = response.data.data.blog_attachments;
        setPath(response.data.cover_path);
        console.log("response data", response.data);
        let im = data.map((o) => {
          let obj = {};
          obj.src = `${process.env.REACT_APP_PUBLIC_URL}/${response.data.attachment_path}/${o.blog_id}/${o.attachment}`;
          return obj;
        });
        setImages(im);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let { id } = useParams();
  return (
    <div>
      <div className="container-fluid px-0 pt-1">
        <div className="row">
          <div className="col-md-12">
          <img width="100%" src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${blog.cover}`} alt={blog.title} />
            {/* <Slider {...settings}>
              {images.map((img, index) => (
                <div>
                  <img width="100%" src={img.src} alt="" />
                </div>
              ))}
            </Slider> */}
            <div className="blog-item-wrapper blog-item-wrapper__blog-grid blog-item-wrapper__blog-details">
              <div class="blog-inner-content">
                <h5 class="cat-name">{/* <a href="#">fruits</a> */}</h5>
                <h3>{blog.title}</h3>
                <ul class="blog-meta">
                  <li>
                    Post by: <span>{blog.admin !== undefined ? blog.admin.name : ""}</span>
                  </li>
                  <li> {moment(blog.updated_at).format("MMMM D Y")}</li>
                </ul>
                <p> {blog.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
