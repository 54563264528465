import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { formatNumber } from "../../helpers/utils";

const ProductItem = ({ product }) => {
  const {
    addProduct,
    cartItems,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.id === product.id);
  };
  const checkQuantity = (product) => {
    // console.log("product", product);
    let selectedProduct = cartItems.find((item) => item.id === product.id);
    if (selectedProduct !== undefined) {
      if (Object.keys(selectedProduct).includes("quantity"))
        return selectedProduct.quantity;
    }
    return 0;
  };

  // console.log("cartitem's", cartItems);

  return (
    <div className="card card-body">
      <img
        style={{ display: "block", margin: "0 auto 10px", maxHeight: "200px" }}
        className="img-fluid"
        src={product.image}
        alt=""
      />
      <p>{product.title}</p>
      <h3 className="text-left">{formatNumber(product.price)}Tk</h3>
      <div className="text-right">
        <Link to="/" className="btn btn-link btn-sm mr-2">
          Details
        </Link>

        {checkQuantity(product) > 1 && (
          <button
            onClick={() => decrease(product)}
            className="btn btn-outline-primary btn-sm"
          >
            Remove
          </button>
        )}
        {checkQuantity(product) === 1 && (
          <button
            onClick={() => removeProduct(product)}
            className="btn btn-outline-primary btn-sm"
          >
            Remove
          </button>
        )}

        {isInCart(product) && (
          <div>
            <button
              onClick={() => increase(product)}
              className="btn btn-outline-primary btn-sm"
            >
              Add more
            </button>
          </div>
        )}

        {!isInCart(product) && (
          <button
            onClick={() => addProduct(product)}
            className="btn btn-primary btn-sm"
          >
            Add to cart
          </button>
        )}

        {checkQuantity(product)}
      </div>
    </div>
  );
};

export default ProductItem;
