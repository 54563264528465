import axios from "axios";
import history from "../history";

const UserAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/user`,
});

UserAPI.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const ImaginaryUserToken = JSON.parse(
        localStorage.getItem("ImaginaryUserToken")
      );

      if (ImaginaryUserToken) {
        config.headers.Authorization = `Bearer ${ImaginaryUserToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
UserAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Errror from USER API", error.response.status);
      const path = document.location.pathname;
      localStorage.removeItem("ImaginaryUserToken");
      localStorage.setItem("authenticated", JSON.stringify(false));
      history.push("/");
    }
    return Promise.reject(error);
  }
);

export { UserAPI };
