import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function ProductList() {
  const [productList, setProductList] = useState([]);
  const [path, setPath] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getProductList();
  }, [params]);

  const getProductList = () => {
    AdminAPI.get(`products`, { params: params })
      .then((response) => {
        setProductList(response.data.data.data);
        setPath(response.data.path);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const onChangeStatus = (id, e) => {
    AdminAPI.post(`products/${id}/update-status`, {
      status: e.target.value,
    })
      .then((response) => {
        getProductList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event, value) => {
    console.log(event, value);
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/add-product"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Product
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Out of stock">Out of stock</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col" style={{ width: "5%" }}>
                      Photo
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                      Name
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                      Category
                    </th>
                    <th scope="col" style={{ width: "30%" }}>
                      Subcategory
                    </th>
                    <th scope="col" style={{ width: "5%" }}>
                      Variant
                    </th>
                    <th scope="col" style={{ width: "20%" }}>
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {productList.map((product, i) => (
                    <tr key={i}>
                      <td>
                        {product.product_variants !== null &&
                        product.product_variants[0].product_photos.length > 0 &&
                        product.product_variants[0] !== undefined ? (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${product.id}/${product.product_variants[0].id}/${product.product_variants[0].product_photos[0].photo}`}
                            style={{ height: "60px" }}
                            alt={product.name}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{product.name}</td>
                      <td>
                        {product.subcategory !== undefined && (
                          <span> {product.subcategory.category.name}</span>
                        )}
                      </td>
                      <td>
                        {product.subcategory != null
                          ? product.subcategory.name
                          : ""}
                      </td>
                      <td>
                        {product.variant_count != null
                          ? product.variant_count.number
                          : 0}
                      </td>
                      <td>
                        <select
                          value={product.status}
                          onChange={(e) => {
                            onChangeStatus(product.id, e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Out of stock">Out of stock</option>
                        </select>
                      </td>

                      <td>
                        <Link
                          to={`/admin/product/view/${product.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductList;
