import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";

function ChargeDiscountList() {
  const [chargeDiscountList, setChargeDiscountList] = useState([]);

  useEffect(() => {
    getChargeDiscountList();
  }, []);

  const getChargeDiscountList = () => {
    AdminAPI
      .get(`delivery-charge-discounts`)
      .then((response) => {
        setChargeDiscountList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link
                to="/admin/delivery-charge-discount/add-new"
                type="button"
                className="btn btn-md btn-success float-right mb-1"
            >
                Add Charge discount
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th className="text-center" scope="col">Minimum Bill</th>
                    <th className="text-center" scope="col">Discount</th>
                    <th className="text-center" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {chargeDiscountList.map((charge, i) => (
                    <tr key={i}>
                      <td className="text-right"> {charge.bill} Tk</td>
                      <td className="text-right"> {charge.discount} Tk</td>
                      <td className="text-right">
                        <Link
                          to={`/admin/delivery-charge-discount/view/${charge.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChargeDiscountList;
