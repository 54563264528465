import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function SubcategoryList() {
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getSubcategoryList();
  }, [params]);

  const getSubcategoryList = () => {
    AdminAPI
      .get(`subcategories`, {params: params})
      .then((response) => {
        setSubcategoryList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const onChagneStatus = (id, e) => {
    AdminAPI
      .post(`subcategories/${id}/update-status`, {
        status: e.target.value
      })
      .then((response) => {
        getSubcategoryList();
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/add-subcategory"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Subcategory
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-4 my-1">
            <select
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "status");
              }}
              className="form-control"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="col-8 my-1">
            <input className="form-control" placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search")
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Product</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {subcategoryList.map((subcategory, i) => (
                    <tr key={i}>
                      <td>{subcategory.name}</td>
                      <td>{subcategory.category != null ? subcategory.category.name : ""}</td>
                      <td>{subcategory.product_count !== null ? subcategory.product_count.number : 0}</td>
                      <td>
                        <select
                          value={subcategory.status}
                          onChange={(e) => {
                            onChagneStatus(subcategory.id, e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </td>
                      <td>
                        <Link
                          to={`/admin/subcategory/view/${subcategory.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default SubcategoryList;
