const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};
const authenticated = (login) => {
  localStorage.setItem("authenticated", JSON.stringify(login ? true : false));
};

export const sumItems = (cartItems) => {
  Storage(cartItems);
  let itemCount = cartItems.reduce(
    (total, product) => total + product.quantity,
    0
  );
  let total = cartItems
    .reduce(
      (total, product) =>
        total +
        (product.selectedVariant.price -
          (product.selectedVariant.discount / 100) *
            product.selectedVariant.price) *
          product.quantity,
      0
    )
    .toFixed(2);
  return { itemCount, total };
};
// {
//   formatNumber(
//     selectedVariant.price -
//       (selectedVariant.discount / 100) * selectedVariant.price
//   );
// }

export const CartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      if (
        !state.cartItems.find(
          (item) => item.selectedVariant.id === action.variant.id
        )
      ) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
          selectedVariant: action.variant,
        });
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "REMOVE_ITEM":
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter(
            (item) => item.selectedVariant.id !== action.variant.id
          )
        ),
        cartItems: [
          ...state.cartItems.filter(
            (item) => item.selectedVariant.id !== action.variant.id
          ),
        ],
      };
    case "INCREASE":
      state.cartItems[
        state.cartItems.findIndex(
          (item) => item.selectedVariant.id === action.variant.id
        )
      ].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "DECREASE":
      state.cartItems[
        state.cartItems.findIndex(
          (item) => item.selectedVariant.id === action.variant.id
        )
      ].quantity--;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case "CHECKOUT":
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };
    case "OPEN_MODAL":
      return {
        ...state,
        loginModal: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        loginModal: false,
      };
    case "USER_AUTH":
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
        ...authenticated(true),
        loggedIn: true,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
        ...authenticated(false),
        loggedIn: false,
      };
    case "CLEAR":
      return {
        ...state,
        cartItems: [],
        ...sumItems([]),
      };
    default:
      return state;
  }
};
