import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { formatNumber } from "../helpers/utils";
import { useContainerDimensions } from "./useContainerDimensions";
import "./css/component.css";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      fontSize: "9px",
    },
  },
}));
const ProductsListItem = ({ product, path }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  let history = useHistory();
  const [price, setPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState({});
  const classes = useStyles();
  const ref = useRef(null);
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const {
    addProduct,
    cartItems,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.selectedVariant.id === product.id);
  };
  const checkQuantity = (product) => {
    // console.log("product", product);
    let selectedProduct = cartItems.find(
      (item) => item.selectedVariant.id === product.id
    );
    if (selectedProduct !== undefined) {
      if (Object.keys(selectedProduct).includes("quantity"))
        return selectedProduct.quantity;
    }
    return 0;
  };
  const decreaseProduct = (item) => {
    if (checkQuantity(item) > 1) {
      decrease(product, selectedVariant);
    } else {
      removeProduct(product, selectedVariant);
    }
  };
  const onChagneVarint = (e) => {
    let pID = parseInt(e.target.value);
    let p = { ...product };
    let arr = p.product_variants.filter((o) => o.id === pID);
    setSelectedVariant(arr[0]);
  };

  useEffect(() => {
    if (product.product_variants.length > 0) {
      setSelectedVariant(product.product_variants[0]);
    }
  }, []);
  useEffect(() => {
    // console.log("width", ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  return (
    <div>
      <div ref={componentRef} class="product-list-item mb-30">
        <div className="row">
          <div className={width > 896 ? "col-lg-3" : "col-lg-4"}>
            <div class="item-image-list">
              {selectedVariant !== undefined &&
                selectedVariant.product_photos !== undefined &&
                selectedVariant.product_photos.length > 0 && (
                  <img
                    className="img-fluida"
                    src={`${process.env.REACT_APP_PUBLIC_URL}/${path}/${product.id}/${selectedVariant.id}/${selectedVariant.product_photos[0].photo}`}
                    alt=""
                  />
                )}
            </div>
          </div>
          <div className={width > 896 ? "col-lg-9" : "col-lg-8"}>
            <div class="product-content-list-item">
              <div class="product-name">
                <h4
                  onClick={() => history.push(`/product-details/${product.id}`)}
                >
                  {product.name}
                </h4>
              </div>
              <div class="price-box">
                {selectedVariant !== undefined && (
                  <span class="regular-price">
                    {formatNumber(selectedVariant.price)} Tk
                  </span>
                )}
              </div>
              <p>{product.description}</p>
              <div className="row">
                <div className="col-md-5">
                  <div className="select-box-area-list my-3">
                    {product.product_variants.length > 1 ? (
                      <div className="text-left product-price">
                        <select
                          onChange={(e) => {
                            onChagneVarint(e);
                          }}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          {product.product_variants.map((p, i) => (
                            <option value={p.id}>
                              {p.unit} - {p.price}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="select_height"> </div>
                    )}
                  </div>

                  <div className="">
                    {isInCart(selectedVariant) && (
                      <div>
                        <ButtonGroup
                          size="small"
                          variant="contained"
                          color="secondary"
                          aria-label="outlined primary button group"
                        >
                          <Button
                            classes={{ root: classes.root }}
                            onClick={() => decreaseProduct(selectedVariant)}
                          >
                            {" "}
                            <Icon color="white">remove</Icon>
                          </Button>
                          <Button classes={{ root: classes.root }}>
                            {" "}
                            <span className="quantity_areaa">
                              {checkQuantity(selectedVariant)} in bag
                            </span>
                          </Button>
                          <Button
                            classes={{ root: classes.root }}
                            onClick={() => increase(product, selectedVariant)}
                          >
                            {" "}
                            <Icon color="white">add</Icon>
                          </Button>
                        </ButtonGroup>
                      </div>
                    )}

                    {!isInCart(selectedVariant) && (
                      <button
                        style={{ maxWidth: "230px" }}
                        onClick={() => addProduct(product, selectedVariant)}
                        className="btn btn-primary btn-block"
                      >
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsListItem;
