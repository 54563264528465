import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import moment from "moment";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

function PurchaseList() {
  const [purchaseList, setPurchaseList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });

  useEffect(() => {
    getPurchaseList();
  }, [params]);

  const getPurchaseList = () => {
    AdminAPI
      .get(`purchases`, {params: params})
      .then((response) => {
        setPurchaseList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);
  
  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="/admin/add-purchase"
              type="button"
              className="btn btn-md btn-success"
            >
              Add Purchase
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-8 offset-md-4 my-1">
            <input className="form-control" placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search")
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Subcategory</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Total</th>
                    <th scope="col">Date</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseList.map((purchase, i) => (
                    <tr key={i}>
                      <td> {
                        purchase.product_variant !== null &&
                        purchase.product_variant.product !== null ?
                        purchase.product_variant.product.name : "" } {" "}
                        ({purchase.product_variant !== null ? purchase.product_variant.unit : "" })
                      </td>
                      <td> {
                          purchase.product_variant !== null &&
                          purchase.product_variant.product !== null && 
                          purchase.product_variant.product.subcategory !== null &&
                          purchase.product_variant.product.subcategory.category !== null ?
                          purchase.product_variant.product.subcategory.category.name : ""
                      } </td>
                      <td>{
                        purchase.product_variant !== null &&
                        purchase.product_variant.product !== null && 
                        purchase.product_variant.product.subcategory !== null ?
                        purchase.product_variant.product.subcategory.name : ""
                      }</td>
                      <td> { purchase.unit_price } </td>
                      <td> { purchase.quantity } </td>
                      <td> { purchase.total } </td>
                      <td>
                        {purchase.purchase_date != null ? moment(purchase.purchase_date).format("YYYY-MM-DD") : ""}
                      </td>
                      <td>
                        <Link
                          to={`/admin/purchase/view/${purchase.id}`}
                          type="button"
                          className="btn btn-sm btn-primary mb-1"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default PurchaseList;
