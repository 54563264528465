import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function OpenCloseForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();

  const [openDialog, setOpenDialog] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [settingDetail, setSettingDetail] = useState({});

  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    getSettingDetails();
  }, []);

  const getSettingDetails = () => {
    AdminAPI.get(`preferences/${id}`)
      .then((response) => {
        setSettingDetail(response.data.data);
        handleServerProductPhoto(response.data.data.value);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const removeImage = (id) => {
    let copyArray = [...previews];
    let hello = copyArray.filter((o) => o.id !== id);
    setPreviews(hello);
  };
  const removeImageServer = (id) => {
    let copyArrayFiles = [...files];
    let helloFiles = copyArrayFiles.filter((o) => o.id !== id);
    setFiles(helloFiles);
  };

  const uploadMultipleFiles = (e, index) => {
    const fileList = Array.from(e.target.files);

    if (checkMimeType(e)) {
      const mappedFilesforIndexId = fileList.map((file) => {
        let obj = file;
        obj.id = _.uniqueId("gfg_");
        obj.photoIndex = index;
        return obj;
      });

      const mappedFiles = mappedFilesforIndexId.map((file) => {
        return {
          originalName: file.name,
          mimetype: file.type,
          id: file.id,
          photoIndex: file.photoIndex,
          preview: URL.createObjectURL(file),
        };
      });
      setFiles([...files, ...mappedFilesforIndexId]);
      setPreviews([...previews, ...mappedFiles]);
    }
  };
  const getBlobFromFile = async (photo) => {
    let fileExt = photo.split(".").pop();

    const response = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/files/preferences/${photo}`,

        {
          responseType: "blob",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        var objectURL = URL.createObjectURL(response.data);
        var obj = {
          originalName: photo,
          preview: objectURL,
          id: _.uniqueId("gfg_"),
          mimetype: `image/${fileExt}`,
        };

        return obj;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
  const handleServerProductPhoto = async (image) => {
    let previewFiles = [];
    let previewFile = await getBlobFromFile(image);
    previewFiles.push(previewFile);
    let fineArr = _.compact(previewFiles);
    setPreviews(fineArr);
  };

  const showImages = (index) => {
    console.log("index", index);
    let filteredArray = [...previews];
    filteredArray.filter((x) => x.id === index);
    console.log("fileted array", filteredArray);
  };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
  });
  const initialValues = {
    name: settingDetail.name || "",
  };

  const getFile = async (paramObj) => {
    const { preview, originalName, mimetype } = paramObj;
    const blob = await fetch(preview).then((r) => r.blob());
    const newfileObj = new File([blob], `${originalName}`, { type: mimetype });
    return newfileObj;
  };

  const onSubmit = async (values, { resetForm }) => {
    let formData = new FormData();
    formData.append("name", values.name);
    if (previews.length > 0) {
      let someFile = await getFile(previews[0]);
      console.log("some file", someFile);
      formData.append("image", someFile);
    }
    AdminAPI.post(`closed-modal/${id}`, formData)
      .then((response) => {
        history.push('/admin/settings');
      })
      .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
      });
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Name</label>
                                  <div className="col-md-9">
                                    <Field
                                      name={`name`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`name`}
                                      component="div"
                                      className="error-message"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Image</label>
                                  <div className="col-md-9">
                                    <input
                                      id={`btn-input`}
                                      style={{ display: "none" }}
                                      multiple={false}
                                      type="file"
                                      onChange={(event) => {
                                        uploadMultipleFiles(event);
                                      }}
                                    />
                                    <label htmlFor={`btn-input`}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                      >
                                        Upload
                                      </Button>
                                    </label>

                                    {previews !== undefined &&
                                      previews.length > 0 && (
                                        <div className="form-group multi-preview">
                                          {(previews || []).map((url, key) => (
                                            <div
                                              key={key}
                                              className="container_img"
                                            >
                                              <img
                                                src={url.preview}
                                                alt="..."
                                              />
                                              <div className="topright">
                                                <button
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setFiles([]);
                                                    setPreviews([]);
                                                  }}
                                                  className="btn btn-sm"
                                                >
                                                  <HighlightOffIcon color="secondary" />
                                                </button>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="text-right">
                                      <button
                                        // disabled={
                                        //   medData.length === 0 &&
                                        //   observationData.length === 0 &&
                                        //   testData.length === 0 &&
                                        //   files.length === 0
                                        // }
                                        className="btn btn-lg btn-primary"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully added your categories
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// const top100Films = [
//   { title: "Napa", year: 1994 },
//   { title: "Seclo", year: 1972 },
// ];
