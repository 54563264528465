import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import "../css/admin.css";
import { UserAPI } from "../../../AxiosRequest/UserAPI";

export default function UserProfile() {
  const [loading, setLoading] = useState(false);

  const [erroropen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [adminData, setAdminData] = useState({});

  // Backend Error Messages
  const [nameErros, setNameErrors] = useState([]);
  const [phoneErros, setPhoneError] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Backend Errors Handle
  const [backendErrorMessage, setBackendErrorMessage] = useState({});

  useEffect(() => {
    getAdminData();
  }, []);
  const getAdminData = () => {
    UserAPI.get("auth/me")
      .then((response) => {
        setAdminData(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name field is required"),
    phone: Yup.string().required("Please enter your phone number"),
  });
  const initialValues = {
    name: adminData.name || "",
    phone: adminData.phone || "",
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    UserAPI.post("auth/me", values)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setSuccessMessage(response.data.message);
          setOpen(true);
          setErrorOpen(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        if (error.response.data.message) {
          setErrorMessage(error.response.data.message);
          setErrorOpen(true);
          setOpen(false);
        }
        if (error.response.data.data !== undefined) {
          setErrorOpen(true);
          setOpen(false);
        }

        if (error.response.data.errors !== undefined) {
          setBackendErrorMessage(error.response.data.errors);
        }
      });
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group row ">
                          <label className="col-sm-3 offset-sm-1 col-form-label ">
                            Name
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Your Name"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error-message"
                            />
                            {nameErros.map((item, i) => (
                              <p className="error-message" key={i}>
                                {item}
                              </p>
                            ))}
                          </div>
                        </div>
                        <div className="form-group row  a">
                          <label className="col-sm-3 offset-sm-1 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-7">
                            <Field
                              name="phone"
                              type="text"
                              className="form-control"
                              placeholder="Phone/Mobile Number"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error-message"
                            />
                            {phoneErros.map((item, i) => (
                              <p className="error-message" key={i}>
                                {item}
                              </p>
                            ))}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 my-4">
                            <Collapse in={open}>
                              <Alert
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {successMessage}
                              </Alert>
                            </Collapse>
                            <Collapse in={erroropen}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setErrorOpen(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {errorMessage}
                              </Alert>
                            </Collapse>
                          </div>
                        </div>

                        <div className="form-group row ">
                          <label className="col-sm-5 offset-sm-1">
                            <button
                              disabled={loading}
                              // disabled={!isValid || !dirty}
                              type="submit"
                              className="btn btn-md btn-success"
                            >
                              {loading && (
                                <i className="fa fa-refresh fa-spin"></i>
                              )}
                              {loading && <span>Updating</span>}
                              {!loading && <span> Update</span>}
                            </button>
                          </label>
                          <div className="col-sm-7"></div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
