import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { TextField } from "formik-material-ui";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import _, { isArray } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function ProductForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [pArr, setPArr] = useState([]);
  const paramID = parseInt(id);

  const [path, setPath] = useState("");
  const [editing, setEditing] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [productVariant, setProductVariants] = React.useState([]);

  const [loadingReports, setLoadingReports] = React.useState(false);
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [catID, setCatID] = React.useState("");
  const [subCatID, setSubCatID] = React.useState("");
  const [catValue, setCatValue] = React.useState("");
  const [subCatValue, setSubCatValue] = React.useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  // const [params, setParams] = {};
  const [serverErrors, setServerErrors] = useState({});
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const params = {
    page: 1,
    per_page: 15,
  };

  useEffect(() => {
    getCategoryList();
  }, [productSearchTerm]);

  const getCategoryList = () => {
    AdminAPI.get(`categories?search=${productSearchTerm}`, { params: params })
      .then((response) => {
        setCategories(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureProduct = _.debounce((event) => {
    setProductSearchTerm(event.target.value);
  }, 500);

  useEffect(() => {
    if (id !== undefined) {
      getProductDetails();
    }
  }, []);
  useEffect(() => {}, []);

  const goto = () => {
    setOpenDialog(false);
    history.push("/admin/products");
  };

  const getProductDetails = () => {
    AdminAPI.get(`products/${id}`)
      .then((response) => {
        setEditing(true);
        handleServerProductPhotos(
          response.data.data.product_variants,
          response.data.data
        );
        if (response.data.data.product_variants.length > 0) {
        }
        setProduct(response.data.data);
        setCatID(response.data.data.subcategory.category_id);
        setCatValue(response.data.data.subcategory.category.name);
        setSubCatValue(response.data.data.subcategory.name);
        setSubCatID(response.data.data.subcategory_id);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    console.log("proudct updated", product);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    getSubCategoryList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catID]);
  const getSubCategoryList = () => {
    AdminAPI.get(`categories/${catID}`)
      .then((response) => {
        console.log("aaa", response.data.data.subcategories);
        let subArr = response.data.data.subcategories;
        if (isArray(subArr) && subArr.length > 0) {
          setSubCategories(subArr);
        } else {
          setSubCategories([]);
        }

        // if (id !== undefined) {
        //   let cat = response.data.data.data;
        //   if (cat.length > 0) {
        //     let catName = cat.filter((o) => o.id === parseInt(catID));
        //     if (catName.length > 0) {
        //       setCatValue(catName[0].name);
        //     }
        //   }
        // }

        // setMedicineList(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const removeImageIndex = (id) => {
    let copyArray = [...previews];
    console.log("id", id);
    console.log("arrrrrr", copyArray);
    let hello = copyArray.filter((o) => o.photoIndex !== id);
    console.log("hello", hello);

    setPreviews(hello);
  };
  const removeImage = (id) => {
    let copyArray = [...previews];
    console.log("arrrrrr", copyArray);
    let hello = copyArray.filter((o) => o.id !== id);

    setPreviews(hello);
  };
  const removeImageServer = (id) => {
    let copyArrayFiles = [...files];
    let helloFiles = copyArrayFiles.filter((o) => o.id !== id);

    setFiles(helloFiles);
  };

  const uploadMultipleFiles = (e, index, prev) => {
    console.log("preeeev", prev);
    // let pRR = []

    const fileList = Array.from(e.target.files);

    let someIDtoAssign;

    if (checkMimeType(e)) {
      // setFiles(fileList);
      // for (let i = 0; i < fileList.length; i++) {
      //   someIDtoAssign = _.uniqueId("gfg_");
      //   console.log("fileeeeeeeeeeeeeeee=> idddd", someIDtoAssign);
      // }

      const mappedFilesforIndexId = fileList.map((file) => {
        let obj = file;
        obj.id = _.uniqueId("gfg_");
        obj.photoIndex = index;
        return obj;

        // return {
        //   originalName: file.name,
        //   mimetype: file.type,
        //   preview: URL.createObjectURL(file),
        // };
      });

      const mappedFiles = mappedFilesforIndexId.map((file) => {
        return {
          originalName: file.name,
          mimetype: file.type,
          id: file.id,
          photoIndex: file.photoIndex,
          preview: URL.createObjectURL(file),
        };
        // let obj = file;
        // obj.originalName = file.name;
        // obj.preview = URL.createObjectURL(file);
        // return obj;
      });
      // filesarr.push(mappedFilesforIndexId);

      let files = [...prev, ...mappedFiles];
      console.log("filessssss for formik", files);
      // setFiles([...files, ...mappedFilesforIndexId]);
      // setPreviews([...previews, ...mappedFiles]);
      return files;
    }
  };

  console.log("preeeeeeeeeeeeeeee", previews);

  const showImages = (index) => {
    console.log("index", index);
    let filteredArray = [...previews];
    filteredArray.filter((x) => x.id === index);
    console.log("fileted array", filteredArray);
  };

  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
    subcategory_id: Yup.string().required("This field is required"),
    product_variations: Yup.array().of(
      Yup.object().shape({
        unit: Yup.string().required("Required"),
        price: Yup.number().required("Required").typeError("Required"),
        discount: Yup.number().required("Required").typeError("Required"),
        product_photos: Yup.array().min(1, "Product Photo is required"),
      })
    ),
  });
  const initialValues = {
    name: "" || product.name,
    description: "" || product.description,
    subcategory_id: "" || product.subcategory_id,
    product_variations: [
      {
        unit: "",
        price: "",
        discount: "",
        product_photos: [],
      },
    ],
  };
  const initialValuesEdit = {
    name: "" || product.name,
    description: "" || product.description,
    subcategory_id: "" || product.subcategory_id,

    product_variations: productVariant,
  };

  const getFile = async (paramObj) => {
    const { preview, originalName, mimetype } = paramObj;
    const blob = await fetch(preview).then((r) => r.blob());
    const newfileObj = new File([blob], `${originalName}`, { type: mimetype });

    return newfileObj;
  };

  const getBlobFromFile = async (paramObj, index, pro) => {
    const { product_variant_id, photo } = paramObj;
    let fileExt = photo.split(".").pop();

    const response = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/files/product/${pro.id}/${product_variant_id}/${photo}`,

        {
          responseType: "blob",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        var objectURL = URL.createObjectURL(response.data);
        var obj = {
          originalName: photo,
          photoIndex: index,
          preview: objectURL,
          id: _.uniqueId("gfg_"),
          mimetype: `image/${fileExt}`,
        };

        return obj;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
  const handleServerProductPhotos = async (arr, pro) => {
    let formatted = arr.map((o, i) => {
      let obj;
      obj = {
        unit: o.unit,
        price: o.price,
        discount: o.discount,
        product_photos: [],
      };
      return obj;
    });
    console.log("formatedd====>", formatted);
    // setProductVariants(formatted);
    let pR = [...arr];

    let previewFiles = [];
    for (let i = 0; i < pR.length; i++) {
      let obj = pR[i];
      for (let j = 0; j < obj.product_photos.length; j++) {
        let obj2 = obj.product_photos[j];
        let previewFile = await getBlobFromFile(obj2, i, pro);
        previewFiles.push(previewFile);
      }
    }
    // previewFiles.filter((item) => item);
    let fineArr = _.compact(previewFiles);
    let game = formatted.map((arr, index) => {
      let obj = arr;
      obj.product_photos = fineArr.filter((o) => o.photoIndex === index);

      return obj;
    });
    console.log("outputttttttttttttttt===> game", _.compact(game));
    setProductVariants(game);
    // setPreviews(fineArr);
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log("value of form", values);
    // console.log("previews", previews);
    let comArry = [...values.product_variations];

    // // let game = comArry.map((arr, index) => {
    // //   let obj = arr;
    // //   obj.product_photos = files.filter((o) => o.photoIndex === index);
    // //   return obj;
    // // });

    // console.log("game array array", game);
    // // console.log("game array valuesss", values);
    let pvP = comArry.map((o) => {
      let obj = o;
      return obj.price;
    });
    let pvU = comArry.map((o) => {
      let obj = o;
      return obj.unit;
    });
    let pvD = comArry.map((o) => {
      let obj = o;
      return obj.discount;
    });
    // // const mappedImages = await Promise.all(
    // //   comArry.map((o) => {
    // //     let obj = o;
    // //     return obj.product_photos.map(async (p) => {
    // //       let obj2 = p;
    // //       let someFile = await getFile(obj2);
    // //       console.log("here it comes", someFile);
    // //     });
    // //   })
    // // );

    let pvI = comArry.map((o) => {
      let obj = o;
      const response = obj.product_photos.map(async (p) => {
        let obj2 = p;
        let someFile = await getFile(obj2);

        return someFile;
      });
      return response;
    });
    let outputArr = [];
    for (let i = 0; i < pvI.length; i++) {
      const arr = await pvI[i];

      let test = [];
      for (let j = 0; j < arr.length; j++) {
        const obj = await arr[j];
        test.push(obj);
      }
      outputArr.push(test);
    }
    console.log("outputttttttttttttttt===>", outputArr);

    // // console.log("mappedImages", mappedImages);

    let formData = new FormData();

    // // data.append("reports", files);

    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("subcategory_id", values.subcategory_id);

    for (var x = 0; x < pvU.length; x++) {
      formData.append(`product_variants[${x}][unit]`, pvU[x]);
    }
    for (var y = 0; y < pvP.length; y++) {
      formData.append(`product_variants[${y}][price]`, pvP[y]);
    }
    for (var z = 0; z < pvD.length; z++) {
      formData.append(`product_variants[${z}][discount]`, pvD[z]);
    }

    // // for (var w = 0; w < outputArr[0].length; w++) {
    // //   formData.append(
    // //     `product_variants[${w}][product_photos]`,
    // //     outputArr[0][w]
    // //   );
    // // }
    for (var w = 0; w < outputArr.length; w++) {
      let singleobj = outputArr[w];

      for (var b = 0; b < singleobj.length; b++) {
        formData.append(
          `product_variants[${w}][product_photos][${b}]`,
          singleobj[b]
        );
      }

      // formData.append(`product_variants[${w}][product_photos]`, outputArr[w]);
    }

    for (var value of formData.values()) {
      console.log("value of formadata", value);
    }
    if (id !== undefined) {
      AdminAPI.post(`product/${id}`, formData)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
          // setShowEndButton(true);
          // history.push("/doctor");
        })
        .catch((error) => {
          console.log("errrro====>", error.response);
          setServerErrors(error.response.data.errors);
        });
    } else {
      AdminAPI.post(`products`, formData)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
          // setShowEndButton(true);
          // history.push("/doctor");
        })
        .catch((error) => {
          console.log("errrro====>", error.response.data.errors);

          setServerErrors(error.response.data.errors);
          // alert(error.response.data.message);
        });
    }

    // const formData = new FormData();
    // let serialNumber = 0;

    // for (let i = 0; i < game.length; i++) {
    //   const singleGame = game[i];

    //   for (let j = 0; j < singleGame.product_photos.length; j++) {
    //     const photo = singleGame.product_photos[j];
    //     const file = await getFile(photo);
    //     console.log("photot", photo);

    //     formData.append(`${serialNumber}`, file);
    //     photo.serialNumber = `${serialNumber}`;
    //     serialNumber++;

    //     console.log("file fro mthe array", file);
    //   }
    // }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={`${isMobile ? "sm" : "md"}`}
        open={openPhotoModal}
        onClose={() => setOpenPhotoModal(false)}
      >
        <DialogTitle id="max-width-dialog-title">Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              width="100%"
              // src={
              //   `${process.env.REACT_APP_PUBLIC_URL}` + path + "/" + selectedImg
              // }
              alt="..."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPhotoModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    initialValues={!editing ? initialValues : initialValuesEdit}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Name</label>
                                  <div className="col-md-8">
                                    <Field
                                      name={`name`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`name`}
                                      component="div"
                                      className="error-message"
                                    />
                                    {!_.isEmpty(serverErrors) &&
                                      serverErrors.name !== undefined && (
                                        <span className="error-msg">
                                          {" "}
                                          {serverErrors.name}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">
                                    Description
                                  </label>
                                  <div className="col-md-8">
                                    <Field
                                      component="textarea"
                                      name={`description`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`description`}
                                      component="div"
                                      className="error-message"
                                    />
                                    {!_.isEmpty(serverErrors) &&
                                      serverErrors.description !==
                                        undefined && (
                                        <span className="error-msg">
                                          {" "}
                                          {serverErrors.description}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="obeservation">
                              <div className="form-group row prescription  ">
                                <label className="col-md-3 ">Category</label>
                                <div className="col-md-8">
                                  <Autocomplete
                                    id="disabledHelperText"
                                    getOptionLabel={(option) =>
                                      option.name || catValue
                                    }
                                    getOptionSelected={(option, value) => {
                                      return option.name === value;
                                    }}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCaptureProduct(event);
                                    }}
                                    value={catValue}
                                    options={categories}
                                    onChange={(event, value) => {
                                      console.log("test value", value);

                                      if (value == null) {
                                        console.log("null");
                                        setCatID("");
                                        setCatValue("");
                                        setSubCatValue("");
                                        setFieldValue(`subcategory_id`, "");
                                      } else {
                                        setFieldValue(`category_id`, value.id);
                                        setCatID(value.id);
                                        setCatValue(value.name);
                                        console.log(
                                          value.id,
                                          values.category_id
                                        );
                                        setSubCatValue("");
                                        setFieldValue(`subcategory_id`, "");
                                      }

                                      console.log(values);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        name={`category_id`}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name={`category_id`}
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.category_id !== undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.category_id}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="form-group row prescription  ">
                                <label className="col-md-3 ">SubCategory</label>
                                <div className="col-md-8">
                                  <Autocomplete
                                    disabled={catID === ""}
                                    id="disabledHelperText"
                                    getOptionLabel={(option) =>
                                      option.name || subCatValue
                                    }
                                    value={subCatValue}
                                    options={subCategories}
                                    onInputCapture={(event) => {
                                      event.persist(); // As we are accessing the event in asynchronous way.
                                      handleOnInputCaptureProduct(event);
                                    }}
                                    onChange={(event, value) => {
                                      console.log("test value", value);

                                      if (value == null) {
                                        console.log("null");
                                        setSubCatValue("");
                                        setFieldValue(`subcategory_id`, "");
                                      } else {
                                        setFieldValue(
                                          `subcategory_id`,
                                          value.id
                                        );
                                        setSubCatID(value.id);
                                        setSubCatValue(value.name);
                                      }
                                      console.log(values);
                                    }}
                                    renderInput={(params) => (
                                      <Field
                                        component={TextField}
                                        {...params}
                                        name={`subcategory_id`}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name={`subcategory_id`}
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.subcategory_id !==
                                      undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.subcategory_id}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <div className="areax">
                              <div>
                                <FieldArray
                                  name="product_variations"
                                  render={(arrayHelpers) => (
                                    <div>
                                      {values.product_variations.length > 0 &&
                                        values.product_variations.map(
                                          (friend, index) => (
                                            <div className="row" key={index}>
                                              <div className="col-12">
                                                <div className="row">
                                                  <div className="col-3">
                                                    <label
                                                      htmlFor={`product_variations.${index}.unit`}
                                                    >
                                                      Unit
                                                    </label>
                                                  </div>
                                                  <div className="col-8">
                                                    <Field
                                                      name={`product_variations.${index}.unit`}
                                                      placeholder="Input product unit"
                                                      type="text"
                                                      className="form-control"
                                                    />
                                                    <ErrorMessage
                                                      component="div"
                                                      name={`product_variations.${index}.unit`}
                                                      className="error-message"
                                                    />
                                                    {!_.isEmpty(serverErrors) &&
                                                      _.get(
                                                        serverErrors,
                                                        `product_variants.${index}.unit`
                                                      ) !== undefined && (
                                                        <span className="error-msg">
                                                          {_.get(
                                                            serverErrors,
                                                            `product_variants.${index}.unit`
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-3">
                                                    <label
                                                      htmlFor={`product_variations.${index}.price`}
                                                    >
                                                      Price
                                                    </label>
                                                  </div>
                                                  <div className="col-8">
                                                    <Field
                                                      name={`product_variations.${index}.price`}
                                                      placeholder="Input product price"
                                                      type="text"
                                                      className="form-control"
                                                    />
                                                    <ErrorMessage
                                                      component="div"
                                                      className="error-message"
                                                      name={`product_variations.${index}.price`}
                                                    />
                                                    {!_.isEmpty(serverErrors) &&
                                                      _.get(
                                                        serverErrors,
                                                        `product_variants.${index}.price`
                                                      ) !== undefined && (
                                                        <span className="error-msg">
                                                          {_.get(
                                                            serverErrors,
                                                            `product_variants.${index}.price`
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-3">
                                                    <label
                                                      htmlFor={`product_variations.${index}.discount`}
                                                    >
                                                      Discount
                                                    </label>
                                                  </div>
                                                  <div className="col-8">
                                                    <Field
                                                      name={`product_variations.${index}.discount`}
                                                      placeholder="Input product discount"
                                                      type="text"
                                                      className="form-control"
                                                    />
                                                    <ErrorMessage
                                                      component="div"
                                                      className="error-message"
                                                      name={`product_variations.${index}.discount`}
                                                    />
                                                    {!_.isEmpty(serverErrors) &&
                                                      _.get(
                                                        serverErrors,
                                                        `product_variants.${index}.discount`
                                                      ) !== undefined && (
                                                        <span className="error-msg">
                                                          {_.get(
                                                            serverErrors,
                                                            `product_variants.${index}.discount`
                                                          )}
                                                        </span>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-3">
                                                    <label
                                                      htmlFor={`product_variations.${index}.product_photos`}
                                                    >
                                                      Photos
                                                    </label>
                                                  </div>
                                                  <div className="col-8">
                                                    <input
                                                      id={`product_variations.${index}.product_photos`}
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      multiple={true}
                                                      type="file"
                                                      name={
                                                        `product_variations.${index}.product_photos` ||
                                                        []
                                                      }
                                                      onChange={(event) => {
                                                        let fileof =
                                                          uploadMultipleFiles(
                                                            event,
                                                            index,
                                                            values
                                                              .product_variations[
                                                              index
                                                            ].product_photos
                                                          );
                                                        console.log(
                                                          "file Of previewsss formik",
                                                          previews
                                                        );
                                                        setFieldValue(
                                                          `product_variations.${index}.product_photos`,
                                                          fileof
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      htmlFor={`product_variations.${index}.product_photos`}
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                        name={`product_variations.${index}.product_photos`}
                                                      >
                                                        Upload
                                                      </Button>
                                                    </label>
                                                    <ErrorMessage
                                                      component="div"
                                                      className="error-message"
                                                      name={`product_variations.${index}.product_photos`}
                                                    />

                                                    {values.product_variations[
                                                      index
                                                    ].product_photos !==
                                                      undefined &&
                                                      values.product_variations[
                                                        index
                                                      ].product_photos.length >
                                                        0 && (
                                                        <div className="form-group multi-preview">
                                                          {(
                                                            values
                                                              .product_variations[
                                                              index
                                                            ].product_photos ||
                                                            []
                                                          ).map((url, key) => (
                                                            <div
                                                              key={key}
                                                              className="container_img"
                                                            >
                                                              <img
                                                                src={
                                                                  url.preview
                                                                }
                                                                alt="..."
                                                              />
                                                              <div className="topright">
                                                                <button
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();
                                                                    let arrOf =
                                                                      values
                                                                        .product_variations[
                                                                        index
                                                                      ]
                                                                        .product_photos;
                                                                    let fileter =
                                                                      arrOf.filter(
                                                                        (o) =>
                                                                          o.id !==
                                                                          url.id
                                                                      );
                                                                    console.log(
                                                                      "pres arr",
                                                                      arrOf
                                                                    );
                                                                    console.log(
                                                                      "filter arr",
                                                                      fileter
                                                                    );
                                                                    setFieldValue(
                                                                      `product_variations.${index}.product_photos`,
                                                                      fileter
                                                                    );
                                                                    // removeImage(
                                                                    //   url.id
                                                                    // );
                                                                    // removeImageServer(
                                                                    //   url.id
                                                                    // );
                                                                  }}
                                                                  className="btn btn-sm"
                                                                >
                                                                  <HighlightOffIcon color="secondary" />
                                                                </button>
                                                              </div>
                                                            </div>
                                                          ))}
                                                          {/* {showImages(index)} */}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                                {values.product_variations
                                                  .length > 1 && (
                                                  <div className="row my-2">
                                                    <div className="col-md-11 text-right">
                                                      {}
                                                      <Button
                                                        onClick={() => {
                                                          // removeImageIndex(
                                                          //   index
                                                          // );
                                                          arrayHelpers.remove(
                                                            index
                                                          );
                                                        }}
                                                        variant="contained"
                                                        color="secondary"
                                                      >
                                                        Remove
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      <div className="row pt-3">
                                        <div className="mr-auto">
                                          <Button
                                            onClick={() =>
                                              arrayHelpers.push({
                                                unit: "",
                                                price: "",
                                                discount: "",
                                                product_photos: [],
                                              })
                                            }
                                            variant="contained"
                                            color="primary"
                                          >
                                            Add
                                          </Button>
                                        </div>
                                        <div className="ml-auto">
                                          <button
                                            // disabled={
                                            //   medData.length === 0 &&
                                            //   observationData.length === 0 &&
                                            //   testData.length === 0 &&
                                            //   files.length === 0
                                            // }
                                            className="btn btn-lg btn-primary"
                                            type="submit"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully added your product
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goto} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
