import React from "react";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import ListSubheader from "@material-ui/core/ListSubheader";
import adminAuth from "../../routingRules/adminAuth";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import CreateIcon from "@material-ui/icons/Create";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AvTimerIcon from "@material-ui/icons/AvTimer";
// import BlockIcon from "@material-ui/icons/Block";
// import AssignmentIcon from "@material-ui/icons/Assignment";
// import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PersonIcon from "@material-ui/icons/Person";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import OpacityIcon from "@material-ui/icons/Opacity";
import SchoolIcon from "@material-ui/icons/School";

import Icon from "@material-ui/core/Icon";
import ResetPassword from "./reset-password";
import UserProfile from "./profile";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";

import StarBorder from "@material-ui/icons/StarBorder";

// import ScheduleIcon from "@material-ui/icons/Schedule";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  // },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
    },
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "4rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    list: {
      width: 250,
    },
    links: {
      textDecoration: "none",
      color: "#000",
    },
    menuHeader: {
      paddingLeft: "5px",
    },
    menuList: {
      fontSize: "15px",
      color: "#333333",
      textTransform: "capitalize",
      lineHeight: "18px",
      borderBottom: "1px solid #fff",
      backgroundColor: "#f8f8f8",
    },
  },
}));
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "} */}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function UserDashboard({ children }) {
  let history = useHistory();
  let match = useRouteMatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openInvoice, setOpenInvoice] = React.useState(false);
  const [openProduct, setOpenProduct] = React.useState(false);

  const handleInvoiceExpand = () => {
    setOpenInvoice(!openInvoice);
  };
  const handleProductExpand = () => {
    setOpenProduct(!openProduct);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const profile = () => {
    history.push("/user/profile");
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const logOut = () => {
    adminAuth.logout(() => {
      history.push("/");
    });
  };
  const resetPassword = () => {
    history.push("/user/reset-password");
  };

  return (
    <div className={""}>
      <div className="row">
        <div className="col-md-3">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            <ListItem
              className={classes.menuList}
              button
              component={Link}
              to={`/customers/account`}
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="DashBoard" />
            </ListItem>
            <ListItem className={classes.menuList} button>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItem>
            <ListItem className={classes.menuList} button>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Address" />
            </ListItem>
            <ListItem className={classes.menuList} button>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Account Details" />
            </ListItem>
            {/* <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Inbox" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItem>
              </List>
            </Collapse> */}
          </List>
        </div>
        <div className="col-md-9">{children}</div>
      </div>
    </div>
  );
}
