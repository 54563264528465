import React, { useState, useEffect } from "react";

import SliderBanner from "../../components/SliderBanner";
import ProductSlider from "../../components/ProductSlider";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import ProductItems from "../../components/ProductItems";
import ProductsGrids from "../../components/ProductGrids";
import axios from "axios";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScrollAnimation from "react-animate-on-scroll";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link, useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import SliderBanner2 from "../../components/SliderBanner2";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  accor: {
    backgroundColor: "#7fd02c",
    color: "#fff",
  },
  accorPaper: {
    margin: "16px 10px!important",
  },
}));

export default function MainPage() {
  const classes = useStyles();
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const [isClose, setIsClose] = React.useState({});
  const [vacationDetail, setVacationDetail] = React.useState({});
  const [texts, setTexts] = useState([]);
  const [products, setProducts] = useState([]);
  const [productPath, setProductPath] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryPath, setCategoryPath] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [slide, setSlide] = useState(2);
  const [openBanner, setOpenBanner] = React.useState(false);
  const [stop, setStop] = useState(true);
  const [count, setCount] = useState(1);
  const [countM, setCountM] = useState(1);
  const [countS, setCountS] = useState(1);
  const [countXS, setCountXS] = useState(1);

  const handleClickOpenBanner = () => {
    setOpenBanner(true);
  };

  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  useEffect(() => {
    getcategories();
    getProducts();
    getBlogs();
    getTexts();
    isClosed();
  }, []);

  const isClosed = () => {
    axios
      .get("is-closed")
      .then((response) => {
        setVacationDetail(response.data.data[3]);
        setIsClose(response.data.data[2]);
        response.data.data[2]["value"] === "1"
          ? setOpenBanner(true)
          : setOpenBanner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTexts = () => {
    axios
      .get(`banners?type=text`)
      .then((response) => {
        setTexts(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getcategories = () => {
    axios
      .get(`categories`)
      .then((response) => {
        setCategories(response.data.data.data);
        let data = response.data.data.data;
        console.log("data length===>", data.length);
        let dataC = data.length;
        if (dataC === 1) {
          setCount(1);
          setCountM(1);
          setCountS(1);
          setCountXS(1);
        } else if (dataC === 2) {
          setCount(2);
          setCountM(2);
          setCountS(2);
          setCountXS(2);
        } else if (dataC === 3) {
          setCount(3);
          setCountM(3);
          setCountS(2);
          setCountXS(2);
        } else if (dataC >= 4) {
          setCount(4);
          setCountM(3);
          setCountS(2);
          setCountXS(2);
        }
        setCategoryPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getProducts = () => {
    axios
      .get(`products`)
      .then((response) => {
        setProducts(response.data.data.data);
        setProductPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getBlogs = () => {
    axios
      .get(`blogs`)
      .then((response) => {
        setBlogs(response.data.data.data);
        if (response.data.data.data.length >= 3) {
          setSlide(3);
        } else {
          setSlide(response.data.data.data.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const blogSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slide,
    slidesToScroll: slide,

    centerMode: true,

    centerPadding: "60px",

    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "25px",
        },
      },
    ],
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: count,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: count,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: countM,
          slidesToScroll: countM,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: countS,
          slidesToScroll: countS,
          initialSlide: countS,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: countXS,
          slidesToScroll: countXS,
        },
      },
    ],
  };
  return (
    <div className="mt-5">
      {/* <SliderBanner /> */}
      <SliderBanner2 />
      <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOutRight">
        <div className="container-fluid pt-5">
          <div className="service-features-inner">
            {texts.map((text, index) => (
              <div className="single-features-item mb-1">
                <div className="features-icon">
                  <i className="ion-paper-airplane"></i>
                </div>
                <div className="features-content">
                  <ScrollAnimation animateIn="fadeIn">
                    <h5>{text.heading}</h5>
                    <p>{text.detail}</p>
                  </ScrollAnimation>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollAnimation>
      <section className="popular-category bg-gray mt-minus pt-60 pb-120 pb-md-30 pb-sm-30">
        <ScrollAnimation animateIn="fadeIn">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center pt-62">
                  <h2>Popular Categories</h2>
                </div>
              </div>
            </div>

            <Slider {...settings}>
              {categories.map((category, index) => (
                <div onClick={() => history.push(`/${category.url}`)}>
                  <div className="">
                    <div className="category-single-item mb-md-30 mb-sm-30">
                      <div className="category-item-inner color5 text-center">
                        <div className="category-content">
                          <h2 className="color-black">
                            <Link to={category.url}> {category.name}</Link>
                          </h2>
                          {/* <p>
                            {category.subcategory_count !== null
                              ? category.subcategory_count.number
                              : 0}{" "}
                            subcategories
                          </p> */}
                        </div>
                        <div className="category-thumb">
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}/${categoryPath}/${category.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            {/* {categories.map((category) => (
               
              ))} */}
          </div>
        </ScrollAnimation>
      </section>

      <section className="popular-category bg-white mt-minus pt-60 pb-60 pb-md-30 pb-sm-30">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center pt-62">
                <h2>Popular Products</h2>
              </div>
            </div>
          </div>
          {/* <ScrollAnimation
            animateIn="zoomIn"
            animateOut="backOutRight"
            duration={5}
          > */}
          <div className={classes.root}>
            <div className={classes.demo1}>
              {/* <ProductsGrids
                products={products}
                path={productPath}
                stop={stop}
              /> */}
              <ProductSlider products={products} path={productPath} />
            </div>
          </div>
          {/* </ScrollAnimation> */}
        </div>
      </section>

      <section className="bg-gray pt-60 pb-60">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center">
                  <h2>why choose us</h2>
                </div>
              </div>
            </div>
            <div className="accort_padd">
              <Accordion
                classes={{
                  root: classes.accorPaper,
                  expanded: classes.accorPaper,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.accor,
                  }}
                >
                  <Typography className={classes.heading}>
                    100% Fresh Organic Food
                  </Typography>
                </AccordionSummary>
                {/* <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails> */}
              </Accordion>
              <Accordion
                classes={{
                  root: classes.accorPaper,
                  expanded: classes.accorPaper,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  classes={{
                    root: classes.accor,
                  }}
                >
                  <Typography className={classes.heading}>
                    Fast Delivery
                  </Typography>
                </AccordionSummary>
                {/* <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails> */}
              </Accordion>
              <Accordion
                classes={{
                  root: classes.accorPaper,
                  expanded: classes.accorPaper,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  classes={{
                    root: classes.accor,
                  }}
                >
                  <Typography className={classes.heading}>
                    Rich Experience
                  </Typography>
                </AccordionSummary>
                {/* <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails> */}
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white pt-60 pb-60">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <h2>from the blogs</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <Slider {...blogSetting}>
            {blogs.map((blog, index) => (
              <div className="slide-card">
                <div style={{ minHeight: "100px" }} className="card blog-card">
                  <img
                    style={{ height: "140px", borderRadius: "15%" }}
                    className="card-img-top-blog"
                    alt="ablog post"
                    src={`${process.env.REACT_APP_PUBLIC_URL}/files/blogs/${blog.cover}`}
                  />
                  <div className="card-body">
                    <h5
                      onClick={() => history.push(`/blog-details/${blog.id}`)}
                      style={{ cursor: "pointer" }}
                      className="card-title text-uppercase"
                    >
                      {blog.title.length > 30
                        ? blog.title.slice(0, 30) + "..."
                        : blog.title}
                      {/* {blog.title} */}
                    </h5>
                    <p className="card-text">
                      {blog.description.length > 70
                        ? blog.description.slice(0, 70) + "..."
                        : blog.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <Dialog
        open={openBanner}
        onClose={handleCloseBanner}
        aria-labelledby="form-dialog-title"
      >
        {vacationDetail !== undefined && (
          <DialogTitle id="form-dialog-title">
            {vacationDetail["name"]}
          </DialogTitle>
        )}

        <DialogContent>
          {vacationDetail !== undefined && (
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/files/preferences/${vacationDetail["value"]}`}
              alt="offer"
              width="100%"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBanner} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
