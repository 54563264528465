import React, { useContext } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  TrashIcon,
} from "../../components/icons";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { CartContext } from "../../contexts/CartContext";
import { makeStyles } from "@material-ui/core/styles";
import { formatNumber } from "../../helpers/utils";
import { Icon } from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
    border: "none",
    color: "#000",
    "&:hover": {
      // color: "#40a9ff",
      border: "none",
    },
  },
  disabled: {
    border: "none!important",
  },
  outlinedSizeSmall: {
    padding: "0px 9px",
  },
}));

const CartItemDetails = ({ product }) => {
  const classes = useStyles();
  const { increase, decrease, removeProduct } = useContext(CartContext);

  return (
    <div className="cart-list-itms">
      {/* <table class="table table-sm table-borderless">
        <tbody>
          <tr className="cart-row cart-row-items">
           
          
            
           
            <td>
              {" "}
              <Button
                size="small"
                onClick={() => removeProduct(product, product.selectedVariant)}
                classes={{ root: classes.root }}
              >
                {" "}
                <Icon size="small">close</Icon>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="cart-table table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="pro-thumbnail">Thumbnail</th>
                    <th class="pro-title">Product</th>
                    <th class="pro-price">Price</th>
                    <th class="pro-quantity">Quantity</th>
                    <th class="pro-subtotal">Total</th>
                    <th class="pro-remove">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pro-thumbnail">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/img/product/product-1.jpg"
                          alt="Product"
                        />
                      </a>
                    </td>
                    <td class="pro-title">
                      <a href="#">Endeavor Daytrip</a>
                    </td>
                    <td class="pro-price">
                      <span>$295.00</span>
                    </td>
                    <td class="pro-quantity">
                      <div class="pro-qty">
                        <span class="dec qtybtn">-</span>
                        <input type="text" value="1" />
                        <span class="inc qtybtn">+</span>
                      </div>
                    </td>
                    <td class="pro-subtotal">
                      <span>$295.00</span>
                    </td>
                    <td class="pro-remove">
                      <a href="#">
                        <i class="fa fa-trash-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="pro-thumbnail">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/img/product/product-2.jpg"
                          alt="Product"
                        />
                      </a>
                    </td>
                    <td class="pro-title">
                      <a href="#">Joust Duffle Bags</a>
                    </td>
                    <td class="pro-price">
                      <span>$275.00</span>
                    </td>
                    <td class="pro-quantity">
                      <div class="pro-qty">
                        <span class="dec qtybtn">-</span>
                        <input type="text" value="2" />
                        <span class="inc qtybtn">+</span>
                      </div>
                    </td>
                    <td class="pro-subtotal">
                      <span>$550.00</span>
                    </td>
                    <td class="pro-remove">
                      <a href="#">
                        <i class="fa fa-trash-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="pro-thumbnail">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/img/product/product-3.jpg"
                          alt="Product"
                        />
                      </a>
                    </td>
                    <td class="pro-title">
                      <a href="#">Compete Track Totes</a>
                    </td>
                    <td class="pro-price">
                      <span>$295.00</span>
                    </td>
                    <td class="pro-quantity">
                      <div class="pro-qty">
                        <span class="dec qtybtn">-</span>
                        <input type="text" value="1" />
                        <span class="inc qtybtn">+</span>
                      </div>
                    </td>
                    <td class="pro-subtotal">
                      <span>$295.00</span>
                    </td>
                    <td class="pro-remove">
                      <a href="#">
                        <i class="fa fa-trash-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="pro-thumbnail">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/img/product/product-4.jpg"
                          alt="Product"
                        />
                      </a>
                    </td>
                    <td class="pro-title">
                      <a href="#">Bess Yoga Shorts</a>
                    </td>
                    <td class="pro-price">
                      <span>$110.00</span>
                    </td>
                    <td class="pro-quantity">
                      <div class="pro-qty">
                        <span class="dec qtybtn">-</span>
                        <input type="text" value="3" />
                        <span class="inc qtybtn">+</span>
                      </div>
                    </td>
                    <td class="pro-subtotal">
                      <span>$110.00</span>
                    </td>
                    <td class="pro-remove">
                      <a href="#">
                        <i class="fa fa-trash-o"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="cart-update-option d-block d-md-flex justify-content-between">
              <div class="apply-coupon-wrapper">
                <form action="#" method="post" class=" d-block d-md-flex">
                  <input
                    type="text"
                    placeholder="Enter Your Coupon Code"
                    required=""
                  />
                  <button class="btn btn__bg btn__sqr">Apply Coupon</button>
                </form>
              </div>
              <div class="cart-update mt-sm-16">
                <a href="#" class="btn btn__bg btn__sqr">
                  Update Cart
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    */}

      <tr>
        <td>
          <img
            alt={product.title}
            style={{ margin: "0 auto" }}
            src={`${process.env.REACT_APP_PUBLIC_URL}/files/product/${product.id}/${product.selectedVariant.id}/${product.selectedVariant.product_photos[0].photo}`}
            className=""
          />
        </td>
        <td>
          {" "}
          <h5 className="mb-1">{product.name}</h5>
        </td>
        <td>
          {" "}
          <p className="mb-1">
            {formatNumber(
              (product.selectedVariant.price -
                (product.selectedVariant.discount / 100) *
                  product.selectedVariant.price) *
                product.quantity
            )}
            Tk
          </p>
        </td>
        <td>
          {" "}
          <ButtonGroup
            orientation="vertical"
            color="secondary"
            aria-label="vertical outlined primary button group"
          >
            <Button
              size="small"
              onClick={() => increase(product, product.selectedVariant)}
              classes={{
                root: classes.root,
                outlinedSizeSmall: classes.outlinedSizeSmall,
              }}
            >
              <Icon size="small">expand_less</Icon>
            </Button>
            <Button
              size="small"
              classes={{
                root: classes.root,
                outlinedSizeSmall: classes.outlinedSizeSmall,
              }}
              style={{ pointerEvents: "none" }}
            >
              {product.quantity}
            </Button>
            <Button
              size="small"
              disabled={product.quantity === 1}
              onClick={() => decrease(product, product.selectedVariant)}
              classes={{
                root: classes.root,
                disabled: classes.disabled,
                outlinedSizeSmall: classes.outlinedSizeSmall,
              }}
            >
              {" "}
              <Icon size="small">expand_more</Icon>
            </Button>
          </ButtonGroup>
        </td>
        <td class="pro-subtotal">
          <span>$550.00</span>
        </td>
        <td class="pro-remove">
          <a href="#">
            <i class="fa fa-trash-o"></i>
          </a>
        </td>
      </tr>
    </div>
  );
};

export default CartItemDetails;
