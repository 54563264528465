import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { TextField } from "formik-material-ui";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function BlogForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();

  const [path, setPath] = useState("");
  const [editing, setEditing] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [blog, setBlog] = React.useState({});
  const [blogAttachments, setBlogAttachments] = React.useState([]);

  const [loadingReports, setLoadingReports] = React.useState(false);
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [catID, setCatID] = React.useState("");
  const [serverErrors, setServerErrors] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [files, setFiles] = useState([]);
  const [fileCover, setFileCover] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [previewsCover, setPreviewsCover] = useState([]);

  console.log("previews cover", previewsCover);
  // const [params, setParams] = {};

  useEffect(() => {
    if (id !== undefined) {
      getBlogDetails();
    }
  }, []);
  useEffect(() => {}, []);

  const deleteModal = () => {
    setOpenDeleteDialog(true);
  };

  const deleteBlog = () => {
    AdminAPI.post(`blogs/${id}`)
      .then((response) => {
        setOpenDialog(false);
        history.push("/admin/blogs/list");
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  const getBlogDetails = () => {
    AdminAPI.get(`blogs/${id}`)
      .then((response) => {
        setEditing(true);
        handleServerProductPhotos(
          response.data.data.blog_attachments,
          response.data.data
        );
        handleServerCovePhoto(response.data.data.cover, response.data.data);

        setBlog(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const removeImage = (id) => {
    let copyArray = [...previews];

    let hello = copyArray.filter((o) => o.id !== id);

    setPreviews(hello);
  };
  const removeImageServer = (id) => {
    let copyArrayFiles = [...files];
    let helloFiles = copyArrayFiles.filter((o) => o.id !== id);

    setFiles(helloFiles);
  };
  const uploadFilesCover = (e) => {
    const fileList = Array.from(e.target.files);
    console.log("file list", fileList);

    if (checkMimeType(e)) {
      const mappedFilesforIndexId = fileList.map((file) => {
        let obj = file;
        obj.id = _.uniqueId("gfg_");
        return obj;
      });

      const mappedFiles = mappedFilesforIndexId.map((file) => {
        return {
          originalName: file.name,
          mimetype: file.type,
          id: file.id,
          photoIndex: file.photoIndex,
          preview: URL.createObjectURL(file),
        };
      });
      setFileCover(mappedFilesforIndexId);
      setPreviewsCover(mappedFiles);
    }
  };

  const uploadMultipleFiles = (e, index) => {
    // let pRR = []

    const fileList = Array.from(e.target.files);

    if (checkMimeType(e)) {
      // setFiles(fileList);
      // for (let i = 0; i < fileList.length; i++) {
      //   someIDtoAssign = _.uniqueId("gfg_");
      //   console.log("fileeeeeeeeeeeeeeee=> idddd", someIDtoAssign);
      // }

      const mappedFilesforIndexId = fileList.map((file) => {
        let obj = file;
        obj.id = _.uniqueId("gfg_");
        obj.photoIndex = index;
        return obj;

        // return {
        //   originalName: file.name,
        //   mimetype: file.type,
        //   preview: URL.createObjectURL(file),
        // };
      });

      const mappedFiles = mappedFilesforIndexId.map((file) => {
        return {
          originalName: file.name,
          mimetype: file.type,
          id: file.id,
          photoIndex: file.photoIndex,
          preview: URL.createObjectURL(file),
        };
        // let obj = file;
        // obj.originalName = file.name;
        // obj.preview = URL.createObjectURL(file);
        // return obj;
      });
      // filesarr.push(mappedFilesforIndexId);

      return mappedFiles;
    }
  };

  const showImages = (index) => {
    console.log("index", index);
    let filteredArray = [...previews];
    filteredArray.filter((x) => x.id === index);
    console.log("fileted array", filteredArray);
  };

  // const maxSelectFile = (event) => {
  //   let files = event.target.files; // create file object
  //   if (files.length > 5) {
  //     const msg = "Only 5 images can be uploaded at a time";
  //     event.target.value = null; // discard selected file
  //     alert(msg);
  //     return false;
  //   }
  //   return true;
  // };

  const checkMimeType = (event) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = "";
    // list allow mime type
    //  "application/pdf"
    const types = ["image/png", "image/jpeg", "image/gif"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      alert(err);
      return false;
    }
    return true;
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
    // blog_attachments: Yup.array().of(
    //   Yup.object().shape({
    //     caption: Yup.string().required("Required"),
    //   })
    // ),
  });
  const initialValues = {
    title: "" || blog.title,
    description: "" || blog.description,

    blog_attachments: [
      {
        caption: "",
        attachment: [],
      },
    ],
  };
  const initialValuesEdit = {
    title: "" || blog.title,
    description: "" || blog.description,

    blog_attachments: blogAttachments,
  };

  const getFile = async (paramObj) => {
    const { preview, originalName, mimetype } = paramObj;
    const blob = await fetch(preview).then((r) => r.blob());
    const newfileObj = new File([blob], `${originalName}`, { type: mimetype });

    return newfileObj;
  };

  const getBlobFromFile = async (paramObj, index, blog) => {
    let fileExt = paramObj.split(".").pop();

    const response = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/files/blogAttachments/${blog.id}/${paramObj}`,

        {
          responseType: "blob",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        var objectURL = URL.createObjectURL(response.data);
        var obj = {
          originalName: paramObj,
          photoIndex: index,
          preview: objectURL,
          id: _.uniqueId("gfg_"),
          mimetype: `image/${fileExt}`,
        };

        return obj;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
  const getBlobFromFileCover = async (paramObj, blog) => {
    let fileExt = paramObj.split(".").pop();

    const response = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/files/blogs/${paramObj}`,

        {
          responseType: "blob",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        var objectURL = URL.createObjectURL(response.data);
        var obj = {
          originalName: paramObj,
          preview: objectURL,
          id: _.uniqueId("gfg_"),
          mimetype: `image/${fileExt}`,
        };

        return obj;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
  const handleServerCovePhoto = async (cover, blog) => {
    let previewFiles = [];

    let previewFile = await getBlobFromFileCover(cover, blog);
    previewFiles.push(previewFile);

    console.log("outputttttttttttttttt===>", previewFiles);
    setPreviewsCover(previewFiles);
  };
  const handleServerProductPhotos = async (arr, blog) => {
    let formatted = arr.map((o, i) => {
      let obj;
      obj = {
        caption: o.caption,
        attachment: [],
      };
      return obj;
    });
    console.log("formatedd====>", formatted);
    //

    let pR = [...arr];

    let previewFiles = [];
    for (let i = 0; i < pR.length; i++) {
      let obj = pR[i];

      let previewFile = await getBlobFromFile(obj.attachment, i, blog);
      previewFiles.push(previewFile);
    }
    let fineArr = _.compact(previewFiles);
    let game = formatted.map((arr, index) => {
      let obj = arr;
      obj.attachment = fineArr.filter((o) => o.photoIndex === index);

      return obj;
    });
    console.log("outputttttttttttttttt===> game", _.compact(game));
    setBlogAttachments(game);
    // setPreviews(previewFiles);
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log("value", values);
    // console.log("previews", previews);
    let comArry = [...values.blog_attachments];

    // let game = comArry.map((arr, index) => {
    //   let obj = arr;
    //   obj.product_photos = files.filter((o) => o.photoIndex === index);
    //   return obj;
    // });
    // let game = comArry.map((arr, index) => {
    //   let obj = arr;
    //   obj.attachment = previews.filter((o) => o.photoIndex === index);

    //   return obj;
    // });
    // console.log("game array array", game);
    // console.log("game array valuesss", values);
    let pvC = comArry.map((o) => {
      let obj = o;
      return obj.caption;
    });

    // const mappedImages = await Promise.all(
    //   comArry.map((o) => {
    //     let obj = o;
    //     return obj.product_photos.map(async (p) => {
    //       let obj2 = p;
    //       let someFile = await getFile(obj2);
    //       console.log("here it comes", someFile);
    //     });
    //   })
    // );

    let pvI = comArry.map((o) => {
      let obj = o;
      const response = obj.attachment.map(async (p) => {
        let obj2 = p;
        let someFile = await getFile(obj2);

        return someFile;
      });
      return response;
    });
    console.log("SOme fileeee", pvI);

    let outputArr = [];
    for (let i = 0; i < pvI.length; i++) {
      const arr = await pvI[i];

      let test = [];
      for (let j = 0; j < arr.length; j++) {
        const obj = await arr[j];
        test.push(obj);
      }
      outputArr.push(test);
    }
    console.log("outputttttttttttttttt===>", outputArr);

    // console.log("mappedImages", mappedImages);

    let formData = new FormData();

    // data.append("reports", files);

    formData.append("title", values.title);
    formData.append("description", values.description);
    if (previewsCover.length > 0) {
      let someFile = await getFile(previewsCover[0]);

      formData.append("cover", someFile);
    }

    for (var x = 0; x < pvC.length; x++) {
      formData.append(`caption[${x}]`, pvC[x]);
    }

    for (var w = 0; w < outputArr.length; w++) {
      let singleobj = outputArr[w];

      for (var b = 0; b < singleobj.length; b++) {
        formData.append(`attachment[${w}]`, singleobj[b]);
      }
    }

    for (var value of formData.values()) {
      console.log("value of formadata", value);
    }
    if (id !== undefined) {
      AdminAPI.post(`blog/${id}`, formData)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
          // setShowEndButton(true);
          // history.push("/doctor");
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    } else {
      AdminAPI.post(`blogs`, formData)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    }
  };

  const redirect = () => {
    setOpenDialog(false);
    history.push("/admin/blogs/list");
  }
  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    initialValues={!editing ? initialValues : initialValuesEdit}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">Title</label>
                                  <div className="col-md-8">
                                    <Field
                                      name={`title`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`title`}
                                      component="div"
                                      className="error-message"
                                    />
                                    {!_.isEmpty(serverErrors) &&
                                      serverErrors.title !== undefined && (
                                        <span className="error-msg">
                                          {" "}
                                          {serverErrors.title}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row prescription  ">
                                  <label className="col-md-3 ">
                                    Description
                                  </label>
                                  <div className="col-md-8">
                                    <Field
                                      component="textarea"
                                      name={`description`}
                                      className="form-control"
                                    />

                                    <ErrorMessage
                                      name={`description`}
                                      component="div"
                                      className="error-message"
                                    />
                                    {!_.isEmpty(serverErrors) &&
                                      serverErrors.description !==
                                        undefined && (
                                        <span className="error-msg">
                                          {" "}
                                          {serverErrors.description}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="obeservation">
                              <div className="form-group row prescription  ">
                                <label className="col-md-3 ">Cover</label>
                                <div className="col-md-8">
                                  <input
                                    id={`btn-input`}
                                    style={{ display: "none" }}
                                    multiple={false}
                                    type="file"
                                    onChange={(event) => {
                                      uploadFilesCover(event);
                                    }}
                                  />
                                  <label htmlFor={`btn-input`}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                    >
                                      Upload
                                    </Button>
                                  </label>
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.cover !== undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.cover}
                                      </span>
                                    )}
                                  {previewsCover !== undefined &&
                                    previewsCover.length > 0 && (
                                      <div className="form-group multi-preview">
                                        {(previewsCover || []).map(
                                          (url, key) => (
                                            <div
                                              key={key}
                                              className="container_img"
                                            >
                                              <img
                                                src={url.preview}
                                                alt="..."
                                              />
                                              <div className="topright">
                                                <button
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setPreviewsCover([]);
                                                    setFileCover([]);
                                                  }}
                                                  className="btn btn-sm"
                                                >
                                                  <HighlightOffIcon color="secondary" />
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {id !== undefined ? (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row mt-2">
                                <div className="col-md-12">
                                  <button
                                    onClick={deleteModal}
                                    className="btn btn-lg btn-danger float-left"
                                    type="button"
                                  >
                                    Delete
                                  </button>

                                  <button
                                    // disabled={
                                    //   medData.length === 0 &&
                                    //   observationData.length === 0 &&
                                    //   testData.length === 0 &&
                                    //   files.length === 0
                                    // }
                                    className="btn btn-lg btn-primary float-right"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ):(
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row mt-2">
                                <div className="col-md-12">
                                  <button
                                    className="btn btn-lg btn-primary float-right"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this blog?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-auto"
            onClick={() => setOpenDeleteDialog(false)}
            color="default"
          >
            Cancel
          </Button>
          <Button
            className="ml-auto"
            onClick={() => deleteBlog()}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully updated your blog
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => redirect()} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
