import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProductsGrids from "../../components/ProductGrids";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  accor: {
    backgroundColor: "#62d2a2",
    color: "#fff",
  },
  accorPaper: {
    margin: "16px 10px!important",
  },
}));

export default function Search() {
  let { search } = useParams();
  const classes = useStyles();
  const [priceRange, setPriceRange] = useState({
    min_price: "",
    max_price: "",
  });

  const [products, setProducts] = useState([]);
  const [path, setPath] = useState({});
  const [productMsg, setProductMsg] = useState("");
  const [stop, setStop] = useState(false);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });
  useEffect(() => {
    if (search !== undefined) {
      setProducts([]);
      setParams({ ...params, page: 1 });
      setStop(false);
    }
  }, [search]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("search field :::::::", search);

  const handleChange = (e) => {
    // setProducts([]);
    setPriceRange({ ...priceRange, [e.target.name]: e.target.value });
    // setParams({ ...params, ...priceRange})
  };
  const handlePriceRange = () => {
    setProducts([]);
    setParams({ ...params, ...priceRange });
  };
  const handleSorting = (value) => {
    if (value != "") {
      setProducts([]);
      setParams({ ...params, sort_by: value });
    }
  };
  const triggerScroll = (value) => {
    console.log("value", value);
    setParams({ ...params, page: params.page + 1 });
  };
  useEffect(() => {
    getProducts();
  }, [params]);
  const getProducts = () => {
    axios
      .get(`products?search=${search}`, { params: params })
      .then((response) => {
        let curr = response.data.data.current_page;
        let last = response.data.data.last_page;
        let pPp = [...products];
        let copyArr = [...response.data.data.data];
        const projectListLocalIDS = pPp.map((o) => o.id);
        copyArr.forEach((item) => {
          if (!projectListLocalIDS.includes(item.id)) {
            pPp.push(item);
          }
        });
        if (curr === last) {
          setStop(true);
        }

        console.log("pPp all======>", pPp);
        if (pPp.length === 0) {
          setProductMsg("No Products Found");
        } else if (pPp.length > 0) {
          setProductMsg("");
        }

        setProducts(pPp);

        setPath(response.data.path);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div>
      <div className="">
        {search ? (
          <div>
            {/* Search {search} */}
            <section className="popular-category bg-white mt-minus pt-60 pb-60 pb-md-30 pb-sm-30">
              <div className="">
                <div className="row my-2">
                  <div className="col-md-2 search_fields">
                    <input
                      name="min_price"
                      type="text"
                      placeholder="Min Price"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-2 search_fields">
                    <input
                      name="max_price"
                      placeholder="Max Price"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-2 search_fields">
                    <button
                      onClick={handlePriceRange}
                      className="btn btn-outline-primary"
                    >
                      Go
                    </button>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4 search_fields">
                    <select
                      onChange={(e) => {
                        handleSorting(e.target.value);
                      }}
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option value="">Sort By</option>
                      <option value="name.asc">Name (A-Z)</option>
                      <option value="name.desc">Name (Z-A)</option>
                      <option value="price.asc">Price (Low - High)</option>
                      <option value="price.desc">Price (High - Low)</option>
                    </select>
                  </div>
                </div>

                <div className={classes.root}>
                  <div className={classes.demo1}>
                    <ProductsGrids
                      triggerScroll={triggerScroll}
                      products={products}
                      path={path}
                      stop={stop}
                      search={search}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <h1 className="text-center">Search Products</h1>
        )}
        <h1 className="text-center">{productMsg}</h1>
      </div>
    </div>
  );
}
