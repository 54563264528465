import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { InvoiceContext } from "../../contexts/InvoiceContext";

import InvoiceItem from "./InvoiceItem";
// import styles from './InvoiceProducts.module.scss';

const InvoiceProducts = ({ handleCheckout }) => {
  const { cartItems } = useContext(InvoiceContext);

  return (
    <div className={"styles.p__containersa"}>
      <div className="card card-body border-0">
        {cartItems.map((product) => (
          <InvoiceItem key={product.selectedVariant.id} product={product} />
        ))}
      </div>
      {cartItems.length > 0 && (
        <div className="text-right">
          <button
            type="button"
            onClick={() => handleCheckout()}
            className="btn btn-sm btn-primary"
          >
            Checkout
          </button>
        </div>
      )}
    </div>
  );
};

export default InvoiceProducts;
