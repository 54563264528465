import React, { useState, useEffect } from "react";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import moment from "moment";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

function ReportList() {
  const [reportList, setReportList] = useState([]);
  const [income, setIncome] = useState("");
  const [expense, setExpense] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });
  const [selectedStartDate, handleDateChangeStart] = useState(null);
  const [selectedEndDate, handleDateChangeEnd] = useState(null);

  useEffect(() => {
    getReportList();
  }, [params]);

  const getReportList = () => {
    AdminAPI.get(`reports`, { params: params })
      .then((response) => {
        setReportList(response.data.data.data);
        setTotalPage(response.data.data.last_page);
        setIncome(response.data.income);
        setExpense(response.data.expense);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const pdf = (type) => {
    AdminAPI.get(`reports/pdf`, {
      params: params,
      responseType: "blob",
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        if (type == "Download") {
          var a = document.createElement("a");
          a.href = fileURL;
          console.log(fileURL);
          a.download =
            "Imaginary income-expense report " +
            moment().format("YYYY-MM-DD") +
            ".pdf";
          a.click();
          window.URL.revokeObjectURL(fileURL);
        } else {
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnWiseSearch = _.debounce((inputValue, column) => {
    const paramState = { ...params };
    if (_.isEmpty(inputValue)) {
      delete paramState[column];
    } else {
      paramState[column] = inputValue;
    }
    paramState.page = 1; // This is for resolving the columwise search & pagination bug.
    setParams(paramState);
  }, 500);

  const handleDateChangeDate = (value, col) => {
    if(value !== null) {
      handleColumnWiseSearch(moment(value).format('YYYY-MM-DD'), col);
    }
    else {
      handleColumnWiseSearch(value, col);
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
    setParams({ ...params, page: value });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-9">
            <div className="row">
              <div className="col-4">Income: {income}</div>
            </div>
            <div className="row">
              <div className="col-4">Expense: {expense}</div>
            </div>
          </div>
          <div className="col-3">
            <button
              onClick={(event) => {
                event.preventDefault();
                pdf("Download");
              }}
              className="btn btn-sm btn-success float-right mx-2"
            >
              Download
            </button>
            <button
              onClick={(event) => {
                event.preventDefault();
                pdf("Print");
              }}
              className="btn btn-sm btn-success float-right mx-2"
            >
              Print
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Profit: {income - expense}</div>
          <div className="col-8 my-1">
            <input
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                handleColumnWiseSearch(e.target.value, "search");
              }}
            ></input>
          </div>
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="row my-2">
            <div className="col">
              {" "}
              <KeyboardDatePicker
                clearable
                value={selectedStartDate}
                placeholder="Start Date"
                onChange={(date) => {
                  handleDateChangeStart(date);
                  handleDateChangeDate(date, 'start_date');
                }}
                format="MM/dd/yyyy"
              />
            </div>
            <div className="col">
              <KeyboardDatePicker
                clearable
                value={selectedEndDate}
                placeholder="End Date"
                onChange={(date) => {
                  handleDateChangeEnd(date);
                  handleDateChangeDate(date, 'end_date')
                }}
                format="MM/dd/yyyy"
              />
            </div>
            <div className="col">
              <select
                onChange={(e) => {
                  handleColumnWiseSearch(e.target.value, "type");
                }}
                className="form-control"
                aria-label="Default select example"
              >
                <option value="">All</option>
                <option value="Income">Income</option>
                <option value="Expense">Expense</option>
              </select>
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col" className="text-center">
                      Type
                    </th>
                    <th scope="col" className="text-center">
                      Income
                    </th>
                    <th scope="col" className="text-center">
                      Expense
                    </th>
                    <th scope="col" className="text-center">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportList.map((report, i) => (
                    <tr key={i}>
                      <td>{report.referred_from}</td>
                      <td className="text-right">
                        {report.type == "Income" ? report.bill : ""}
                      </td>
                      <td className="text-right">
                        {report.type == "Expense" ? report.bill : ""}
                      </td>
                      <td className="text-right">{report.report_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <Pagination
            color="primary"
            count={totalPage}
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ReportList;
