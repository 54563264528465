import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import "../css/admin.css";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function CustomerForm() {
  let history = useHistory();
  let { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [customer, setCustomer] = useState("");
  const [serverErrors, setServerErrors] = useState({});

  const validationSchema = Yup.object({
    mobile_number: Yup.string().required("This field is required")
  });

  const initialValues = {
    mobile_number: customer.mobile_number || ""
  };

  const onSubmit = async (values, { resetForm }) => {
    let objToSend = {
      mobile_number: values.mobile_number
    };
    AdminAPI.post(`users`, objToSend)
      .then((response) => {
        console.log(response);
        history.push(`/admin/customers`);
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        setServerErrors(error.response.data.errors);
      });
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="obeservation">
                              <div className="form-group row">
                                <label className="col-md-3 ">Phone</label>
                                <div className="col-md-9">
                                  <Field
                                    name={`mobile_number`}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name={`mobile_number`}
                                    component="div"
                                    className="error-message"
                                  />
                                  {!_.isEmpty(serverErrors) &&
                                    serverErrors.mobile_number !== undefined && (
                                      <span className="error-msg">
                                        {" "}
                                        {serverErrors.mobile_number}
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <button
                                  className="btn btn-lg btn-success ml-auto"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
