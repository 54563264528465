import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../css/admin.css";
import axios from "axios";
import InvoiceItem from "./InvoiceItem";
import clsx from "clsx";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import Drawer from "@material-ui/core/Drawer";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext, useState, useEffect } from "react";

import { InvoiceContext } from "../../../contexts/InvoiceContext";

import Icon from "@material-ui/core/Icon";

import ButtonGroup from "@material-ui/core/ButtonGroup";

import Typography from "@material-ui/core/Typography";

import InvoiceProducts from "../../cart/InvoiceProducts";
import ProductItems from "../../../components/ProductItems";
const drawerWidthCart = 320;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  btnRoot: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      fontSize: "9px",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  header: {
    padding: "12px 0",
    borderBottom: "1px solid darkgrey",
  },
  content: {
    padding: 12,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
    fontWeight: "700",
  },
  icnBtnRoot: {
    padding: "0px",
  },
  header: {
    padding: "12px 0",
    borderBottom: "1px solid darkgrey",
  },
  content: {
    padding: 12,
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
    fontWeight: "700",
  },
  hide: {
    display: "none",
  },

  drawerCart: {
    width: drawerWidthCart,
    flexShrink: 0,
  },
  drawerPaperCart: {
    width: drawerWidthCart,
    [theme.breakpoints.up("sm")]: {
      top: "65px",
      width: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      top: "55px",
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },

  contentShift2: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      marginRight: drawerWidthCart,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function InvoiceForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();
  const { total, cartItems, clearCart, itemCount } = useContext(InvoiceContext);
  const [categoryID, setCategoryID] = React.useState("");
  const [categoryValue, setCategoryValue] = React.useState("");
  const [invoice, setInvoice] = useState({});
  const [editing, setEditing] = useState(false);

  const paramID = parseInt(id);
  const { addProduct } = useContext(InvoiceContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [customers, setCustomers] = useState([]);
  const [policeStations, setPoliceStations] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [customer, setCustomer] = useState("");
  const [district, setDistrict] = useState("");
  const [stationSearchTerm, setStationSearchTerm] = useState("");
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  const [policeStation, setPoliceStation] = useState("");
  const [productList, setProductList] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [open, setOpen] = useState(false);
  const [openCart, setOpenCart] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  useEffect(() => {
    // getProducts("");
    // clearCart();
    if (id !== undefined) {
      getCategoryDetails();
    }
  }, []);

  const getCategoryDetails = () => {
    AdminAPI.get(`invoices/${id}`)
      .then((response) => {
        setEditing(true);

        setInvoice(response.data.data);
        setCustomer(response.data.data.customer.mobile_number);
        setDistrict(response.data.data.police_station.district);
        setPoliceStation(response.data.data.police_station.name);

        let da = [];
        da = response.data.data.invoice_details;
        let productArr = [];

        for (let i = 0; i < da.length; i++) {
          let obj = da[i];
          let product = obj.product_variant.product;
          product.product_variant_id = obj.product_variant_id;
          product.quantity = obj.quantity;

          console.log("prodcut inside loop", product);
          productArr.push(product);
          // let sele = product.product_variants.filter(
          //   (o) => o.id === response.data.data.product_variant.id
          // );
          // console.log("sfdsfd from loop =====>", sele);
          // setSelectedVariant(sele[0]);
        }
        setProductList(productArr);
        console.log("invoice data ararr", productArr);
        let cus = da.map((o) => {
          let obj = o;
        });

        // setInvoice(response.data.data);
        // setCategoryValue(response.data.data.category.name);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    GetPoliceStations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationSearchTerm, district]);
  useEffect(() => {
    getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtSearchTerm]);
  useEffect(() => {
    getCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const getCustomerList = () => {
    AdminAPI.get(`users?mobile_number=${customer}`)
      .then((response) => {
        console.log("customers====>", response.data.data);
        setCustomers(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const GetPoliceStations = () => {
    if (district !== "") {
      axios
        .get(`districts/${district}?name=${stationSearchTerm}`)
        .then((response) => {
          console.log("pppppp", response.data.data);
          setPoliceStations(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  const getDistricts = () => {
    axios
      .get(`districts?district=${districtSearchTerm}`)
      .then((response) => {
        console.log("dddd", response.data.data.data);
        setDistrictList(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getProducts = (value) => {
    AdminAPI.get(`products?name=${value}`)
      .then((response) => {
        console.log("pppppp", response.data.data);
        setProductList(response.data.data.data);
        let dta = response.data.data.data;
        let objT = dta.map((o) => {
          let obj = {
            product_variant: {
              product: o,
            },
          };
          return obj;
        });
        console.log("maaap data", objT);
        // setProductList(objT);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureCustomer = _.debounce((event) => {
    setCustomer(event.target.value);
  }, 500);
  const handleOnInputCaptureMed = _.debounce((event) => {
    setStationSearchTerm(event.target.value);
  }, 500);
  const handleOnInputCaptureDis = _.debounce((event) => {
    setDistrictSearchTerm(event.target.value);
  }, 500);
  const handleOnInputCaptureProduct = _.debounce((value) => {
    getProducts(value);
  }, 500);
  const validationSchema = Yup.object({
    customer_id: Yup.string().required("This field is required"),
    customer_name: Yup.string().required("This field is required"),
    police_station_id: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
  });
  const initialValues = {
    customer_id: invoice.customer_id || "",
    customer_name: invoice.customer_name || "",
    police_station_id: invoice.police_station_id || "",
    address: invoice.address || "",
  };

  const onSubmit = async (values, { resetForm }) => {
    let invoiceDetails = cartItems.map((o) => {
      let obj = {
        product_variant_id: o.selectedVariant.id,
        quantity: o.quantity,
      };
      return obj;
    });
    console.log("invoiceDetails", invoiceDetails);
    let objToSend = { ...values };
    objToSend.invoiceDetails = invoiceDetails;
    if (id !== undefined) {
      AdminAPI.put(`invoices/${id}`, objToSend)
        .then((response) => {
          console.log(response);
          setOpenDialog(true);
          clearCart();
          history.push("/admin/invoices");
        })
        .catch((error) => {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        });
    } else {
      AdminAPI.post(`invoices`, objToSend)
        .then((response) => {
          console.log(response);
          clearCart();
          setOpenDialog(true);
          history.push("/admin/invoices");
        })
        .catch((error) => {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        });
    }
  };

  const onChagneVarint = (e, product) => {
    console.log(e.target.value, product);
    let pID = parseInt(e.target.value);
    let producta = { ...product };
    let arr = producta.product_variants.filter((o) => o.id === pID);
    console.log("selected variant arr", arr);
    setSelectedVariant(arr[0]);
    console.log("selected variant", selectedVariant);
  };

  const handleDrawerOpenCart = () => {
    setOpenCart(!openCart);
  };
  const handleCheckout = () => {
    console.log("trieeeeeee");
    setShowForm(true);
  };

  return (
    <div>
      <Drawer
        className={classes.drawerCart}
        variant="persistent"
        anchor="right"
        open={openCart}
        classes={{
          paper: classes.drawerPaperCart,
        }}
      >
        <div className="shopptingCartButtona">
          <div class="shoppingCartButton">{/* <button>hello</button> */}</div>
          <div className="header-cart">
            <div className="row py-2">
              <div className="col-12">
                <div className="d-flex">
                  <div className="px-2">
                    {" "}
                    <Icon fontSize="large">local_mall</Icon>{" "}
                  </div>
                  <div>
                    {" "}
                    <h3 className="">{} Items</h3>
                  </div>
                  <div className="px-4">
                    {" "}
                    <Button
                      onClick={() => handleDrawerOpenCart()}
                      variant="outlined"
                      color="primary"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="col-5"></div> */}
            </div>
          </div>
          <div className="cart-items-list">
            <InvoiceProducts handleCheckout={handleCheckout} />
          </div>
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
          [classes.contentShift2]: openCart,
        })}
      >
        <div onClick={() => handleDrawerOpenCart()} className="stickyHeader">
          <div className="text-center">
            <LocalMallOutlinedIcon fontSize="small" />
          </div>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {itemCount} Items
          </h5>
          <h5 style={{ textAlign: "center", fontSize: "14px" }} className="">
            {total} Tk
          </h5>
        </div>
        <div className="products_section">
          <div className="container">
            <div className="products_section_form">
              {showForm ? (
                <div className="form_area">
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="text-right">
                        <button
                          className="btn btn-lg btn-primary"
                          onClick={() => setShowForm(false)}
                        >
                          Search Product
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="whole-prescription-area">
                        <Formik
                          enableReinitialize={id ? true : false}
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validationSchema}
                        >
                          {({
                            setFieldValue,
                            values,
                            errors,
                            isValid,
                            dirty,
                            resetForm,
                            validateField,
                          }) => (
                            <Form>
                              <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                  <div className="obeservation">
                                    <div className="form-group row">
                                      <label className="col-md-3 ">
                                        Customer
                                      </label>
                                      <div className="col-md-9">
                                        <Autocomplete
                                          freeSolo
                                          id="disabledHelperText"
                                          name="customer_id"
                                          options={customers}
                                          onInputCapture={(event) => {
                                            event.persist(); // As we are accessing the event in asynchronous way.
                                            handleOnInputCaptureCustomer(event);
                                          }}
                                          getOptionLabel={(option) => {
                                            console.log("option ", option);
                                            return (
                                              option.mobile_number || customer
                                            );
                                          }}
                                          getOptionSelected={(
                                            option,
                                            value
                                          ) => {
                                            return (
                                              option.mobile_number === value
                                            );
                                          }}
                                          value={customer}
                                          onChange={(e, value) => {
                                            if (value == null) {
                                              setFieldValue("customer_id", "");
                                            } else {
                                              console.log(value);

                                              setFieldValue(
                                                "customer_id",
                                                value.id
                                              );
                                              setCustomer(value.mobile_number);
                                              // setPoliceStation(value.id);
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              {...params}
                                              onChange={(e, value) => {
                                                console.log(e.target.value);
                                              }}
                                              name="customer_id"
                                              variant="outlined"
                                              fullWidth
                                              placeholder="Select Customer"
                                            />
                                          )}
                                        />
                                        <ErrorMessage
                                          name="customer_id"
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-md-3 ">Name</label>
                                      <div className="col-md-9">
                                        <Field
                                          name={`customer_name`}
                                          className="form-control"
                                        />

                                        <ErrorMessage
                                          name={`customer_name`}
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-md-3 ">
                                        District
                                      </label>
                                      <div className="col-md-9">
                                        <Autocomplete
                                          freeSolo
                                          id="disabledHelperText"
                                          name="district"
                                          placeholder="Select District"
                                          options={districtList}
                                          value={district}
                                          onInputCapture={(event) => {
                                            event.persist(); // As we are accessing the event in asynchronous way.
                                            handleOnInputCaptureDis(event);
                                          }}
                                          getOptionLabel={(option) => {
                                            return option.district || district;
                                          }}
                                          getOptionSelected={(
                                            option,
                                            value
                                          ) => {
                                            return option.district === value;
                                          }}
                                          onChange={(e, value) => {
                                            if (value == null) {
                                              console.log("Hello");

                                              // setPoliceStations([]);
                                              setFieldValue(
                                                "police_station_id",
                                                ""
                                              );
                                            } else {
                                              console.log(value);
                                              // setFieldValue(
                                              //   "police_station_id",
                                              //   value.id
                                              // );
                                              // setPoliceStations([]);
                                              setDistrict(value.district);

                                              GetPoliceStations();
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              {...params}
                                              onChange={(e, value) => {
                                                console.log(e.target.value);
                                              }}
                                              name="district"
                                              variant="outlined"
                                              fullWidth
                                              placeholder="Select District"
                                            />
                                          )}
                                        />
                                        <ErrorMessage
                                          name="district"
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-md-3 ">
                                        Police Station
                                      </label>
                                      <div className="col-md-9">
                                        <Autocomplete
                                          disabled={district === ""}
                                          freeSolo
                                          id="disabledHelperText"
                                          name="police_station_id"
                                          value={policeStation}
                                          options={policeStations}
                                          onInputCapture={(event) => {
                                            event.persist(); // As we are accessing the event in asynchronous way.
                                            handleOnInputCaptureMed(event);
                                          }}
                                          getOptionSelected={(
                                            option,
                                            value
                                          ) => {
                                            return option.name === value;
                                          }}
                                          getOptionLabel={(option) => {
                                            return option.name || policeStation;
                                          }}
                                          onChange={(e, value) => {
                                            if (value == null) {
                                              setFieldValue(
                                                "police_station_id",
                                                ""
                                              );
                                            } else {
                                              console.log(value);

                                              setFieldValue(
                                                "police_station_id",
                                                value.id
                                              );
                                              setPoliceStation(value.name);
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <Field
                                              component={TextField}
                                              {...params}
                                              onChange={(e, value) => {
                                                console.log(e.target.value);
                                              }}
                                              name="police_station_id"
                                              variant="outlined"
                                              fullWidth
                                              placeholder="Select Police Station"
                                            />
                                          )}
                                        />
                                        <ErrorMessage
                                          name="police_station_id"
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-3 ">
                                        Address
                                      </label>
                                      <div className="col-md-9">
                                        <Field
                                          component="textarea"
                                          name={`address`}
                                          className="form-control"
                                        />

                                        <ErrorMessage
                                          name={`address`}
                                          component="div"
                                          className="error-message"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div className="col-md-12">
                                        <div className="text-right">
                                          <button
                                            className="btn btn-lg btn-primary"
                                            type="submit"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="product_list_area">
                  <div className="row my-3">
                    <div className="col-md-4">
                      <input
                        type="text"
                        onChange={(e) =>
                          handleOnInputCaptureProduct(e.target.value)
                        }
                        className="form-control"
                        placeholder="Search Product"
                      />
                    </div>
                    <div className="col-md-4">
                      <button
                        type="text"
                        onClick={() => clearCart()}
                        className="btn btn-primary"
                      >
                        {" "}
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="row"></div>
                  <table class="custom-responsive-table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Variant</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productList.map((p, o) => (
                        <RenderRows product={p} key={o} />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have successfully added your invoice
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const RenderRows = ({ product }) => {
  const [selectedVariant, setSelectedVariant] = useState({});
  const classes = useStyles();
  const { addProduct, cartItems, increase, decrease, removeProduct } =
    useContext(InvoiceContext);

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.selectedVariant.id === product.id);
  };
  const checkQuantity = (product) => {
    // console.log("product", product);
    let selectedProduct = cartItems.find(
      (item) => item.selectedVariant.id === product.id
    );
    if (selectedProduct !== undefined) {
      if (Object.keys(selectedProduct).includes("quantity"))
        return selectedProduct.quantity;
    }
    return 0;
  };
  const decreaseProduct = (item) => {
    if (checkQuantity(item) > 1) {
      decrease(product, selectedVariant);
    } else {
      removeProduct(product, selectedVariant);
    }
  };
  const onChagneVarint = (e) => {
    console.log(e.target.value);
    let pID = parseInt(e.target.value);
    let p = { ...product };
    let arr = p.product_variants.filter((o) => o.id === pID);
    setSelectedVariant(arr[0]);
  };

  useEffect(() => {
    console.log("sfdsfd useeffect =====>", product);
    if (product.product_variants.length > 0) {
      // let sele = product.product_variants.filter(
      //   (o) => o.id === product.product_variant_id
      // );
      // console.log("sfdsfd useeffect =====>");
      setSelectedVariant(product.product_variants[0]);
      // addFromSer(product.product_variants[0]);
    }
    if (product.product_variant_id) {
      let sele = product.product_variants.filter(
        (o) => o.id === product.product_variant_id
      );
      console.log("sfdsfd =====>", sele);
      setSelectedVariant(sele[0]);
      addFromSer(sele[0]);
    }
  }, [product]);
  // console.log("sfdsfd variant=====>", selectedVariant);
  // console.log("sfdsfd caet=====>", cartItems);
  const addFromSer = (variant) => {
    if (!_.isEmpty(variant)) {
      console.log("You are here", variant);
      addProduct(product, variant, product.quantity);
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td data-column="Name">{product.name}</td>
        <td data-column="Variant">
          {" "}
          {product.product_variants.length > 0 ? (
            <div className="text-left product-price">
              <select
                onChange={(e) => {
                  onChagneVarint(e);
                }}
                className="form-control"
                aria-label="Default select example"
                value={selectedVariant !== undefined ? selectedVariant.id : ""}
              >
                {product.product_variants.map((p, i) => (
                  <option value={p.id}>
                    {p.unit} - {p.price}{" "}
                    {(p.status !== "Out of stock") &
                    (product.status !== "Out of stock")
                      ? ""
                      : "(Stockout)"}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="select_height"> </div>
          )}
        </td>
        <td data-column="Action">
          {product.status !== "Out of stock" ? (
            <span>
              {selectedVariant !== undefined && isInCart(selectedVariant) && (
                <div>
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      classes={{ root: classes.btnRoot }}
                      onClick={() => decreaseProduct(selectedVariant)}
                    >
                      {" "}
                      <Icon color="white">remove</Icon>
                    </Button>
                    <Button classes={{ root: classes.btnRoot }}>
                      {" "}
                      <span className="quantity_areaa">
                        {checkQuantity(selectedVariant)} in bag
                      </span>
                    </Button>
                    <Button
                      classes={{ root: classes.btnRoot }}
                      onClick={() => increase(product, selectedVariant)}
                    >
                      {" "}
                      <Icon color="white">add</Icon>
                    </Button>
                  </ButtonGroup>
                </div>
              )}

              {selectedVariant !== undefined && !isInCart(selectedVariant) && (
                <React.Fragment>
                  <button
                    onClick={() => addProduct(product, selectedVariant)}
                    className="btn btn-primary btn-block"
                  >
                    Add to cart
                  </button>
                </React.Fragment>
              )}
            </span>
          ) : (
            <span className="text-danger">Out of stock</span>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
};
