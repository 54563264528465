import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams, Link } from "react-router-dom";
import { AdminAPI } from "../../../AxiosRequest/AdminAPI";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import moment from "moment";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../css/admin.css";

const useStyles = makeStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
});

export default function PurchaseForm() {
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const theme = useTheme();
  const [purchaseDetail, setPurchaseDetail] = useState({});
  const [products, setProducts] = useState([]);
  const [productValue, setProductValue] = useState("");
  const [variants, setVariants] = useState([]);
  const [variantValue, setVariantValue] = useState("");
  const [message, setMessage] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const params = {
    page: 1,
    per_page: 15,
  };

  useEffect(() => {
    getProductList();
  }, [productSearchTerm]);

  const getProductList = () => {
    AdminAPI.get(`products?search=${productSearchTerm}`, { params: params })
      .then((response) => {
        setProducts(response.data.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleOnInputCaptureProduct = _.debounce((event) => {
    setProductSearchTerm(event.target.value);
  }, 500);

  useEffect(() => {
    setDeleteMessage("Are you sure to delete this purchase?");

    if (id !== undefined) {
      getpurchaseDetails();
    }
  }, []);

  const getpurchaseDetails = () => {
    AdminAPI.get(`purchases/${id}`)
      .then((response) => {
        setPurchaseDetail(response.data.data);
        setVariantValue(response.data.data.product_variant.unit);
        setProductValue(response.data.data.product_variant.product.name);
        getVariantList(response.data.data.product_variant.product_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVariantList = (productId) => {
    AdminAPI.get(`products/${productId}`)
      .then((response) => {
        console.log(response.data.data.product_variants);
        setVariants(response.data.data.product_variants);
      })
      .catch((error) => {
        console.log(error.data.message);
      });
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log("valuessssss", values);
    let dataSet = {
      product_id: values.product_id,
      product_variant_id: values.product_variant_id,
      unit_price: values.unit_price,
      quantity: values.quantity,
      total: values.total,
      purchase_date: moment(values.purchase_date).format("YYYY-MM-DD"),
    };

    if (id !== undefined) {
      AdminAPI.put(`purchases/${id}`, dataSet)
        .then((response) => {
          setOpenDialog(true);
          setMessage(response.data.message);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    } else {
      AdminAPI.post(`purchases`, dataSet)
        .then((response) => {
          setMessage(response.data.message);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          setServerErrors(error.response.data.errors);
        });
    }
  };

  const deletePurchase = () => {
    AdminAPI.post(`purchases/${id}`)
      .then((response) => {
        setDeleteMessage(response.data.message);
        setDeleteModal(false);
        history.push("/admin/purchases");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    product_id: Yup.string().required("This field is required"),
    product_variant_id: Yup.string().required("This field is required"),
    quantity: Yup.string().required("This field is required"),
    unit_price: Yup.string().required("This field is required"),
    total: Yup.string().required("This field is required"),
    purchase_date: Yup.date().required(),
  });
  const initialValues = {
    product_id: purchaseDetail.product_variant_id || "",
    product_variant_id: purchaseDetail.product_variant_id || "",
    quantity: purchaseDetail.quantity || "",
    unit_price: purchaseDetail.unit_price || "",
    total: purchaseDetail.total || "",
    purchase_date:
      moment(purchaseDetail.valid_date).format("MM/DD/YYYY") ||
      moment().format("MM/DD/YYYY"),
  };

  const closeModal = () => {
    setOpenDialog(false);
    history.push("/admin/purchases");
  };

  return (
    <div>
      <div className="products_section">
        <div className="container">
          <div className="products_section_form">
            <div className="row">
              <div className="col-lg-12">
                <div className="whole-prescription-area">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      setFieldValue,
                      values,
                      errors,
                      isValid,
                      dirty,
                      resetForm,
                      validateField,
                    }) => (
                      <Form>
                        <div className="form-group row">
                          <label className="col-md-3">Product</label>
                          <div className="col-md-9">
                            <Autocomplete
                              id="disabledHelperText"
                              getOptionLabel={(option) =>
                                option.name || productValue
                              }
                              getOptionSelected={(option, value) => {
                                return option.name === value;
                              }}
                              onInputCapture={(event) => {
                                event.persist(); // As we are accessing the event in asynchronous way.
                                handleOnInputCaptureProduct(event);
                              }}
                              value={productValue}
                              options={products}
                              onChange={(event, value) => {
                                if (value == null) {
                                  console.log("null");
                                  setProductValue("");
                                } else {
                                  setFieldValue(`product_id`, value.id);
                                  setProductValue(value.name);
                                  setVariantValue("");
                                  getVariantList(value.id);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextField}
                                  {...params}
                                  name={`product_id`}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                            <ErrorMessage
                              name={`product_id`}
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverErrors) &&
                              serverErrors.product_id !== undefined && (
                                <span className="error-msg">
                                  {" "}
                                  {serverErrors.product_id}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3">Variant</label>
                          <div className="col-md-9">
                            <Autocomplete
                              id="disabledHelperText"
                              getOptionLabel={(option) =>
                                option.unit || variantValue
                              }
                              getOptionSelected={(option, value) => {
                                return option.unit === value;
                              }}
                              value={variantValue}
                              options={variants}
                              onChange={(event, value) => {
                                if (value == null) {
                                  setVariantValue("");
                                } else {
                                  setFieldValue(`product_variant_id`, value.id);
                                  setVariantValue(value.unit);
                                }
                              }}
                              renderInput={(params) => (
                                <Field
                                  component={TextField}
                                  {...params}
                                  name={`variant_id`}
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                            <ErrorMessage
                              name={`variant_id`}
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverErrors) &&
                              serverErrors.product_variant_id !== undefined && (
                                <span className="error-msg">
                                  {" "}
                                  {serverErrors.product_variant_id}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 ">Quantity</label>
                          <div className="col-md-9">
                            <Field name={`quantity`} className="form-control" />

                            <ErrorMessage
                              name={`quantity`}
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverErrors) &&
                              serverErrors.quantity !== undefined && (
                                <span className="error-msg">
                                  {" "}
                                  {serverErrors.quantity}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 ">Unit Price</label>
                          <div className="col-md-9">
                            <Field
                              name={`unit_price`}
                              className="form-control"
                            />

                            <ErrorMessage
                              name={`unit_price`}
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverErrors) &&
                              serverErrors.unit_price !== undefined && (
                                <span className="error-msg">
                                  {" "}
                                  {serverErrors.unit_price}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 ">Total price</label>
                          <div className="col-md-9">
                            <Field name={`total`} className="form-control" />

                            <ErrorMessage
                              name={`total`}
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverErrors) &&
                              serverErrors.total !== undefined && (
                                <span className="error-msg">
                                  {" "}
                                  {serverErrors.total}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 ">Purchase date</label>
                          <div className="col-md-8">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                id="date-picker-dialog"
                                label="Purchase date"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                value={values.purchase_date}
                                autoOk
                                clearable
                                onChange={(value) =>
                                  setFieldValue("purchase_date", value)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                fullWidth
                              />
                            </MuiPickersUtilsProvider>
                            <ErrorMessage
                              name="purchase_date"
                              component="div"
                              className="error-message"
                            />
                            {!_.isEmpty(serverErrors) &&
                              serverErrors.purchase_date !== undefined && (
                                <span className="error-msg">
                                  {" "}
                                  {serverErrors.purchase_date}
                                </span>
                              )}
                          </div>
                        </div>
                        {id !== undefined ? (
                          <div className="form-group row">
                            <div className="col-md-6">
                              <div className="text-left">
                                <button
                                  onClick={() => setDeleteModal(true)}
                                  className="btn btn-md btn-danger"
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="text-right">
                                <button
                                  className="btn btn-md btn-primary"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group row">
                            <div className="col-md-12 ml-auto">
                              <div className="text-right">
                                <button
                                  className="btn btn-md btn-primary"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeModal()} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Dialog
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {deleteMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="mr-auto"
              onClick={() => setDeleteModal(false)}
              color="secondary"
            >
              Close
            </Button>
            <Button
              className="ml-auto"
              onClick={() => deletePurchase()}
              color="primary"
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
// const top100Films = [
//   { title: "Napa", year: 1994 },
//   { title: "Seclo", year: 1972 },
// ];
