import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { InvoiceContext } from "../../../contexts/InvoiceContext";

import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      fontSize: "9px",
    },
  },
}));

export default function InvoiceItem({ product, invoice }) {
  const [selectedVariant, setSelectedVariant] = useState({});
  const classes = useStyles();
  const { addProduct, cartItems, increase, decrease, removeProduct } =
    useContext(InvoiceContext);

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.selectedVariant.id === product.id);
  };
  const checkQuantity = (product) => {
    // console.log("product", product);
    let selectedProduct = cartItems.find(
      (item) => item.selectedVariant.id === product.id
    );
    if (selectedProduct !== undefined) {
      if (Object.keys(selectedProduct).includes("quantity"))
        return selectedProduct.quantity;
    }
    return 0;
  };
  const decreaseProduct = (item) => {
    if (checkQuantity(item) > 1) {
      decrease(product, selectedVariant);
    } else {
      removeProduct(product, selectedVariant);
    }
  };
  const onChagneVarint = (e) => {
    console.log(e.target.value);
    let pID = parseInt(e.target.value);
    let p = { ...product };
    let arr = p.product_variants.filter((o) => o.id === pID);
    setSelectedVariant(arr[0]);
  };

  useEffect(() => {
    if (product.product_variants.length > 0 && product.product_variant_id) {
      let sele = product.product_variants.filter(
        (o) => o.id === product.product_variant_id
      );
      console.log("sfdsfd =====>", sele);
      setSelectedVariant(sele[0]);
      addFromSer(sele[0]);
    } else {
      setSelectedVariant(product.product_variants[0]);
    }
  }, []);
  console.log("sfdsfd variant=====>", selectedVariant);
  console.log("sfdsfd caet=====>", cartItems);
  const addFromSer = (variant) => {
    if (!_.isEmpty(variant)) {
      console.log("You are here", variant);
      addProduct(product, variant, product.quantity);
    }
  };

  return (
    <div>
      <React.Fragment>
        <div className="row my-2">
          <div className="col-md-3">{product.name}</div>
          <div className="col-md-5">
            {product.product_variants.length > 0 ? (
              <div className="text-left product-price">
                <select
                  onChange={(e) => {
                    onChagneVarint(e);
                  }}
                  className="form-control"
                  aria-label="Default select example"
                  value={
                    selectedVariant !== undefined ? selectedVariant.id : ""
                  }
                >
                  {product.product_variants.map((p, i) => (
                    <option value={p.id}>
                      {p.unit} - {p.price}{" "}
                      {(p.status !== "Out of stock") &
                      (product.status !== "Out of stock")
                        ? ""
                        : "(Stockout)"}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="select_height"> </div>
            )}
          </div>

          <div className="col-md-4">
            {product.status !== "Out of stock" ? (
              <span>
                {JSON.stringify(selectedVariant.id)}
                {selectedVariant !== undefined && isInCart(selectedVariant) && (
                  <div>
                    <ButtonGroup
                      size="small"
                      variant="contained"
                      color="primary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        classes={{ root: classes.root }}
                        onClick={() => decreaseProduct(selectedVariant)}
                      >
                        {" "}
                        <Icon color="white">remove</Icon>
                      </Button>
                      <Button classes={{ root: classes.root }}>
                        {" "}
                        <span className="quantity_areaa">
                          {checkQuantity(selectedVariant)} in bag
                        </span>
                      </Button>
                      <Button
                        classes={{ root: classes.root }}
                        onClick={() => increase(product, selectedVariant)}
                      >
                        {" "}
                        <Icon color="white">add</Icon>
                      </Button>
                    </ButtonGroup>
                  </div>
                )}

                {selectedVariant !== undefined && !isInCart(selectedVariant) && (
                  <React.Fragment>
                    <button
                      onClick={() => addProduct(product, selectedVariant)}
                      className="btn btn-primary btn-block"
                    >
                      Add to cart
                    </button>
                  </React.Fragment>
                )}
              </span>
            ) : (
              <span className="text-danger">Out of stock</span>
            )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
